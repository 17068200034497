/*
Input => [{obra}, {obra}, {obra}]
Output => [
    [{obra}, {obra}],
    [{obra}]
]
*/
export const divideTestsByEquipment = (pruebas) => {
  // console.log("pruebas metodo");
  // console.log(pruebas);
  let outputArray = [];
  pruebas.forEach((prueba) => {
    let added = false;
    for (let i = 0; i < outputArray.length; i++) {
      // Si la prueba tiene el mismo equipo utilizado que algún grupo existente se añade
      if (outputArray[i][0]?.trompa === prueba.trompa && outputArray[i][0]?.bascula === prueba.bascula && outputArray[i][0]?.flexometro === prueba.flexometro) {
        outputArray[i].push(prueba);
        added = true;
        break;
      }
    }
    // Si no se encontró ningun grupo con ese equipo utilizado, crea un nuevo grupo
    if (!added) outputArray.push([prueba]);
  });
  // console.log("LAS PRUEBAS");
  // console.log(outputArray);
  return outputArray;
};
