import MaterialTable from "@material-table/core";
import { useNavigate } from "react-router-dom";
import { Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@mui/icons-material/Info";
import PrintIcon from "@mui/icons-material/Print";
import TodayIcon from "@mui/icons-material/Today";
import { Button } from "@mui/material";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState, useCallback } from "react";
import InnerDataTableConcreto from "./InnerDataTableConcreto";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfiguracionesConcreto,
  selectFirmaOptions,
  setFirmaConcreto,
} from "../../features/configuraciones/configuracionesSlice";
import { setCase, setData, setPruebas } from "../../features/auth/pdfSlice";
import Swal from "sweetalert2";
import HeaderFecha from "../HeaderFecha";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    backgroundColor: "#F8F8F8",
    padding: "10px",
  },
  // Objeto popup para insertar y editar
  modal: {
    position: "absolute",
    width: 450,
    height: 490,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objeto popup para eliminar
  modalEliminar: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  // Objetos de iconos
  iconos: {
    cursor: "pointer",
  },
  // Objetos de entradas de datos (campos de formularios)
  inputMaterial: {
    width: "100%",
  },
  // Objeto interior de tablas (celdas)
  tableCell: {
    textTransform: "capitalize",
  },
}));

// Ruteo de backend
const baseURL = "/obra";

// Declaracion de tabla
const DataTable = () => {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de controladores
  const controller = new AbortController();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  // Declaracion de estados
  const [verPDF, setVerPDF] = useState(false);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEditarCliente, setModalEditarCliente] = useState(false);
  const [disableFunction, setDisableFunction] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hideColumns, setHideColumns] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [, setValue] = useState("");
  const [clientOptions, setClientOptions] = useState("");
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState({
    nombreObra: "",
    ubicacion: "",
    idObra: "",
    concretos: {
      atencion: "",
      resistenciaDeProyecto: "",
      revenimientoDeProyecto: "",
      fechaEmision: new Date(),
    },
    clientes: {
      id: "",
      empresa: "",
      nomContacto: "",
      telContacto: "",
      correoContacto: "",
    },
  });
  const [dateFilter, setDateFilter] = useState(new Date());
  const [dateFilterValue, setDateFilterValue] = useState(new Date());
  const [dateFilterShown, setDateFilterShown] = useState(false);
  // Configuraciones de concreto en redux
  const concretoConfig = useSelector(selectConfiguracionesConcreto);
  const firmaOptions = useSelector(selectFirmaOptions);

  // Declaracion de columnas de tabla
  const columnas = [
    {
      title: "Nombre de la obra",
      field: "nombreObra",
      width: "100%",
    },
    {
      title: "Ubicación",
      field: "ubicacion",
      width: "100%",
    },

    {
      title: "Nombre del cliente",
      field: "clientes.nomContacto",
      width: "100%",
    },
    {
      title: "Fecha de emisión de informe",
      field: "concretos.fechaEmision",
      width: "100%",
      type: "date",
      dateSetting: {
        format: "dd/mm/yyyy",
      },
    },
    {
      title: "Empresa del cliente",
      field: "clientes.empresa",
      width: "100%",
      hidden: hideColumns,
    },
    {
      title: "Atención",
      field: "concretos.atencion",
      width: "100%",
      hidden: hideColumns,
    },
    {
      title: "Correo del cliente",
      field: "clientes.correoContacto",
      width: "100%",
      hidden: hideColumns,
    },
    {
      title: "Teléfono de contacto",
      field: "clientes.telContacto",
      width: "100%",
      hidden: hideColumns,
    },
    {
      title: "Resistencia del proyecto",
      field: "concretos.resistenciaDeProyecto",
      width: "100%",
      hidden: hideColumns,
    },
    {
      title: "Revenimiento del proyecto",
      field: "concretos.revenimientoDeProyecto",
      width: "100%",
      hidden: hideColumns,
    },
  ];

  // Declaracion de variables
  let count = 0;

  // Metodos para manejo de datos y acciones
  const handleChangeDate = (date) => {
    setProyectoSeleccionado((prevState) => ({
      ...prevState,
      concretos: { ...prevState.concretos, fechaEmision: date },
    }));
  };
  const handleInputChange = (e) => {
    setValue(e);
  };
  const handleSelectedChange = (value, name) => {
    setSelectedValue(value);
    setProyectoSeleccionado((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProyectoSeleccionado((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClientesChange = (e) => {
    const { name, value } = e.target;
    setProyectoSeleccionado((prevState) => ({
      ...prevState,
      clientes: {
        ...prevState.clientes,
        [name]: value,
      },
    }));
  };
  const handleConcretosChange = (e) => {
    const { name, value } = e.target;
    setProyectoSeleccionado((prevState) => ({
      ...prevState,
      concretos: { ...prevState.concretos, [name]: value },
    }));
  };
  const seleccionarProyecto = (proyecto, caso) => {
    setProyectoSeleccionado(proyecto[0]);
    switch (caso) {
      case "Editar":
        abrirCerrarModalEditar();
        break;
      case "Eliminar":
        abrirCerrarModalEliminar();
        break;
      case "EditarCliente":
        abrirCerrarModalEditarCliente();
        break;

      default:
        break;
    }
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  const abrirCerrarModalEditarCliente = () => {
    setModalEditarCliente(!modalEditarCliente);
  };
  const handleCreate = async (inputValue) => {
    setIsLoading(true);
    const { value } = createOption(inputValue);
    handleSelectedChange(
      await createClientes(value.charAt(0).toUpperCase() + value.slice(1)),
      "clientes"
    );
    setIsLoading(false);
  };
  const filterClients = (inputValue) => {
    return clientOptions?.filter((i) =>
      i.nomContacto.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // Metodos para funciones de backend
  const createClientes = async (newClient) => {
    const options = await axios.post("/cliente", { nomContacto: newClient });
    const mensaje = options.data.message;
    const cliente = options.data.cliente;
    if (!mensaje) {
      setSelectedValue(cliente);
      getClientes();
      return cliente;
    }
    return mensaje;
  };
  const peticionPost = async () => {
    await axios
      .post(baseURL + "/obrac", proyectoSeleccionado, {
        signal: controller.signal,
      })
      .then(() => {
        peticionGet();
        abrirCerrarModalInsertar();
         Swal.fire({
           position: "top-end",
           icon: "success",
           title: "Su prueba fue añadida al historial exitosamente",
           showConfirmButton: false,
           timer: 1700,
           timerProgressBar: true,
         });
        setSelectedValue(null);
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const peticionPut = async () => {
    await axios
      .put(
        baseURL +
          "/c" +
          `/${proyectoSeleccionado.id}/${proyectoSeleccionado.concretos.id}/${proyectoSeleccionado.clientes.id}`,
        proyectoSeleccionado
      )
      .then(() => {
        peticionGet();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Datos actualizados exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const peticionDelete = async () => {
    await axios
      .delete(
        baseURL +
          "/" +
          `${proyectoSeleccionado.id}/${proyectoSeleccionado.concretos.id}`
      )
      .then(() => {
        peticionGet();
        abrirCerrarModalEliminar();
         Swal.fire({
           position: "top-end",
           icon: "success",
           title: "Eliminado exitosamente",
           showConfirmButton: false,
           timer: 1000,
           timerProgressBar: true,
         });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const peticionGet = useCallback(async () => {
    await axios
      .get(baseURL + `/pruebascday/?date=${dateFilterValue.toISOString()}`)
      .then((response) => {
        const message = response?.data?.message;
        if (message) {
          setTableData([]);
        } else {
          setTableData(response?.data);
        }
      });
  }, [axios, dateFilterValue]);
  const getClientes = useCallback(async () => {
    const options = await axios.get("/cliente");
    const clientes = options.data.clientes;
    setClientOptions(clientes);
    return clientes;
  }, [axios]);

  useEffect(() => {
    const controller = new AbortController();
    peticionGet();
    getClientes();
    return () => {
      controller.abort();
    };
  }, [peticionGet, getClientes]);
  const createOption = (label) => ({
    value: label.toLowerCase(),
  });

  // Metodos con promesas
  const promiseOptions = async (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterClients(inputValue));
      }, 300);
    });

  // BODY Modal crear proyecto
  const bodyInsertar = (
    <div className={classes.modal}>
      <h3>Agregar Nuevo Proyecto</h3>
      <TextField
        className={classes.inputMaterial}
        autoFocus={true}
        label="Nombre de la obra"
        name="nombreObra"
        onChange={handleChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Ubicación"
        name="ubicacion"
        onChange={handleChange}
      />
      <br />
      <br />
      {/* Lista con creacion de clientes */}
      <AsyncCreatableSelect
        cacheOptions
        required={true}
        defaultMenuIsOpen={false}
        isLoading={isLoading}
        loadingMessage={() => "Cargando..."}
        placeholder={"Selecciona un cliente"}
        defaultOptions={clientOptions}
        value={selectedValue}
        getOptionLabel={(e) => e.nomContacto}
        getOptionValue={(e) => e.id}
        loadOptions={promiseOptions}
        createOptionPosition={"last"}
        onCreateOption={handleCreate}
        onInputChange={handleInputChange}
        onChange={(value) => {
          handleSelectedChange(value, "clientes");
        }}
        isClearable={true}
        backspaceRemovesValue={false}
        noOptionsMessage={() => "No hay clientes"}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Atención"
        name="atencion"
        onChange={handleConcretosChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        type="number"
        step="0.01"
        label="Resistencia de Proyecto"
        name="resistenciaDeProyecto"
        onChange={handleConcretosChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Revenimiento de Proyecto"
        type="number"
        step="0.01"
        name="revenimientoDeProyecto"
        onChange={handleConcretosChange}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha de emisión"
          name="fechaEmision"
          value={proyectoSeleccionado.concretos.fechaEmision}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <br />
      <br />
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalInsertar()}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            peticionPost();
          }}
        >
          Insertar
        </Button>
      </div>
    </div>
  );

  // BODY Modal editar proyecto
  const bodyEditar = (
    <div className={classes.modal}>
      <h3>Editar un proyecto</h3>
      <TextField
        className={classes.inputMaterial}
        label="Nombre de la obra"
        autoFocus={true}
        name="nombreObra"
        value={proyectoSeleccionado?.nombreObra}
        onChange={handleChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Ubicación"
        name="ubicacion"
        value={proyectoSeleccionado?.ubicacion}
        onChange={handleChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Nombre cliente"
        name="nomContacto"
        value={proyectoSeleccionado?.clientes?.nomContacto}
        onChange={handleClientesChange}
      />
      <br />
      <Button color="secondary" onClick={() => abrirCerrarModalEditarCliente()}>
        Editar datos del Cliente
      </Button>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Correo Contacto"
        name="correoContacto"
        value={proyectoSeleccionado?.clientes?.correoContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Atención"
        name="atencion"
        value={proyectoSeleccionado?.concretos?.atencion}
        onChange={handleConcretosChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        type="number"
        value={proyectoSeleccionado?.concretos?.resistenciaDeProyecto}
        label="Resistencia de Proyecto"
        name="resistenciaDeProyecto"
        onChange={handleConcretosChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Revenimiento de Proyecto"
        type="number"
        value={proyectoSeleccionado?.concretos?.revenimientoDeProyecto}
        name="revenimientoDeProyecto"
        onChange={handleConcretosChange}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha de emisión"
          name="fechaEmision"
          value={proyectoSeleccionado?.concretos?.fechaEmision}
          onChange={(date) => {
            handleChangeDate(date, "fechaEmision");
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <br />
      <br />
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalEditar()}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            abrirCerrarModalEditar();
            peticionPut();
          }}
        >
          Actualizar
        </Button>
      </div>
    </div>
  );

  // BODY Modal editar cliente
  const bodyEditarCliente = (
    <div className={classes.modal}>
      <h3>Editar datos de Cliente</h3>
      <TextField
        className={classes.inputMaterial}
        label="Empresa"
        name="empresa"
        value={proyectoSeleccionado?.clientes?.empresa}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Nombre del Cliente"
        name="nomContacto"
        value={proyectoSeleccionado?.clientes?.nomContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Teléfono de Contacto"
        name="telContacto"
        value={proyectoSeleccionado?.clientes?.telContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Correo de Contacto"
        name="correoContacto"
        value={proyectoSeleccionado?.clientes?.correoContacto}
        onChange={handleClientesChange}
      />
      <br />
      <br />
      <br />
      <div align="right">
        <Button
          color="secondary"
          onClick={() => {
            abrirCerrarModalEditarCliente();
            peticionPut();
          }}
        >
          Listo
        </Button>
      </div>
    </div>
  );

  // BODY Modal eliminar proyecto
  const bodyEliminar = (
    <div className={classes.modalEliminar}>
      <p>
        ¿Estás seguro que deseas eliminar al proyecto{" "}
        <b>{proyectoSeleccionado && proyectoSeleccionado.nombreObra}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  );

  // BODY Principal
  return (
    <div className={classes.root}>
      {/* Tabla con obras de concreto */}
      <HeaderFecha date={dateFilterValue} style={{ mb: 3 }} />
      <MaterialTable
        title="Pruebas de Concreto del Dia y Pendientes"
        onSelectionChange={(selected) => {
          if (selected.length > 1) {
            setDisableFunction(true);
          } else {
            setDisableFunction(false);
          }
        }}
        columns={columnas}
        data={tableData}
        actions={[
          {
            icon: PrintIcon,
            disabled: disableFunction,
            tooltip: "Imprimir",
            onClick: async (event, rowData) => {
              let inputOptionsFirma = {};
              firmaOptions?.forEach((option) => {
                inputOptionsFirma = {
                  ...inputOptionsFirma,
                  [option.title]: option.title,
                };
              });
              const { value: firma, isConfirmed } = await Swal.fire({
                title:
                  "¿Deseas imprimir todas las pruebas de " +
                  rowData[0].nombreObra +
                  "?",
                text: "Seleccione signatario",
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                cancelButtonColor: "#d3d3d3",
                confirmButtonText: "Imprimir",
                confirmButtonColor: "#3085d6",
                input: "select",
                inputPlaceholder: "Signatario",
                inputOptions: inputOptionsFirma,
                // This should match exactly the option in firma
                inputValue: "ING. GASTON FLORES",
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (!value) {
                      resolve("Tienes que seleccionar una firma");
                    } else {
                      resolve();
                    }
                  });
                },
              });
              if (isConfirmed && firma) {
                dispatch(setFirmaConcreto(firma));
                dispatch(setData(rowData[0]));
                dispatch(setPruebas(rowData[0]?.concretos?.pconcretos));
                dispatch(setCase("concreto"));
                navigate("/vistaprevia", {
                  replace: true,
                  state: { dateFilter: dateFilterValue },
                });
              }
            },
          },
          {
            icon: TodayIcon,
            isFreeAction: true,
            tooltip: "Imprimir Pruebas del Día",
            onClick: async (event, rowData) => {
              const { isConfirmed } = await Swal.fire({
                title: "¿Deseas imprimir las pruebas del día?",
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                cancelButtonColor: "#d3d3d3",
                confirmButtonText: "Imprimir",
                confirmButtonColor: "#3085d6",
              });

              if (isConfirmed) {
                const allPruebas = [];
                tableData.forEach((obra) => {
                  allPruebas.push(...obra.concretos.pconcretos);
                });
                // Sort by numero general
                allPruebas.sort((a, b) => a.numeroGeneral - b.numeroGeneral); // b - a for reverse sort
                dispatch(setPruebas(allPruebas));
                dispatch(setCase("cargasConcreto"));
                navigate("/vistaprevia", {
                  replace: true,
                  state: { dateFilter: dateFilterValue },
                });
              }
            },
          },
          {
            icon: TodayIcon,
            disabled: disableFunction,
            tooltip: "Imprimir Pruebas del Día",
            onClick: async (event, rowData) => {
              const { isConfirmed } = await Swal.fire({
                title: "¿Deseas imprimir las pruebas del día?",
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                cancelButtonColor: "#d3d3d3",
                confirmButtonText: "Imprimir",
                confirmButtonColor: "#3085d6",
              });

              if (isConfirmed) {
                dispatch(setData(rowData[0]));
                dispatch(setPruebas(rowData[0]?.concretos?.pconcretos));
                dispatch(setCase("cargasConcreto"));
                navigate("/vistaprevia", {
                  replace: true,
                  state: { dateFilter: dateFilterValue },
                });
              }
            },
          },
          {
            icon: EditIcon,
            disabled: disableFunction,
            tooltip: "Editar Proyecto",
            onClick: (event, rowData) => seleccionarProyecto(rowData, "Editar"),
          },
          {
            icon: ManageAccountsIcon,
            disabled: disableFunction,
            tooltip: "Editar Datos del Cliente",
            onClick: (event, rowData) =>
              seleccionarProyecto(rowData, "EditarCliente"),
          },
          {
            icon: CalendarMonthIcon,
            isFreeAction: true,
            tooltip: "Seleccionar fecha",
            onClick: () => setDateFilterShown(true),
          },
          {
            icon: InfoIcon,
            isFreeAction: true,
            tooltip: hideColumns
              ? "Mostrar todas las columnas de la tabla"
              : "Ocultar las columnas nuevamente",
            onClick: () => {
              setHideColumns(!hideColumns);
            },
          },
          {
            icon: AddIcon,
            isFreeAction: true,
            disabled: disableFunction,
            tooltip: "Agregar obra",
            onClick: () => abrirCerrarModalInsertar(),
          },
          {
            icon: DeleteIcon,
            disabled: disableFunction,
            tooltip: "Eliminar proyecto",
            onClick: (event, rowData) => {
              seleccionarProyecto(rowData, "Eliminar");
            },
          },
        ]}
        options={{
          exportButton: true,
          exportAllData: true,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          selection: true,
          showTextRowsSelected: true,
          columnResizable: true,
          showDetailPanelIcon: true,
          showSelectAllCheckbox: false,
          columnsButton: true,
          tableLayout: "auto",
        }}
        localization={{
          header: {
            actions: "Acciones",
          },
          toolbar: {
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar...",
            showColumnsAriaLabel: "Columnas",
            showColumnsTitle: "Columnas",
            addRemoveColumns: "Agregar o quitar columnas de la tabla",
          },
          pagination: {
            nextTooltip: "Siguiente",
            previousTooltip: "Anterior",
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "Renglones por página:",
            labelRowsSelect: "renglones",
          },
          body: {
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Borrar",
            emptyDataSourceMessage: (
              <>
                <p> No hay registros</p>
                <Button
                  color="primary"
                  onClick={() => abrirCerrarModalInsertar()}
                >
                  Agregar
                </Button>
              </>
            ),
            editRow: {
              deleteText: "Borrar este renglón?",
            },
          },
        }}
        detailPanel={(rowData) => {
          count += 1;
          count % 4 === 0 && setVerPDF(false);
          return (
            // Tabla interior con pruebas de obras de concreto
            <InnerDataTableConcreto
              data={rowData}
              getAll={false}
              dateFilter={dateFilterValue.toUTCString()}
              verPDF={verPDF}
              setVerPDF={setVerPDF}
            />
          );
        }}
        onRowClick={(event, rowData, togglePanel) => {
          const rowDataCopy = { ...rowData };
          rowDataCopy.tableData.checked = false;
          // Copy data so we can modify it
          const dataCopy = [...tableData];
          // Find the row we clicked and update it with `checked` prop
          dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
          setTableData(dataCopy);
          peticionGet();
          togglePanel();
        }}
      />
      {/* Modales */}
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
        {bodyInsertar}
      </Modal>
      <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>
      <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>
      <Modal open={modalEditarCliente} onClose={abrirCerrarModalEditarCliente}>
        {bodyEditarCliente}
      </Modal>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label="Fecha de Pruebas"
          name="dateFilter"
          disableMaskedInput={false}
          value={dateFilter}
          open={dateFilterShown}
          onClose={() => setDateFilterShown(false)}
          onChange={(date) => {
            console.log(date);
            setDateFilter(date);
          }}
          onAccept={() => setDateFilterValue(dateFilter)}
          renderInput={() => null}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DataTable;
