import axios from "axios";

// Conexion con backend
// const baseURL = "http://localhost:3001/api";
// const baseURL = "http://18.232.139.2";
const baseURL = "https://api.geotecniacalidadconstructiva.com.mx";
// const baseURL = "http://localhost:3001/";

export default axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
