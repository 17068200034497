/*
Input => [[{obra1}], [{obra2}, {obra3}]] cada posicion es un equipo diferente obra 2 y 3 igual lugar de colado
Output => [
    [[{obra1}]] //pageArray1
    [[{obra2}], {obra3}]], //pageArray2
]
*/
export const dividePruebasByLocation = (pruebas) => {
  let outputArray = [];
  pruebas.forEach((prueba) => {
    // console.log("equipo nuevo");
    let pageArray = [];
    for (var j = 0; j < prueba.length; j++) {
      let added = false;
      for (let i = 0; i < pageArray.length; i++) {
        // let addedSamePage = false;
        // Si la prueba tiene la misma ubicación que algún grupo existente, añadirla al grupo
        // for (var k = 0; k < pageArray[i].length; k++) {
          if (pageArray[i][0]?.lugarDeColado === prueba[j].lugarDeColado) {
            // console.log("mismo lugar de colado");
            pageArray[i].push(prueba[j]);
            // addedSamePage = true;
            added = true;
            break;
          }
        // }
        // if(!addedSamePage) {
        //   console.log("nuevo lugar de colado");
        //   pageArray[i].push([prueba[j]]);
        // }
      }
      // Si no se encontró ningun grupo con esa ubicación, crear un nuevo grupo
      if (!added) {
        // console.log("prueba j");
        // console.log(prueba[j]);
        pageArray.push([prueba[j]]);
        // console.log("nuevo lugar de colado");
        // console.log(pageArray);
      }
    }
    outputArray.push(pageArray);
    // console.log("outputArray");
    // console.log(outputArray);
  });
  return outputArray;
};
