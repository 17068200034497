import * as React from "react";
// import { useDispatch } from "react-redux";
// import { setAccessToken } from "../../features/auth/authSlice";
import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
import { createTheme, /*ThemeProvider*/ } from "@mui/material/styles";
import { useRef, useState, /*useEffect*/ } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";

// Declaracion de tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  half: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

// Declaracion de configuraciones de usuario
const UserConfig = () => {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const userRef = useRef();
  const errRef = useRef();
  // const dispatch = useDispatch();

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //   useEffect(() => {
  //     userRef.current.focus();
  //   }, []);

  // Metodos para manejo de datos y acciones
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      //   const response = await login(email, password);
      //   const accessToken = response?.data?.accessToken;
      // dispatch(setAccessToken(accessToken));
      navigate(from, { replace: true });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Cambios guardados exitosamente",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    } catch (err) {
      // MySwal.fire({
      //   title: <p>Hello World</p>,
      //   didOpen: () => {
      //     MySwal.showLoading();
      //   },
      // }).then(() => {
      //   return MySwal.fire(<p>{err.response.data}</p>);
      // });
      console.log(err);
      errRef.current.focus();
      Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
    }
  };

  // BODY Principal
  return (
    <div className={classes.root}>
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "red",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            width: 900,
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "90%",
              },
            }}
          >
            <TextField
              margin="normal"
              // variant="standard"
              ref={userRef}
              id="email"
              label="Correo electrónico"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
              fullWidth
            />
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "90%",
              },
            }}
          >
            <TextField
              margin="normal"
              // variant="standard"
              ref={userRef}
              id="tel"
              label="Teléfono"
              name="telefono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              autoComplete="telefono"
              autoFocus
              fullWidth
            />
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "90%",
              },
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="newPassword"
              label="Nueva contraseña"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
            />
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "90%",
              },
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirmar contraseña"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              // autoComplete="off"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              [theme.breakpoints.down("sm")]: {
                width: "90%",
                alignSelf: "center",
                mt: 1,
                mb: 1,
              },
            }}
          >
            Actualizar usuario
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UserConfig;
