import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    backgroundColor: "#EBE64A",
    // bottom: "0px",
    textAlign: "center",
    height: "4vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      "& > p": {
        fontSize: "14px",
      },
    },
  },
}));

// Declaracion de componente FOOTER
const Footer = () => {
  // Declaracion de estilos
  const classes = useStyles();

  // BODY Principal
  return (
    <div className={classes.root}>
      <p>Geotecnia y Calidad Constructiva © 2024</p>
    </div>
  );
};

export default Footer;
