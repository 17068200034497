import { createTheme } from "@mui/material";

// Estilo tematico

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        "&.Mui-checked": {
          color: "#000",
        },
      },
    },
  },
});

export default theme;
