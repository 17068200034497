import logoGCC from "../../imgs/logogcc-removebg-preview.png";
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  DataTableCell,
  TableBody,
} from "@david.kucsai/react-pdf-table";
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Estilos
const styles = StyleSheet.create({
  // Objeto para formato de paginacion
  page: {
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  // Objeto con titulo GCC
  title: {
    marginBottom: 1,
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  // Objeto para formato de textos
  text: {
    // margin: 12,
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  sectionTitle: {
    fontFamily: "Helvetica",
    textAlign: "center",
    fontStyle: "bold",
    fontSize: 14,
    marginBottom: 10,
  },
  // Objeto para formato de seccion, wrapper
  section: {
    padding: 10,
    flexGrow: 1,
  },
  // Objeto con
  headerTable: {
    height: 340,
    marginBottom: 15,
  },
  // Objeto con titulos de columnas en tabla de pdf
  headerRow: {
    alignContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    fontSize: 8.8,
    justifyContent: "center",
  },
  // Objeto con titulos de columnas en tabla de pdf
  headerRow2: {
    alignContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    fontSize: 8.8,
    textAlign: "center",
    justifyContent: "center",
    wordWrap: "break-word",
  },
  // Objeto para alineacion de contenido en tabla de pdf
  cell: {
    margin: 0,
    fontSize: 9,
    height: 37,
    paddingHorizontal: 5,
    paddingVertical: 5,
    justifyContent: "center",
    // NOTE: alignItems property gives -infinity error
    // alignItems: "center",
    fontFamily: "Times-Roman",
  },
  // Objeto para alineacion de contenido en tabla de pdf
  cell2: {
    margin: 0,
    fontSize: 9,
    height: 37,
    paddingHorizontal: 5,
    paddingVertical: 5,
    textAlign: "center",
    justifyContent: "center",
    // NOTE: alignItems property gives -infinity error
    // alignItems: "center",
    fontFamily: "Times-Roman",
  },
  cellAvgs: {
    // margin: 0,
    fontSize: 8,
    height: 25,
    borderBottomWidth: 1,
    paddingHorizontal: 6,
    paddingVertical: 5,
    textTransform: "uppercase",
    justifyContent: "center",
    fontFamily: "Times-Roman",
  },
  cellAvgs2: {
    // margin: 0,
    fontSize: 8,
    height: 25,
    borderBottomWidth: 1,
    paddingHorizontal: 6,
    paddingVertical: 5,
    textAlign: "center",
    textTransform: "uppercase",
    justifyContent: "center",
    fontFamily: "Times-Roman",
  },
  cellAvgsInvisible: {
    borderWidth: 0,
  },
  // Objeto con sub-titulo GCC
  subtitle: {
    fontSize: 10,
    marginLeft: 70,
    marginBottom: 10,
    fontFamily: "Oswald",
    textAlign: "center",
    letterSpacing: "",
    alignContent: "right",
    width: "52%",
  },
  // Objeto logo
  image: {
    marginVertical: 10,
    paddingHorizontal: 150,
    width: "100px",
    height: "100px",
    float: "flex",
  },
  nmx: {
    fontSize: 8,
    textAlign: "left",
    textIndent: 1,
    marginTop: 10,
    display: "block",
    strokeLineCap: "butt",
    dominantBaseline: "text-after-edge",
    marginBottom: 5,
  },
  footerRev: {
    fontSize: 6,
    textAlign: "right",
    marginTop: 10,
    // marginBottom: 10,
  },
  footerAddr: {
    textTransform: "uppercase",
    fontStyle: "bold",
    fontSize: 7,
    textAlign: "center",
  },
  footerWebsite: {
    textTransform: "lowercase",
    fontStyle: "bold",
    fontSize: 8,
    textAlign: "center",
  },
  observations: {
    textAlign: "left",
    flexDirection: "row",
    fontSize: 8,
    textTransform: "uppercase",
    marginLeft: 17,
    position: "relative",
  },
  sectionObs: {
    flexDirection: "row",
    alignSelf: "flex-start",
    marginTop: 13,
  },
});

// Metodos para manejo de datos y acciones
const formatDate = (date) => {
  if (date === "") return "";

  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Declaracion de IMPRESION para PDFs de concreto
const DocuPDF = ({ pruebas, data, config }) => {
  let pruebasDivided = [];
  // Dividir pruebas en grupos por resistencia
  pruebas.forEach((prueba) => {
    // Added flag
    let added = false;
    for (let i = 0; i < pruebasDivided.length; i++) {
      // Si la prueba tiene la misma resistencia y modelado que algún grupo existente, añadirla al grupo
      if (
        pruebasDivided[i][0]?.resistenciaDeProyecto ===
          prueba.resistenciaDeProyecto &&
        pruebasDivided[i][0]?.modeladoDeConcreto === prueba.modeladoDeConcreto && pruebasDivided[i][0]?.fechaEnsaye ===
      prueba.fechaEnsaye
      ) {
        pruebasDivided[i].push(prueba);
        added = true;
        break;
      }
    }
    // Si no se encontró ningun grupo con esa resistencia, crear un nuevo grupo
    if (!added) pruebasDivided.push([prueba]);
  });
  // const fechaActual = new Date();
  // const fechaActual = pruebas[0].fechaEnsaye;
  // console.log('pruebas 204')
  // // console.log(pruebas[0].fechaEnsaye);
  // console.log(pruebas[0].fechaEnsaye);
  let pagesFinal = [];
  let pages = {};
  let pruebas8 = [];
  const iSizeOfTable = 8;
  let numeroDePagina = 1;
  // Para saber cuántas páginas habrá por adelantado, se calcula la variable primero
  let numPaginasFinal = 0;
  pruebasDivided.forEach((pruebasGroup) => {
    // Ejemplo: 17 pruebas y 8 págs x prueba = 3 páginas => Math.ceil(17 / 8)
    numPaginasFinal += Math.ceil(pruebasGroup.length / iSizeOfTable);
  });
  if (pruebasDivided.length !== 0) {
    // Iterar cada grupo y formar las páginas
    pruebasDivided.forEach((pruebasGroup) => {
      for (let i = 0; i < pruebasGroup.length; i += iSizeOfTable) {
        pruebas8 = pruebasGroup.slice(i, i + iSizeOfTable);
        if (pruebas8.length < iSizeOfTable) {
          for (let j = pruebas8.length; j < iSizeOfTable; j++) {
            pruebas8.push({
              bActive: false,
              carga: "",
              diametro: "",
              duracion: "",
              esfuerzoMaxKg: "",
              esfuerzoMaxMp: "",
              esfuerzoMaxObtenido: "",
              fechaEnsaye: "",
              fechaMuestreo: "",
              id: "",
              idConcreto: "",
              isDraft: "",
              lugarColado: "",
              modeladoDeConcreto: "",
              nCilindro: "",
              numeroGeneral: "",
              promedioMaxKg: "",
              promedioMaxMp: "",
              promedioMaxObtenido: "",
              resistenciaDeProyecto: "",
              revObtenido: "",
              revenimientoDeProyecto: "",
              tamanoMaxAgregado: "",
            });
          }
        }
        // Definición de widths para celdas de la tabla
        // El valor del width es relativo a los demás y la suma de todos
        // Los widths debe ser = 1
        const cellWidths = {
          nCilindro: 0.077,
          revObtenido: 0.08,
          fechaMuestreo: 0.09,
          duracion: 0.074,
          fechaEnsaye: 0.1,
          lugarColado: 0.27,
          esfuerzoKgMp: 0.2,
          esfuerzoMaxObtenido: 0.1, 
        };
        // calculo de promedios aqui
        let dAvg1 = 0;
        let dAvg2 = 0;
        let dAvg3 = 0;
        let count = 0;
        for (let i = 0; i < pruebas8.length; i++) {
          if (pruebas8[i].bActive) {
            dAvg1 += pruebas8[i].esfuerzoMaxKg;
            dAvg2 += pruebas8[i].esfuerzoMaxMp;
            dAvg3 += pruebas8[i].esfuerzoMaxObtenido;
            count++;
          }
        }
        const fechaActual = pruebas8[0].fechaEnsaye;
        // console.log('pruebas')
        // console.log(pruebas8[0].fechaEnsaye);
        dAvg1 = (dAvg1 / count).toFixed(0);
        dAvg2 = (dAvg2 / count).toFixed(2);
        dAvg3 = (dAvg3 / count).toFixed(0);
        let dAvgs = [];
        dAvgs.push({ dAvg1: dAvg1, dAvg2: dAvg2, dAvg3: dAvg3 });
        // crear contenido
        pages = (
          <View style={styles.section}>
            {/* <View style={{ flexDirection: "row" }}>
              <Image
                style={
                  (styles.image,
                  {
                    flexDirection: "column",
                    width: " 80px",
                    height: "80px",
                    marginHorizontal: 10,
                  })
                }
                src={logoGCC}
              />
                                
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.title}>
                  GEOTECNIA Y CALIDAD CONSTRUCTIVA, S.A. DE C.V.
                </Text>
                <Text style={styles.subtitle}>
                  {config?.titulo ||
                    "SERVICIOS DE CONTROL DE CALIDAD Y ASESORIA TÉCNICA EN LA CONSTRUCCIÓN LABORATORIO DE SUELOS, CONCRETOS Y ASFALTOS"}
                </Text>
                <View style={{ flexDirection: "row"}}>
                  <Text style={styles.text}>
                    {config?.informe ||
                      "Informe de control de calidad de concreto fresco y endurecido"}
                  </Text>
                </View>
                
              </View>
              
            </View> */}
            <Text
              style={{ ...styles.text, textAlign: "center", marginTop: 75 }}
            >
              {config?.informe ||
                "Informe de control de calidad de concreto fresco y endurecido"}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "flex-end",
              }}
            >
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 8,
                  marginHorizontal: 12,
                  marginTop: 12,
                }}
              >
                Fecha de emisión de informe:
              </Text>
              <View
                style={{
                  borderBottom: 0.5,
                  borderColor: "black",
                  width: "20%",
                  alignSelf: "stretch",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    flexDirection: "column",
                    alignSelf: "center",
                    alignItems: "center",
                    marginLeft: 25,
                    fontSize: 8,
                    position: "absolute",
                  }}
                >
                  {formatDate(fechaActual)}
                </Text>
              </View>
            </View>

            {/* Text */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{ fontSize: 8, marginBottom: 10, alignSelf: "flex-end" }}
              >
                Resultado del ensaye a la compresión, en cilindros moldeados de
                Concreto de:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {/* Box 1 */}
                <View
                  style={{
                    border: "1px solid #000",
                    width: 15,
                    height: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  {/* TODO: Change this dynamically */}
                  {pruebasGroup[0].modeladoDeConcreto === "15x30" ? (
                    <Text style={{ fontSize: 10, alignSelf: "center" }}>x</Text>
                  ) : (
                    <Text style={{ fontSize: 10, alignSelf: "center" }}>/</Text>
                  )}
                </View>
                <Text
                  style={{ fontSize: 7, alignSelf: "center", marginLeft: 5 }}
                >
                  15x30 cm
                </Text>
                {/* Box 2 */}
                <View
                  style={{
                    border: "1px solid #000",
                    width: 15,
                    height: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginLeft: 30,
                    marginRight: 5,
                  }}
                >
                  {pruebasGroup[0].modeladoDeConcreto === "10x20" ? (
                    <Text style={{ fontSize: 10, alignSelf: "center" }}>x</Text>
                  ) : (
                    <Text style={{ fontSize: 10, alignSelf: "center" }}>/</Text>
                  )}
                </View>
                <Text
                  style={{ fontSize: 7, alignSelf: "center", marginRight: 30 }}
                >
                  10x20 cm
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginBottom: 5,
                }}
              >
                <Text style={{ textAlign: "left", fontSize: 8, margin: 0 }}>
                  Obra:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "50vh",
                    alignSelf: "stretch",
                    marginBottom: 0,
                    marginLeft: 32,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      alignSelf: "stretch",
                      alignItems: "center",
                      textTransform: "uppercase",
                      fontSize: 8,
                      position: "relative",
                    }}
                  >
                    {data.nombreObra}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginBottom: 3,
                }}
              >
                <Text style={{ textAlign: "left", fontSize: 8 }}>
                  Cliente:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "50vh",
                    alignSelf: "stretch",
                    marginBottom: 0,
                    marginLeft: 25,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      fontSize: 8,
                      textTransform: "uppercase",
                      position: "relative",
                    }}
                  >
                    {data.clientes.nomContacto}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginBottom: 5,
                }}
              >
                <Text style={{ textAlign: "left", fontSize: 8 }}>
                  Ubicación:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "50vh",
                    alignSelf: "stretch",
                    marginBottom: 0,
                    marginLeft: 14,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      fontSize: 8,
                      textTransform: "uppercase",
                      position: "relative",
                    }}
                  >
                    {data.ubicacion}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginBottom: 5,
                }}
              >
                <Text style={{ textAlign: "left", fontSize: 8 }}>
                  Atencion:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "50vh",
                    alignSelf: "stretch",
                    marginBottom: 0,
                    marginLeft: 18,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      fontSize: 8,
                      textTransform: "uppercase",
                      position: "relative",
                    }}
                  >
                    {data.concretos.atencion}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginBottom: 5,
                }}
              >
                <Text style={{ textAlign: "left", fontSize: 8 }}>
                  Resistencia del Proyecto:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "6vh",
                    alignSelf: "stretch",
                    marginBottom: 10,
                    marginLeft: 10,
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      fontSize: 8,
                      position: "relative",
                    }}
                  >
                    {pruebasGroup[0].resistenciaDeProyecto} kg/cm²
                  </Text>
                </View>
                <Text
                  style={{ textAlign: "left", fontSize: 8, marginLeft: 17 }}
                >
                  Revenimiento del Proyecto:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "6vh",
                    alignSelf: "stretch",
                    marginBottom: 10,
                    marginLeft: 10,
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      fontSize: 8,
                      position: "relative",
                    }}
                  >
                    {pruebasGroup[0].revenimientoDeProyecto} cm
                  </Text>
                </View>
                <Text
                  style={{ textAlign: "left", fontSize: 8, marginLeft: 17 }}
                >
                  Tamaño Máx agregado:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "6vh",
                    alignSelf: "stretch",
                    marginBottom: 10,
                    marginLeft: 10,
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      flexDirection: "row",
                      fontSize: 8,
                      position: "relative",
                    }}
                  >
                    {pruebasGroup[0].tamanoMaxAgregado} cm
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={styles.sectionTitle}>Resultados del ensaye</Text>
            </View>
            <View style={styles.headerTable}>
              <Table data={pruebas8}>
                <TableHeader>
                  <TableCell
                    weighting={cellWidths.nCilindro}
                    style={styles.headerRow2}
                  >
                    Cilindro Nº
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.revObtenido}
                    style={styles.headerRow2}
                  >
                    Rev. Obtenido cm
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.fechaMuestreo}
                    style={styles.headerRow2}
                  >
                    Fecha del Muestreo
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.duracion}
                    style={styles.headerRow2}
                  >
                    Edad de Prueba Días
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.fechaEnsaye}
                    style={styles.headerRow2}
                  >
                    Fecha de Ensaye
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.lugarColado}
                    style={styles.headerRow}
                  >
                    Lugar de Colado
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.esfuerzoKgMp}
                    style={styles.headerRow}
                  >
                    <View style={{ width: "100%" }}>
                      <Text>Esfuerzo Maximo obtenido</Text>
                      <View
                        style={{
                          marginTop: 3,
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Text>kg/cm²</Text>
                        <Text>MPa</Text>
                      </View>
                    </View>
                  </TableCell>
                  <TableCell
                    weighting={cellWidths.esfuerzoMaxObtenido}
                    style={styles.headerRow2}
                  >
                    Esfuerzo Maximo obtenido %
                  </TableCell>
                </TableHeader>
                <TableBody
                  includeTopBorder={false}
                  includeBottomBorder={false}
                  includeLeftBorder={true}
                  includeRightBorder={true}
                >
                  <DataTableCell
                    weighting={cellWidths.nCilindro}
                    style={styles.cell2}
                    getContent={(r) => {
                      // console.log(r);
                      return r.nCilindro;
                    }}
                  />
                  <DataTableCell
                    weighting={cellWidths.revObtenido}
                    style={styles.cell2}
                    getContent={(r) => r.revObtenido}
                  />
                  <DataTableCell
                    weighting={cellWidths.fechaMuestreo}
                    style={styles.cell2}
                    getContent={(r) => formatDate(r.fechaMuestreo)}
                  />
                  <DataTableCell
                    weighting={cellWidths.duracion}
                    style={styles.cell2}
                    getContent={(r) => r.duracion}
                  />
                  <DataTableCell
                    weighting={cellWidths.fechaEnsaye}
                    style={styles.cell2}
                    getContent={(r) => formatDate(r.fechaEnsaye)}
                    // getContent={(r) => formatDate(fechaActual)}
                  />
                  <DataTableCell
                    weighting={cellWidths.lugarColado}
                    style={{
                      ...styles.cell2,
                      fontSize: 6,
                      paddingVertical: 2,
                    }}
                    getContent={(r) => r.lugarColado}
                  />
                  <DataTableCell
                    weighting={cellWidths.esfuerzoKgMp}
                    style={styles.cell}
                    getContent={(r) => {
                      const kg = [null, undefined, ""].includes(r.esfuerzoMaxKg)
                        ? " "
                        : r.esfuerzoMaxKg;
                      const mp = [null, undefined, ""].includes(r.esfuerzoMaxMp)
                        ? " "
                        : r.esfuerzoMaxMp;
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <Text>{kg}</Text>
                          <Text>{mp}</Text>
                        </View>
                      );
                    }}
                  />
                  <DataTableCell
                    weighting={cellWidths.esfuerzoMaxObtenido}
                    style={styles.cell2}
                    getContent={(r) => r.esfuerzoMaxObtenido}
                  />
                </TableBody>
              </Table>
              {/* Promedios */}
              <View>
                <Table data={dAvgs}>
                  <TableBody
                    includeTopBorder={false}
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                  >
                    <DataTableCell
                      weighting={cellWidths.nCilindro}
                      style={[styles.cellAvgs2, styles.cellAvgsInvisible]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={cellWidths.revObtenido}
                      style={[styles.cellAvgs2, styles.cellAvgsInvisible]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={cellWidths.fechaMuestreo}
                      style={[styles.cellAvgs2, styles.cellAvgsInvisible]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={cellWidths.duracion}
                      style={[styles.cellAvgs2, styles.cellAvgsInvisible]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={cellWidths.fechaEnsaye}
                      style={[styles.cellAvgs2, styles.cellAvgsInvisible]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={cellWidths.lugarColado}
                      style={{
                        ...styles.cellAvgs2,
                        borderLeftWidth: 1,
                      }}
                      getContent={(r) => "Promedio"}
                    />
                    <DataTableCell
                      weighting={cellWidths.esfuerzoKgMp}
                      style={styles.cellAvgs}
                      getContent={(r) => {
                        return (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Text>{r.dAvg1}</Text>
                            <Text>{r.dAvg2}</Text>
                          </View>
                        );
                      }}
                    />
                    <DataTableCell
                      weighting={cellWidths.esfuerzoMaxObtenido}
                      style={{ ...styles.cellAvgs2, borderRightWidth: 1 }}
                      getContent={(r) => r.dAvg3}
                    />
                  </TableBody>
                </Table>
              </View>
            </View>
            <View>
              <Text style={styles.nmx}>
                Ensaye realizado de acuerdo a la Nómina NMX-C-083-ONNCCE-2014,
                además de las Normas aplicadas NMX-C-155-ONNCCE-2014,{"\n"}
                NMX-C-156-ONNCCE-2010, NMX-C-159-ONNCCE-2016,
                NMX-X-160-ONNCCE-2004, NMX-C-161-ONNCCE-2013 y
                NMX-C-109-ONNCCE-2013{"\n"}
                Correspondencia entre unidades: 1 kg/cm² dividido por 10.197 es
                igual a 0.0981 Mpa{"\n"}
                Este informe no puede ser reproducido sin la autorización por
                escrito por el Laboratorio{"\n"}
                La resistencia obtenida a los 28 días es evaluada conforme a los
                criterios de la Norma NMX-C-155-ONNCCE-2014{"\n"}
                Los resultados solo están relacionados con los items ensayados.{" "}
                {"\n"}
              </Text>
              <View style={styles.sectionObs}>
                <Text style={{ textAlign: "left", fontSize: 8 }}>
                  Observaciones:
                </Text>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "31vw",
                    alignSelf: "stretch",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.observations}>{config?.observacion}</Text>
                </View>
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "30vw",
                    alignSelf: "stretch",
                    flexDirection: "row",
                    marginLeft: 50,
                  }}
                ></View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                }}
              >
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "40vw",
                    alignSelf: "stretch",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.observations}>
                    {/* {config?.observaciones} observaciones linea 2 */}
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 50,
                    width: "30vw",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 8,
                    }}
                  >
                    Signatario de laboratorio
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                }}
              >
                <View
                  style={{
                    borderBottom: 0.5,
                    borderColor: "black",
                    width: "40vw",
                    alignSelf: "stretch",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.observations}>
                    {/* {config?.observaciones} observaciones linea 3*/}
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 50,
                    width: "30vw",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 8,
                    }}
                  >
                    {config?.firma}
                  </Text>
                </View>
              </View>
              <Text style={styles.footerRev}>
                {`Codigo: ${config?.codigo}; Pagina ${numeroDePagina} de ${numPaginasFinal}`}
                {"\n"}
                {config?.fechaVigor}
              </Text>
              {/* <Text style={styles.footerAddr}>
                lázaro cardenas nº.436 col. emiliano zapata, monterrey, n.l.
                c.p. 64390 tels, 81-8347-8528, 81-83478570 y 81-8348-3485
              </Text>
              <Text style={styles.footerWebsite}>www.geotecnia.mx</Text> */}
            </View>
          </View>
        );
        pagesFinal.push(pages);
        numeroDePagina++;
      }
    });
  } else {
    pages = (
      <View style={styles.section}>
        <View style={{ flexDirection: "row" }}>
          <Image
            style={
              (styles.image,
              {
                flexDirection: "column",
                width: " 80px",
                height: "80px",
                marginHorizontal: 10,
              })
            }
            src={logoGCC}
          />
          <View style={{ flexDirection: "column" }}>
            <Text style={styles.title}>
              GEOTECNIA Y CALIDAD CONSTRUCTIVA, S.A. DE C.V.
            </Text>
            <Text style={styles.subtitle}>
              {config?.titulo ||
                "SERVICIOS DE CONTROL DE CALIDAD Y ASESORIA TÉCNICA EN LA CONSTRUCCIÓN LABORATORIO DE SUELOS, CONCRETOS Y ASFALTOS"}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.text}>
                {config?.informe ||
                  "Informe de control de calidad de concreto fresco y endurecido"}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "flex-end",
          }}
        >
          <Text
            style={{
              textAlign: "right",
              fontSize: 8,
              marginHorizontal: 12,
              marginTop: 12,
            }}
          >
            Fecha de emisión de informe:
          </Text>
          <View
            style={{
              borderBottom: 0.5,
              borderColor: "black",
              width: "20%",
              alignSelf: "stretch",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                marginLeft: 25,
                fontSize: 8,
                position: "absolute",
              }}
            >
              {/* {formatDate(data.concretos.fechaEmision)} */}
              {formatDate(data.concretos.fechaEnsaye)}
            </Text>
          </View>
        </View>

        {/* Text */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{ fontSize: 8, marginBottom: 10, alignSelf: "flex-end" }}
          >
            Resultado del ensaye a la compresión, en cilindros moldeados de
            Concreto de:
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {/* Box 1 */}
            <View
              style={{
                border: "1px solid #000",
                width: 15,
                height: 15,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Text style={{ fontSize: 10, alignSelf: "center" }}>/</Text>
            </View>
            <Text style={{ fontSize: 7, alignSelf: "center", marginLeft: 5 }}>
              15x30 cm
            </Text>
            {/* Box 2 */}
            <View
              style={{
                border: "1px solid #000",
                width: 15,
                height: 15,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                marginLeft: 30,
              }}
            >
              <Text style={{ fontSize: 10, alignSelf: "center" }}>/</Text>
            </View>
            <Text style={{ fontSize: 7, alignSelf: "center", marginLeft: 5 }}>
              10x20 cm
            </Text>
          </View>
        </View>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
              marginBottom: 5,
            }}
          >
            <Text style={{ textAlign: "right", fontSize: 8, margin: 0 }}>
              Obra:
            </Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "50vh",
                alignSelf: "stretch",
                marginBottom: 0,
                marginLeft: 32,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  alignSelf: "stretch",
                  alignItems: "center",
                  textTransform: "uppercase",
                  fontSize: 8,
                  position: "relative",
                }}
              >
                {data.nombreObra}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
              marginBottom: 3,
            }}
          >
            <Text style={{ textAlign: "right", fontSize: 8 }}>Cliente:</Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "50vh",
                alignSelf: "stretch",
                marginBottom: 0,
                marginLeft: 25,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center",
                  fontSize: 8,
                  textTransform: "uppercase",
                  position: "relative",
                }}
              >
                {data.clientes.nomContacto}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
              marginBottom: 5,
            }}
          >
            <Text style={{ textAlign: "right", fontSize: 8 }}>Ubicación:</Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "50vh",
                alignSelf: "stretch",
                marginBottom: 0,
                marginLeft: 14,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center",
                  fontSize: 8,
                  textTransform: "uppercase",
                  position: "relative",
                }}
              >
                {data.ubicacion}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
              marginBottom: 5,
            }}
          >
            <Text style={{ textAlign: "right", fontSize: 8 }}>Atencion:</Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "50vh",
                alignSelf: "stretch",
                marginBottom: 0,
                marginLeft: 18,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center",
                  fontSize: 8,
                  textTransform: "uppercase",
                  position: "relative",
                }}
              >
                {data.concretos.atencion}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
              marginBottom: 5,
            }}
          >
            <Text style={{ textAlign: "right", fontSize: 8 }}>
              Resistencia del Proyecto:
            </Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "6vh",
                alignSelf: "stretch",
                marginBottom: 10,
                marginLeft: 10,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  fontSize: 8,
                  position: "relative",
                }}
              >
                0 kg/cm²
              </Text>
            </View>
            <Text style={{ textAlign: "right", fontSize: 8, marginLeft: 17 }}>
              Revenimiento del Proyecto:
            </Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "6vh",
                alignSelf: "stretch",
                marginBottom: 10,
                marginLeft: 10,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  fontSize: 8,
                  position: "relative",
                }}
              >
                0 cm
              </Text>
            </View>
            <Text style={{ textAlign: "right", fontSize: 8, marginLeft: 17 }}>
              Tamaño Máx agregado:
            </Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "6vh",
                alignSelf: "stretch",
                marginBottom: 10,
                marginLeft: 10,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flexDirection: "row",
                  fontSize: 8,
                  position: "relative",
                }}
              >
                0 cm
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text
            style={{
              ...styles.sectionTitle,
              marginBottom: 150,
              marginTop: 150,
            }}
          >
            No se encontraron pruebas con carga para este día
          </Text>
        </View>
        <View>
          <Text style={styles.nmx}>
            Ensaye realizado de acuerdo a la Nómina NMX-C-083-ONNCCE-2014, además de las Normas aplicadas NMX-C-155-ONNCCE-2014,
            NMX-C-156-ONNCCE-2010, NMX-C-159-ONNCCE-2016, NMX-X-160-ONNCCE-2004,
            NMX-C-161-ONNCCE-2013 y NMX-C-109-ONNCCE-2013{"\n"}
            Correspondencia entre unidades: 1 kg/cm² dividido por 10.197 es
            igual a 0.0981 Mpa{"\n"}
            Este informe no puede ser reproducido sin la autorización por
            escrito por el Laboratorio{"\n"}
            La resistencia obtenida a los 28 días es evaluada conforme a los
            criterios de la Norma NMX-C-155-ONNCCE-2014{"\n"}
            Los resultados solo están relacionados con los items ensayados.{" "}
            {"\n"}
          </Text>
          <View style={styles.sectionObs}>
            <Text style={{ textAlign: "left", fontSize: 8 }}>
              Observaciones:
            </Text>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "31vw",
                alignSelf: "stretch",
                flexDirection: "row",
              }}
            >
              <Text style={styles.observations}>{config?.observacion}</Text>
            </View>
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "30vw",
                alignSelf: "stretch",
                flexDirection: "row",
                marginLeft: 50,
              }}
            ></View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
            }}
          >
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "40vw",
                alignSelf: "stretch",
                flexDirection: "row",
              }}
            >
              <Text style={styles.observations}>
                {/* {config?.observaciones} observaciones linea 2 */}
              </Text>
            </View>
            <View
              style={{
                marginLeft: 50,
                width: "30vw",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 8,
                }}
              >
                Signatario de laboratorio
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "flex-start",
            }}
          >
            <View
              style={{
                borderBottom: 0.5,
                borderColor: "black",
                width: "40vw",
                alignSelf: "stretch",
                flexDirection: "row",
              }}
            >
              <Text style={styles.observations}>
                {/* {config?.observaciones} observaciones linea 3*/}
              </Text>
            </View>
            <View
              style={{
                marginLeft: 50,
                width: "30vw",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 8,
                }}
              >
                {config?.firma}
              </Text>
            </View>
          </View>
          <Text style={styles.footerRev}>
            {`Codigo: ${config?.codigo}; Pagina ${numeroDePagina} de ${numPaginasFinal}`}{"\n"}
            {config?.fechaVigor}
          </Text>
          {/* <Text style={styles.footerAddr}>
            lázaro cardenas nº.436 col. emiliano zapata, monterrey, n.l. c.p.
            64390 tels, 81-8347-8528, 81-83478570 y 81-8348-3485
          </Text>
          <Text style={styles.footerWebsite}>www.geotecnia.mx</Text> */}
        </View>
      </View>
    );
    pagesFinal.push(pages);
  }
  return (
    <Document title="Proyectos de Obra">
      <>
        {pagesFinal.map((page, i) => {
          return (
            <Page size="A4" style={styles.page} key={i} id={i} wrap>
              {page}
            </Page>
          );
        })}
        {/* <Page size="A4" style={styles.page} wrap>
          <Text>Hi</Text>
        </Page>
        <Page size="A4" style={styles.page} wrap>
          <Text>Hi 2</Text>
        </Page> */}
      </>
    </Document>
  );
};

export default DocuPDF;
