import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  concreto: {
    titulo: null,
    informe: null,
    normas: null,
    firma: null,
    codigo: null,
    fechaVigor: null,
    observacion: null,
    observacionOptions: [],
  },
  terraceria: {
    titulo: null,
    informe: null,
    nota: null,
    normas: null,
    firma: null,
    tecnico: null,
    tecnicoOptions: [],
    trompaOptions: [],
    basculaOptions: [],
    flexometroOptions: [],
    observacion: null,
    observacionOptions: [],
  },
  firmaOptions: [],
};

export const configuracionesSlice = createSlice({
  name: "configuraciones",
  initialState,
  reducers: {
    setConcretoConfig: (state, action) => {
      state.concreto = action.payload;
    },
    setFirmaConcreto: (state, action) => {
      state.concreto.firma = action.payload;
    },
    setObservacionConcreto: (state, action) => {
      state.concreto.observacion = action.payload;
    },
    setTerraceriaConfig: (state, action) => {
      state.terraceria = action.payload;
    },
    setFirmaTerraceria: (state, action) => {
      state.terraceria.firma = action.payload;
    },
    setTecnicoTerraceria: (state, action) => {
      state.terraceria.tecnico = action.payload;
    },
    setFirmaOptionsState: (state, action) => {
      state.firmaOptions = action.payload;
    },
    setObservacionTerraceria: (state, action) => {
      state.terraceria.observacion = action.payload;
    },
    setFechaVigor: (state, action) => {
      state.fechaVigor = action.payload;
    },
  },
});

export const selectConfiguracionesConcreto = (state) =>
  state.configuraciones.concreto;

export const selectConfiguracionesTerraceria = (state) =>
  state.configuraciones.terraceria;

export const selectFirmaOptions = (state) => state.configuraciones.firmaOptions;

export const {
  setConcretoConfig,
  setFirmaConcreto,
  setObservacionConcreto,
  setTerraceriaConfig,
  setFirmaTerraceria,
  setTecnicoTerraceria,
  setFirmaOptionsState,
  setObservacionTerraceria,
  setFechaVigor,
} = configuracionesSlice.actions;

export default configuracionesSlice.reducer;
