import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export function dateToString(date, format = "fullString") {
  let result;
  switch (format) {
    case "fullString":
      const day = date.getDate();
      const month = capitalizeFirstLetter(
        date.toLocaleString("es", { month: "long" })
      );
      const year = date.getFullYear();
      result = `${day} de ${month} del ${year}`;
      break;

    default:
      break;
  }
  return result;
}
