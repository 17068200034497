import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConcretoConfig,
  setFirmaOptionsState,
} from "../../features/configuraciones/configuracionesSlice";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import {
  selectConfiguracionesConcreto,
  selectFirmaOptions,
} from "../../features/configuraciones/configuracionesSlice";
import Swal from "sweetalert2";

// Declaracion de tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles((theme) => ({
  half: {
    display: "flex",
    flexDirection: "row",
    columnGap: "2rem",
  },
  top: {
    marginTop: "30px",
  },
}));

// Declaracion de configuraciones de terraceria
const ConcretoConfig = () => {
  // Declaracion de estilos
  const classes = useStyles();
  // Filtro para autocompletes
  const filter = createFilterOptions();

  // Declaracion de controladores
  const userRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const concretoConfig = useSelector(selectConfiguracionesConcreto);
  const firmaOptionsState = useSelector(selectFirmaOptions);

  // Declaracion de estados
  const [titulo, setTitulo] = useState(concretoConfig?.titulo); //Servicios de control de calidad y asesoría técnica en la construcción laboratorio de suelos, concretos y asfaltos
  const [informe, setInforme] = useState(concretoConfig?.informe);
  const [normas, setNormas] = useState(concretoConfig?.normas);
  const [codigo, setCodigo] = useState(concretoConfig?.codigo);
  const [fechaVigor, setFechaVigor] = useState(concretoConfig?.fechaVigor);
  const [firma, setFirma] = useState(null);
  const [firmaOptions, setFirmaOptions] = useState(firmaOptionsState || []);
  const [observacion, setObservacion] = React.useState(null);
  const [observacionOptions, setObservacionOptions] = useState(concretoConfig?.observacionOptions || []);
  useEffect(() => {
    // Cuando el componente se renderiza, hacer fetch a la configuración en la bd
    const fetchConfiguracion = async () => {
      try {
        const response = await axiosPrivate.get("/configuracion/");
        const data = response?.data[0];
        // Almacenar la respuesta y sus estados en redux
        dispatch(
          setConcretoConfig({
            titulo: data?.concretoTitulo,
            informe: data?.concretoInforme,
            normas: data?.concretoNormas,
            codigo: data?.codigo,
            fechaVigor: data?.concretoEntradaVigor,
            firma: data?.concretoFirma,
            observacion: data?.concretoObservaciones,
            observacionOptions: data?.concretoObservacionesOptions || [],
          })
        );
        dispatch(setFirmaOptionsState(data?.firmaOptions || []));
      } catch (error) {
        console.error(error);
      }
    };

    fetchConfiguracion();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Actualizar configuraciones en BD
      const response = await axiosPrivate.patch("/configuracion/edit", {
        concretoTitulo: titulo,
        concretoInforme: informe,
        concretoNormas: normas,
        concretoFirma: firma?.title,
        firmaOptions: firmaOptions,
        concretoCodigo: codigo,
        concretoEntradaVigor: fechaVigor,
        concretoObservaciones: observacion?.title,
        concretoObservacionesOptions: observacionOptions,
      });
      // Si la respuesta incluye datos (fue exitosa), muestra feedback (sweet alert) y almacena estados en redux
      if (response?.data) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cambios guardados exitosamente",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        dispatch(
          setConcretoConfig({
            titulo: response.data?.concretoTitulo,
            informe: response.data?.concretoInforme,
            normas: response.data?.concretoNormas,
            firma: response.data?.concretoFirma,
            codigo: response.data?.concretoCodigo,
            fechaVigor: response.data?.concretoEntradaVigor,
            observacion: response.data?.concretoObservaciones,
            observacionOptions: response.data?.concretoObservacionesOptions || [],
          })
        );
        console.log(response?.concretoEntradaVigor);
        console.log(concretoConfig);
        dispatch(setFirmaOptionsState(firmaOptions));
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error",
        text: "Intente de nuevo",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    }
  };

  // BODY Principal
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: 900,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              margin="normal"
              multiline
              rows={3}
              ref={userRef}
              fullWidth
              id="titulo"
              label="Título"
              name="titulo"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              autoComplete="titulo"
              autoFocus
            />
            <TextField
              margin="normal"
              multiline
              rows={3}
              ref={userRef}
              fullWidth
              id="informe"
              label="Informe"
              name="informe"
              value={informe}
              onChange={(e) => setInforme(e.target.value)}
              autoComplete="informe"
              // autoFocus
            />
            <TextField
              margin="normal"
              ref={userRef}
              fullWidth
              multiline
              rows={3}
              id="normas"
              label="Normas"
              name="normas"
              value={normas}
              onChange={(e) => setNormas(e.target.value)}
              autoComplete="normas"
              // autoFocus
            />
            {/* Firma */}
            <Autocomplete
              value={firma || null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setFirma({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setFirmaOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setFirma({
                    title: newValue.inputValue,
                  });
                } else {
                  // When value is part of options
                  setFirma(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="firma"
              options={firmaOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Firma" />
              )}
            />
            <TextField
              margin="normal"
              ref={userRef}
              fullWidth
              multiline
              rows={3}
              id="Codigo"
              label="Codigo"
              name="Codigo"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
              autoComplete="codigo"
              // autoFocus
            />
            {/* nuevo dato */}
            <TextField
              margin="normal"
              ref={userRef}
              fullWidth
              multiline
              rows={3}
              id="FechaVigor"
              label="Fecha de entrada en vigor"
              name="FechaVigor"
              value={fechaVigor}
              onChange={(e) => setFechaVigor(e.target.value)}
              autoComplete="fechaVigor"
              // autoFocus
            />

            <Autocomplete
              value={observacion || null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setObservacion({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setObservacionOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setObservacion({
                    title: newValue.inputValue,
                  });
                } else {
                  // When value is part of options
                  setObservacion(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="observacion"
              options={observacionOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Observación" />
              )}
            />

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Cambiar configuraciones de concreto
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ConcretoConfig;
