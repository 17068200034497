import { useEffect } from "react";
import { axiosPrivate } from "../../api/axios";
import useRefreshToken from "./useRefreshToken";
import { setAccessToken } from "../../features/auth/authSlice";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../services/auth";

// Declaracion de controlador AXIOS para peticiones con backend
const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Access token para firmar las peticiones al backend
  const accessToken = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        if (error?.response?.status === 403 && prevRequest?.sent) {
          await logout();
          dispatch(setAccessToken(""));

          navigate("/auth", { state: { from: location }, replace: true });
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [accessToken, refresh, dispatch, location, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;
