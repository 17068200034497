import axios from "../api/axios";

// Controlador para iniciar sesion
export const login = (email, password) => {
  return axios.post(
    "/user/login",
    {
      email: email,
      password: password,
    },
    {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }
  );
};

// Controlador para cerrar sesion
export const logout = () => {
  return axios.post(
    "/user/logout",
    {},
    {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }
  );
};
