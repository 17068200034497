import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// Declaracion de componente de AUTHENTICACION
const RequireAuth = () => {
  // Declaracion de controladores
  const location = useLocation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  // BODY Principal
  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default RequireAuth;
