import React from "react";
import DayTable from "./DayTable";
import { makeStyles } from "@material-ui/core/styles";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal
  sidebar: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "scroll",
    height: "82vh",
    // backgroundColor: "#000",
    margin: "30px 30px 0px 30px",
    paddingBottom: "3rem",
    // "& > *:nth-child(1)": {
    //   flex: "0 1 25%",
    // },
    // "& > *:nth-child(2)": {
    // flex: "0 1 75%",
    // },
  },
}));

// Declaracion de pantalla TERRACERIA
const Terraceria = () => {
  // Declaracion de esilos
  const classes = useStyles();

  // BODY principal
  return (
    <div className={classes.sidebar}>
      <DayTable />
    </div>
  );
};

export default Terraceria;
