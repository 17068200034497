import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pruebas: "",
  data: "",
  case: null,
};

export const pdfSlice = createSlice({
  name: "pdf",
  initialState,
  reducers: {
    setPruebas: (state, action) => {
      state.pruebas = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCase: (state, action) => {
      state.case = action.payload;
    },
  },
});

export const { setPruebas, setData, setCase } = pdfSlice.actions;

export default pdfSlice.reducer;
