import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./features/auth/authSlice";
import pdfReducer from "./features/auth/pdfSlice";
import configuracionesReducer from "./features/configuraciones/configuracionesSlice";

const persistAuthConfig = {
  key: "auth",
  storage,
};
const persistPdfConfig = {
  key: "pdf",
  storage,
};
const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer);
const persistedpdfReducer = persistReducer(persistPdfConfig, pdfReducer);

const config = () => {
  let store = configureStore({
    reducer: {
      auth: persistedAuthReducer,
      pdf: persistedpdfReducer,
      configuraciones: persistReducer(
        {
          key: "configuraciones",
          storage,
        },
        configuracionesReducer
      ),
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: ["pdf"],
        },
      }),
  });
  let persistor = persistStore(store);
  return { store, persistor };
};
export default config;
