import { Link } from "react-router-dom";
import {
  Alert,
  Container,
  CssBaseline,
  Stack,
  Typography,
} from "@mui/material";

// Declaracion de pantalla 404
const Missing = () => {
  // BODY Principal
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            mb: 1,
            textAlign: "center",
            pb: 3,
            fontFamily: "monospace",
            fontSize: 60,
          }}
        >
          404
        </Typography>
        <Stack sx={{ width: "100%" }} spacing={6}>
          <Alert variant="outlined" severity="error">
            <strong>Página no encontrada</strong>
          </Alert>

          <Link to="/">Visite nuestra página de inicio</Link>
        </Stack>
      </Container>
    </>
  );
};

export default Missing;
