import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import config from "../../imgs/settings.png";
import historial from "../../imgs/history.png";
import cylinder from "../../imgs/cylinder.png";
import grass from "../../imgs/grass.png";
import { createTheme } from "@mui/material/styles";
import { useEffect } from "react";
import {
  setConcretoConfig,
  setTerraceriaConfig,
  setFirmaOptionsState,
} from "../../features/configuraciones/configuracionesSlice";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";

//Declarar tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles({
  // Objeto principal, wrapper
  root: {
    display: "flex",
    backgroundColor: "#F8F8F8",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      width: "100vw",
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
  },
});

// Declaracion de pantalla HOME
const Home = () => {
  // Declaracion de estilos
  const cls = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfiguraciones = async () => {
      const configResponse = await axiosPrivate.get("/configuracion/");
      const data = configResponse?.data[0];
      // Almacenar la respuesta y sus estados en redux
      console.log("configResponse", configResponse);
      dispatch(
        setConcretoConfig({
          titulo: data?.concretoTitulo,
          informe: data?.concretoInforme,
          normas: data?.concretoNormas,
          codigo: data?.codigo,
          fechaVigor: data?.concretoEntradaVigor,
          firma: data?.concretoFirma,
          observacion: data?.concretoObservaciones,
          observacionOptions: data?.concretoObservacionesOptions || [],
        })
      );
      console.log(data?.concretoEntradaVigor);
      dispatch(
        setTerraceriaConfig({
          titulo: data?.terraceriaTitulo,
          informe: data?.terraceriaInforme,
          nota: data?.terraceriaNota,
          normas: data?.terraceriaNormas,
          tecnico: data?.terraceriaTecnico,
          tecnicoOptions: data?.terraceriaTecnicoOptions || [],
          firma: data?.terraceriaFirma,
          trompaOptions: data?.terraceriaTrompasOptions || [],
          basculaOptions: data?.terraceriaBasculasOptions || [],
          flexometroOptions: data?.terraceriaFlexometrosOptions || [],
          observacion: data?.terraceriaObservaciones,
          observacionOptions: data?.terraceriaObservacionesOptions || [],
        })
      );
      dispatch(setFirmaOptionsState(data?.firmaOptions || []));
    };

    fetchConfiguraciones();
  }, []);

  // BODY Principal
  return (
    <>
      <div className={cls.root}>
        <div>
          <Card
            title="Concreto"
            route="/concreto"
            text="Agregar prueba de concreto"
            image={cylinder}
          />
          <Card
            title="Historial"
            text="lorem ipsum lorem"
            image={historial}
            route="/historial"
          />
        </div>
        <div>
          <Card
            title="Terracería"
            route="/terraceria"
            text="Agregar prueba de terracería"
            image={grass}
          />

          <Card
            title="Configuraciones"
            text="lorem ipsum lorem"
            route="/configuraciones"
            image={config}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
