import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../services/auth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// Declaracion de tema
const theme = createTheme();

// Declaracion de pantalla OLVIDO PWD
export default function ForgotPassword() {
  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const userRef = useRef();
  const errRef = useRef();
  const dispatch = useDispatch();

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");

  // Metodos para manejo de datos y acciones

  // BODY Principal
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <CheckCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
              ¡Correo enviado!
            </Typography>
            <Typography variant="body2" align="center">
              Hemos enviado un correo electrónico con un link para cambiar la
              contraseña a @gmail.com: <br /> ¡Revisa tu correo y haz click en
              el link para cambiar la contraseña.!
            </Typography>
            <br />
            <Grid container>
              <Grid item xs>
                <Link href="/auth" variant="body2">
                  Regresar al inicio de sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </ThemeProvider>
    </div>
  );
}
