import React from "react";
import DayTable from "./DayTable";
import { makeStyles } from "@material-ui/core/styles";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal
  sidebar: {
    display: "flex",
    flexWrap: "wrap",
    height: "82vh",
    overflowY: "scroll",
    margin: "30px 30px 0px 30px",
    paddingBottom: "3rem",
    // "& > *:nth-child(1)": {
    //   flex: "0 1 25%",
    // },
    // "& > *:nth-child(2)": {
    //   flex: "0 1 75%",
    //   // margin: "20px",
    //   // backgroundColor: "#000",
    // },
  },
}));

// Declaracion de pantalla CONCRETO
const Concreto = () => {
  // Declaracion de esilos
  const classes = useStyles();

  // BODY principal
  return (
    <>
      <div className={classes.sidebar}>
        <DayTable />
      </div>
    </>
  );
};

export default Concreto;
