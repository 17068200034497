import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfiguracionesTerraceria,
  setTerraceriaConfig,
  selectFirmaOptions,
  setFirmaOptionsState,
} from "../../features/configuraciones/configuracionesSlice";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Declaracion de tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles((theme) => ({
  half: {
    display: "flex",
    flexDirection: "row",
    columnGap: "2rem",
  },
  top: {
    marginTop: "30px",
  },
}));

// Declaracion de configuraciones de terraceria
const TerraceriaConfig = () => {
  // Declaracion de estilos
  const classes = useStyles();
  const filter = createFilterOptions();

  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const userRef = useRef();
  const errRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const terraceriaConfig = useSelector(selectConfiguracionesTerraceria);
  const firmaOptionsState = useSelector(selectFirmaOptions);

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");
  const [titulo, setTitulo] = React.useState(terraceriaConfig?.titulo || ""); //Servicios de control de calidad y asesoría técnica en la construcción laboratorio de suelos, concretos y asfaltos
  const [informe, setInforme] = React.useState(terraceriaConfig?.informe || "");
  const [nota, setNota] = React.useState(terraceriaConfig?.nota || "");
  const [normas, setNormas] = React.useState(terraceriaConfig?.normas || "");
  const [tecnico, setTecnico] = useState(null);
  const [tecnicoOptions, setTecnicoOptions] = useState(
    terraceriaConfig?.tecnicoOptions || []
  );
  const [firma, setFirma] = React.useState(null);
  const [firmaOptions, setFirmaOptions] = useState(firmaOptionsState || []);
  const [trompa, setTrompa] = React.useState(null);
  const [trompaOptions, setTrompaOptions] = useState(terraceriaConfig?.trompaOptions || []);
  const [bascula, setBascula] = React.useState(null)
  const [basculaOptions, setBasculaOptions] = useState(terraceriaConfig?.basculaOptions || []);
  const [flexometro, setFlexometro] = React.useState(null)
  const [flexometroOptions, setFlexometroOptions] = useState(terraceriaConfig?.flexometroOptions || []);
  const [observacion, setObservacion] = React.useState(null);
  const [observacionOptions, setObservacionOptions] = useState(terraceriaConfig?.observacionOptions || []);
  const [codigo, setCodigo] = React.useState("");
  const [vigor, setVigor] = React.useState("");
  const [footer, setFooter] = React.useState("");
  const [newBascula, setNewBascula] = React.useState(false);
  const [newTrompa, setNewTrompa] = React.useState(false);
  const [newFlex, setNewFlex] = React.useState(false);

  React.useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const response = await axiosPrivate.get("/configuracion/");
        const data = response?.data[0];
        console.log(response);
        dispatch(
          setTerraceriaConfig({
            titulo: data?.terraceriaTitulo,
            informe: data?.terraceriaInforme,
            nota: data?.terraceriaNota,
            normas: data?.terraceriaNormas,
            tecnico: data?.terraceriaTecnico,
            tecnicoOptions: data?.terraceriaTecnicoOptions || [],
            trompaOptions: data?.terraceriaTrompasOptions || [],
            basculaOptions: data?.terraceriaBasculasOptions || [],
            flexometroOptions: data?.terraceriaFlexometrosOptions || [],
            observacion: data?.terraceriaObservaciones,
            observacionOptions: data?.terraceriaObservacionesOptions || [],
            firma: data?.terraceriaFirma,
          })
        );
        dispatch(setFirmaOptionsState(data?.firmaOptions || []));
      } catch (error) {
        console.error(error);
      }
    };
    console.log("TERRACERIA", terraceriaConfig);
    fetchConfiguracion();
  }, []);

  // Metodos para manejo de datos y acciones
  const handleChange = (event, newAlignment) => {
    setFirma(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    var quantityBascula = 0;
    var quantityTrompa = 0;
    var quantityFlex = 0;
    if(newBascula){
      quantityBascula = Number(bascula.title);
      while (basculaOptions.length) {
        basculaOptions.pop();
      }
      for (var i = 1; i <= quantityBascula; i++) {
        basculaOptions.push({ title: "0"+ i.toString() });
      }
      setNewBascula(false);
    }
    if(newTrompa){
      quantityTrompa = Number(trompa.title);
      while (trompaOptions.length) {
        trompaOptions.pop();
      }
      for (var i = 1; i <= quantityTrompa; i++) {
        trompaOptions.push({ title: "0"+ i.toString() });
      }
      setNewTrompa(false);
    }
    if(newFlex){
      quantityFlex = Number(flexometro.title);
      while (flexometroOptions.length) {
        flexometroOptions.pop();
      }
      for (var i = 1; i <= quantityFlex; i++) {
        flexometroOptions.push({ title: "0"+ i.toString() });
      }
      setNewFlex(false);
    }
    
    try {
      const response = await axiosPrivate.patch("/configuracion/edit", {
        terraceriaTitulo: titulo,
        terraceriaInforme: informe,
        terraceriaNota: nota,
        terraceriaNormas: normas,
        terraceriaTecnico: tecnico?.title,
        terraceriaTecnicoOptions: tecnicoOptions,
        terraceriaTrompaOptions: trompaOptions,
        terraceriaBasculaOptions: basculaOptions,
        terraceriaFlexometroOptions: flexometroOptions,
        terraceriaObservaciones: observacion?.title,
        terraceriaObservacionesOptions: observacionOptions,
        terraceriaFirma: firma?.title,
        firmaOptions: firmaOptions,
      });
      if (response?.data) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cambios guardados exitosamente",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        dispatch(
          setTerraceriaConfig({
            titulo: response.data?.terraceriaTitulo,
            informe: response.data?.terraceriaInforme,
            nota: response.data?.terraceriaNota,
            normas: response.data?.terraceriaNormas,
            tecnico: response.data?.terraceriaTecnico,
            tecnicoOptions: tecnicoOptions,
            trompaOptions: trompaOptions,
            basculaOptions: basculaOptions,
            flexometroOptions: flexometroOptions,
            observacion: response.data?.terraceriaObservaciones,
            observacionOptions: observacionOptions,
            firma: response.data?.terraceriaFirma,
          })
        );
        dispatch(setFirmaOptionsState(firmaOptions));
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error",
        text: "Intente de nuevo",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    }
  };

  // BODY Principal
  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "red",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: 900,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              margin="normal"
              // variant="standard"
              multiline
              rows={3}
              ref={userRef}
              fullWidth
              id="titulo"
              label="Título"
              name="titulo"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              autoComplete="titulo"
              autoFocus
            />
            <TextField
              margin="normal"
              // variant="standard"
              multiline
              rows={3}
              ref={userRef}
              fullWidth
              id="informe"
              label="Informe"
              name="informe"
              value={informe}
              onChange={(e) => setInforme(e.target.value)}
              autoComplete="informe"
              // autoFocus
            />

            <TextField
              margin="normal"
              // variant="standard"
              ref={userRef}
              fullWidth
              id="nota"
              label="Nota"
              name="nota"
              value={nota}
              onChange={(e) => setNota(e.target.value)}
              autoComplete="nota"
              // autoFocus
            />
            <TextField
              margin="normal"
              // variant="standard"
              ref={userRef}
              fullWidth
              id="normas"
              label="Normas"
              name="normas"
              value={normas}
              onChange={(e) => setNormas(e.target.value)}
              autoComplete="normas"
              // autoFocus
            />
            {/* <div className={classes.half}>
              <FormControl
                sx={{
                  minWidth: 435,
                  mt: 2,
                }}
              >
                <InputLabel>Técnico Laborista</InputLabel>
                <Select
                  labelId="tecnico-id"
                  id="tecnico"
                  value={tecnico}
                  label="tecnico"
                  onChange={handleChange}
                  // size="small"
                >
                  <MenuItem value="">
                    <em>Técnico</em>
                  </MenuItem>
                  <MenuItem value={"Ingeniero Gastón"}>
                    Ingeniero Gastón
                  </MenuItem>
                  <MenuItem value={"Pablo Jasso"}>Pablo Jasso</MenuItem>
                </Select>
                <FormHelperText>Técnico laborista</FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: 435,
                  mt: 2,
                }}
              >
                <InputLabel>Firma del responsable</InputLabel>
                <Select
                  labelId="firma-id"
                  id="firma"
                  value={firma}
                  label="firma"
                  onChange={handleChange}
                  // size="small"
                >
                  <MenuItem value="">
                    <em>Firma</em>
                  </MenuItem>
                  <MenuItem value={"Ingeniero Gastón"}>
                    Ingeniero Gastón
                  </MenuItem>
                  <MenuItem value={"Pablo Jasso"}>Pablo Jasso</MenuItem>
                </Select>
                <FormHelperText>Firma de respresentante de GCC</FormHelperText>
              </FormControl>
            </div> */}
            <Autocomplete
              value={firma || null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setFirma({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setFirmaOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setFirma({
                    title: newValue.inputValue,
                  });
                } else {
                  // When value is part of options
                  setFirma(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="firma"
              options={firmaOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Firma" />
              )}
            />
            <Autocomplete
              value={tecnico || null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setTecnico({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setTecnicoOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setTecnico({
                    title: newValue.inputValue,
                  });
                } else {
                  // When value is part of options
                  setTecnico(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="tecnico"
              options={tecnicoOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Técnico Laborista"
                />
              )}
            />
            <Autocomplete
              value={observacion || null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setObservacion({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setObservacionOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setObservacion({
                    title: newValue.inputValue,
                  });
                } else {
                  // When value is part of options
                  setObservacion(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="observacion"
              options={observacionOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Observación"
                />
              )}
            />

            <Autocomplete
              value={null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setTrompa({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setTrompaOptions((prevState) => [
                    ...prevState,
                    //console.log(prevState),
                    { title: newValue.inputValue },
                  ]);
                  setTrompa({
                    title: newValue.inputValue,
                  });
                  setNewTrompa(true);
                } else {
                  setTrompa(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="trompa"
              options={trompaOptions}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Trompa"
                />
              )}
            />
                
            <Autocomplete
              value={null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setBascula({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setBasculaOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setBascula({
                    title: newValue.inputValue,
                  });
                  setNewBascula(true);
                } else {
                  setBascula(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                  
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="bascula"
              options={basculaOptions}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Báscula"
                />
              )}
            />
                  
            <Autocomplete
              value={null}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setFlexometro({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setFlexometroOptions((prevState) => [
                    ...prevState,
                    { title: newValue.inputValue },
                  ]);
                  setFlexometro({
                    title: newValue.inputValue,
                  });
                  setNewFlex(true);
                } else {
                  setFlexometro(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="flexometro"
              options={flexometroOptions}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              freeSolo
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Flexómetro"
                />
              )}
            />
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Cambiar configuraciones de terracería
            </Button>
          </Box>
        </Box>
      </Container>
      {/* </ThemeProvider> */}
    </div>
  );
};

export default TerraceriaConfig;
