import { Grid } from "@mui/material";
import React from "react";
import { dateToString } from "../services/dateToString";

const styles = {
  title: {
    textAlign: "center",
    marginBottom: 5,
  },
  date: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    padding: 5,
  },
};

const HeaderFecha = ({ date, style }) => {
  return (
    <Grid container justifyContent={"center"} sx={{ ...style }}>
      <Grid item xs={12} md={8}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <p style={styles.title}> Mostrando pruebas para la fecha</p>
          </Grid>
          <Grid item xs={12}>
            <p style={styles.date}>{dateToString(date)}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderFecha;
