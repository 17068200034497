import DownloadIcon from "@mui/icons-material/Download";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState, useCallback } from "react";
import MaterialTable from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DocuPDF from "../PDF/DocuPDF";
import { useNavigate, useLocation } from "react-router-dom";
import { setData, setPruebas, setCase } from "../../features/auth/pdfSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  selectConfiguracionesConcreto,
  setFirmaConcreto,
  selectFirmaOptions,
} from "../../features/configuraciones/configuracionesSlice";
import Swal from "sweetalert2";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    backgroundColor: "#F8F8F8",
    padding: "10px",
  },
  // Objeto popup para insertar y editar
  modal: {
    position: "absolute",
    width: 450,
    height: 490,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  modalCarga: {
    position: "absolute",
    width: 450,
    // height: 490,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objeto popup para eliminar
  modalEliminar: {
    position: "absolute",
    width: 430,
    maxHeight: "80vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objetos de iconos
  iconos: {
    cursor: "pointer",
  },
  // Objetos de entradas de datos (campos de formularios)
  inputMaterial: {
    width: "100%",
  },
  // Objeto de contenedor de tabla
  scrollContainer: {
    maxHeight: "400px", // Adjust the height as needed
    overflowY: "auto",
  },
}));

// Ruteo de backend
const baseURL = "/pconcreto";

// Declaracion de tabla interior
const InnerDataTableConcreto = (props) => {
  // Declaracion de estilos
  const classes = useStyles();
  //ammm
  const [stats, setStats] = useState({});
  // Declaracion de controladores
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/vistaprevia";
  // Configuraciones de concreto en redux
  const firmaOptions = useSelector(selectFirmaOptions);
  // Declaracion de estados
  const [disabledButton, setDisabledButton] = useState(false);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalInsertarScreen, setModalInsertarScreen] = useState(0);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalCarga, setModalCarga] = useState(false);
  const [tableData, setTableData] = useState([{}]);
  const [hideColumns, setHideColumns] = useState(true);
  const [tamanoMaxAgregado, setTamanoMaxAgregado] = useState("");
  const [tamanoMaxAgregadoError, setTamanoMaxAgregadoError] = useState(false);
  const [nCilindro, setNCilindro] = useState(0);
  const [carga, setCarga] = useState(0);
  const [diametro, setDiametro] = useState(0);
  const [lugarColado, setLugarColado] = useState("");
  let [resistenciaDeProyecto, setResistenciaDeProyecto] = useState("");
  let [revObtenido, setRevObtenido] = useState("");
  let [revenimientoDeProyecto, setRevenimientoDeProyecto] = useState("");
  const [lastRevenimiento, setLastRevenimiento] = useState(0);
  const [lastResistencia, setLastResistencia] = useState(0);
  const [fechaMuestreo, setFechaMuestreo] = useState(new Date());
  const [duracion, setDuracion] = useState("");
  const [duracionArray, setDuracionArray] = useState([]);
  const [fechaEnsaye, setFechaEnsaye] = useState(null);
  const [fechaEnsayeArray, setFechaEnsayeArray] = useState([]);
  const [esfuerzoMaxKg, setEsfuerzoMaxKg] = useState(0);
  const [esfuerzoMaxMp, setEsfuerzoMaxMp] = useState(0);
  const [esfuerzoMaxObtenido, setEsfuerzoMaxObtenido] = useState(0);
  const [modeladoDeConcreto, setModeladoDeConcreto] = useState("");
  const [idConcreto, setIdConcreto] = useState();
  const [numG, setNumG] = useState(1);
  const [numeroGeneralGlobal, setNumeroGeneralGlobal] = useState(1);
  const [obraSeleccionada, setObraSeleccionada] = useState({
    id: "",
    nPrueba: "",
    nombreObra: "",
    ubicacion: "",
    clientes: {
      correoContacto: "",
      empresa: "",
      id: "",
      nomContacto: "",
      telContacto: "",
    },
    concretos: {
      resistenciaDeProyecto: "",
      revenimientoDeProyecto: "",
    },
  });
  // const [datosEdit, setDatosEdit] = useState();
  const [datosEdit, setDatosEdit] = useState([]);
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState({
    id: "",
    tamanoMaxAgregado: "",
    nCilindro: "",
    carga: "",
    diametro: "",
    lugarColado: "",
    revObtenido: "",
    fechaMuestreo: "",
    duracion: "",
    fechaEnsaye: "",
    esfuerzoMaxKg: "",
    modeladoDeConcreto: "",
    esfuerzoMaxMp: "",
    esfuerzoMaxObtenido: "",
    resistenciaDeProyecto: "",
    revenimientoDeProyecto: "",
  });

  // Declaracion de columnas de tabla
  const columnas = [
    {
      title: "Número General",
      field: "numeroGeneral",
      width: "auto",
    },
    {
      title: "Número de cilindro",
      field: "nCilindro",
      width: "auto",
    },
    {
      title: "Fecha de Muestreo",
      field: "fechaMuestreo",
      type: "date",
      dateSetting: {
        format: "dd/mm/yyyy",
      },
      width: "auto",
    },
    {
      title: "Duración",
      field: "duracion",
      width: "auto",
    },
    {
      title: "Fecha de Ensaye",
      field: "fechaEnsaye",
      type: "date",
      dateSetting: {
        format: "dd/mm/yyyy",
      },
      width: "auto",
    },
    {
      title: "Carga",
      field: "carga",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "diametro",
      field: "diametro",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Tamaño máximo agregado",
      field: "tamanoMaxAgregado",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Lugar colado",
      field: "lugarColado",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Revenimiento de proyecto",
      field: "revenimientoDeProyecto",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Resistencia de proyecto",
      field: "resistenciaDeProyecto",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Revenimiento obtenido",
      field: "revObtenido",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Esfuerzo máximo KG",
      field: "esfuerzoMaxKg",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Esfuerzo máximo MP",
      field: "esfuerzoMaxMp",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Esfuerzo máximo obtenido",
      field: "esfuerzoMaxObtenido",
      width: "auto",
      hidden: hideColumns,
    },
    // {
    //   title: "Promedio máximo KG",
    //   field: "promedioMaxKg",
    //   width: "auto",
    //   hidden: hideColumns,
    // },
    // {
    //   title: "Promedio máximo MP",
    //   field: "promedioMaxMp",
    //   width: "auto",
    //   hidden: hideColumns,
    // },
    // {
    //   title: "Promedio máximo obtenido",
    //   field: "promedioMaxObtenido",
    //   width: "auto",
    //   hidden: hideColumns,
    // },
    {
      title: "Modelado de concreto",
      field: "modeladoDeConcreto",
      width: "auto",
      hidden: hideColumns,
    },
  ];

  // Metodos para manejo de datos y acciones
  const handleMultiEditChange = (e) => {
    const { name, value } = e.target;

    if (name === "duracion") {
      const currentFechaMuestreo = new Date(datosEdit?.fechaMuestreo);
      let newFechaEnsaye = new Date(datosEdit?.fechaMuestreo);
      newFechaEnsaye.setDate(
        currentFechaMuestreo.getDate() + parseInt(value || 0, 10)
      );
      setDatosEdit((prevState) => ({
        ...prevState,
        [name]: value,
        fechaEnsaye: newFechaEnsaye,
      }));
      return;
    }
    if (name === "carga") {
      setDatosEdit((prevState) => ({
        ...prevState,
        [name]: parseInt(value, 10),
      }));
      return;
    }

    setDatosEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleMultiEditChangeDate = (date, name) => {
    setDatosEdit((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const seleccionarProyecto = (proyectos, caso) => {
    let ids = [];
    proyectos.forEach((proyecto) => {
      ids.push(proyecto.id);
    });
    //let ids = proyectos.map((proyecto) => proyecto.id);
    // console.log("IDs de proyectos seleccionados:", ids);

    setProyectoSeleccionado(ids);

    // Si se seleccionó al menos un proyecto
    if (proyectos.length > 0) {
      const datosProyectos = proyectos.map(
        ({ tableData, ...proyecto }) => proyecto
      );
      // console.log("Datos edit antes de setDatosEdit:", datosProyectos);

      setDatosEdit(datosProyectos);

      caso === "Editar"
        ? abrirCerrarModalEditar()
        : caso === "Carga"
        ? abrirCerrarModalCarga()
        : abrirCerrarModalEliminar();
    }
  };

  // const seleccionarProyecto = (proyectos, caso) => {
  //   let ids = [];
  //   proyectos.forEach((proyecto) => {
  //     ids.push(proyecto.id);
  //   });
  //   setProyectoSeleccionado(ids);
  //   // Si se seleccionó solo un proyecto, datosEdit == proyecto
  //   // if (proyectos.length === 1) {
  //   if (proyectos.length > 0) {
  //     // Se elimina primero el atributo tableData
  //     // const { tableData, ...proyecto } = proyectos;
  //     // setDatosEdit(proyecto);
  //     const datosProyectos = proyectos.map(
  //     ({ tableData, ...proyecto }) => proyecto
  //   );
  //   }
  //   caso === "Editar"
  //     ? abrirCerrarModalEditar()
  //     : caso === "Carga"
  //     ? abrirCerrarModalCarga()
  //     : abrirCerrarModalEliminar();
  // };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  const abrirCerrarModalEditar = () => {
    modalEditar &&
      setProyectoSeleccionado((prevState) => ({
        ...prevState.tableData,
        checked: false,
      }));
    setModalEditar(!modalEditar);
  };
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  // Metodos para funciones de backend
  const peticionGet = useCallback(
    async (id) => {
      if (props?.getAll) {
        const prueba = await axios.get(baseURL + `/${id}`);
        // setResistenciaDeProyecto(prueba.data.pruebas[0].resistenciaDeProyecto); // check
        setTableData(prueba.data.pruebas);
        // {console.log("381  " + JSON.stringify(prueba.data.pruebas));}
        // {console.log("381  " + JSON.stringify(prueba));}
      } else {
        const dateFilter = props?.dateFilter || "";
        const prueba = await axios.get(
          baseURL + `/day/${id}/?date=${dateFilter}`
        );
        setTableData(prueba.data.pruebas);
      }
    },
    [axios]
  );

  // Petición get al endpoint de stats de concreto
  const getStatsConcreto = async () => {
    try {
      const response = await axios.get("concreto/stats");
      setStats(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Cuando se monte este componente, realiza una petición get a los stats de concreto
  useEffect(() => {
    getStatsConcreto();
  }, []);

  // {console.log("linea 414 " + JSON.stringify(stats?.pruebasTotales));}

  function calcularNuevoNumeroGeneral() {
    return numeroGeneralGlobal;
  }
  // {console.log("Número general 397:", numeroGeneralGlobal);}

  const peticionPost = async () => {
    abrirCerrarModalInsertar();
    let c = obraSeleccionada.concretos;
    revenimientoDeProyecto = revenimientoDeProyecto || c.revenimientoDeProyecto;
    resistenciaDeProyecto = resistenciaDeProyecto || c.resistenciaDeProyecto;
    // Calcula el nuevo número general global
    const newGeneralNumber = calcularNuevoNumeroGeneral();
    {
      console.log("Número general global actualizado a:", newGeneralNumber + 1);
    }
    // {console.log("www" + JSON.stringify(newGeneralNumber))}
    await axios
      .post(baseURL, {
        numeroGeneral: newGeneralNumber,
        tamanoMaxAgregado,
        nCilindro,
        carga,
        diametro,
        lugarColado,
        revObtenido,
        revenimientoDeProyecto,
        resistenciaDeProyecto,
        fechaMuestreo,
        duracion: duracionArray,
        fechaEnsaye: fechaEnsayeArray,
        esfuerzoMaxKg,
        modeladoDeConcreto,
        esfuerzoMaxMp,
        esfuerzoMaxObtenido,
        idConcreto,
      })
      .then(() => {
        setNumeroGeneralGlobal(newGeneralNumber + 1);
        // {console.log("Número general  432:", newGeneralNumber + 1);}
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Prueba añadida exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        peticionGet(idConcreto);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });

    // {console.log("Número general global actualizado a 445:", newGeneralNumber);}
  };
  //PETICION PUT ANTERIOR
  // const peticionPut = async () => {
  //   await axios
  //     .put(baseURL, { datosEdit, proyectoSeleccionado })
  //     .then((res) => {
  //       modalEditar && abrirCerrarModalEditar();
  //       modalCarga && abrirCerrarModalCarga();
  //       const updatedProyectos = res.data.proyectos;
  //       // Update the tableData state with the updated projects
  //       setTableData((prevTableData) => {
  //         const updatedTableData = prevTableData.map((proyecto) => {
  //           const updatedProyecto = updatedProyectos.find(
  //             (p) => p.id === proyecto.id
  //           );
  //           return updatedProyecto ? updatedProyecto : proyecto;
  //         });
  //         return updatedTableData;
  //       });
  //       peticionGet(idConcreto);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   setDatosEdit();
  // };

  //PETICION PUT NVO
  const peticionPut = async () => {
    // Crear un nuevo array con los valores de carga y diámetro
    // console.log("Datos edit de setDatosEdit: valores; ", valores);
    // console.log("Datos edit de setDatosEdit: valoresDiametro; ", valoresDiametro);
    // console.log("Datos edit de setDatosEdit: valoresEsfuerzoMaxKg; ", valoresEsfuerzoMaxKg);
    // console.log("Datos edit de setDatosEdit: valoresEsfuerzoMaxMp; ", valoresEsfuerzoMaxMp);
    // console.log("Datos edit de setDatosEdit: valoresEsfuerzoMaxObtenido; ", valoresEsfuerzoMaxObtenido);
    // console.log("Datos edit de setDatosEdit pre: ", datosEdit);

    const proyectosActualizados = datosEdit.map((proyecto, index) => ({
      ...proyecto,
      carga: valores[index] || proyecto.carga, // Asignar el valor de carga
      diametro: valoresDiametro[index] || proyecto.diametro, // Asignar el valor de diámetro
      esfuerzoMaxKg: valoresEsfuerzoMaxKg[index] || proyecto.esfuerzoMaxKg,
      esfuerzoMaxMp: valoresEsfuerzoMaxMp[index] || proyecto.esfuerzoMaxMp,
      esfuerzoMaxObtenido: valoresEsfuerzoMaxObtenido[index] || proyecto.esfuerzoMaxObtenido,
      // esfuerzoMaxKg: proyecto.esfuerzoMaxKg || 0,
      // esfuerzoMaxMp: proyecto.esfuerzoMaxMp || 0,
      // esfuerzoMaxObtenido: proyecto.esfuerzoMaxObtenido || 0,
    }));
    // console.log(proyectosActualizados);

    //Actualiza datosEdit con los poryectos proyectosActualizados
    // console.log("Datos edit de setDatosEdit pos: ", proyectosActualizados);
    setDatosEdit(proyectosActualizados);

    // console.log("GUUD sent axios request");
    await axios
      .put(baseURL, { proyectos: proyectosActualizados, proyectoSeleccionado })
      .then((res) => {
        modalEditar && abrirCerrarModalEditar();
        modalCarga && abrirCerrarModalCarga();
        const updatedProyectos = res.data.proyectos;
        // console.log("updateProyectos: ", res.data.proyectos);
        // console.log("updateProyecto: ", updatedProyectos);
        //setTableData(res.data.proyectos);
        setTableData((prevTableData) => {
          const updatedTableData = prevTableData.map((proyecto) => {
            const updatedProyecto = updatedProyectos.find(
              (p) => p.id === proyecto.id
            );
            return updatedProyecto ? updatedProyecto : proyecto;
          });
          return updatedTableData;
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Pruebas actualizadas exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        peticionGet(idConcreto);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });

    // .catch((error) => {
    //   console.log(error);
    // });
    //setDatosEdit();
  };

  const peticionDelete = async () => {
    await axios
      .delete(baseURL + `/${proyectoSeleccionado}`)
      .then(() => {
        abrirCerrarModalEliminar();
        peticionGet(idConcreto);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const abrirCerrarModalCarga = () => {
    modalCarga &&
      setProyectoSeleccionado((prevState) => ({
        ...prevState.tableData,
        checked: false,
      }));
    setModalCarga(!modalCarga);
  };

  const getUltimoNumeroGeneral = async () => {
    const prueba = await axios.get(baseURL + `/last`).catch((error) => {
      console.log(error);
    });

    if (prueba.data.message == "No hay ninguna prueba de concreto registrada") {
      return 0;
    }
    return prueba.data.prueba[0].numeroGeneral;
  };

  const handleIdConcreto = async () => {
    const rowData = props.data.rowData;
    setObraSeleccionada(rowData);
    const id = props.data.rowData.concretos.id;
    peticionGet(id);
    setIdConcreto(id);
  };

  const getDiametros = async (datosEdit) => {
    let ids = []
    for (const prueba of datosEdit) {
      ids.push(prueba.id);
    }
    // console.log(datosEdit);
    // console.log(ids);

    try {
      const response = await axios.get(baseURL + `/diametros/${ids}`);
      const fetchedDiametros = response.data.pruebas.map(item => item.modeladoDeConcreto.substring(0, 2));
      console.log(fetchedDiametros);
      setValoresDiametro(fetchedDiametros);
    } catch (error) {
      console.error('Error obtienendo diametros:', error);
      // Handle errors or set default values as needed
      setValoresDiametro(Array(datosEdit.length).fill(""));
    }
  };

  // Paso 0: Obtener ultimo id de pruebas de concreto creado
  useEffect(() => {    
    handleIdConcreto();

    if (!tableData) {
      getUltimoNumeroGeneral()
        .then((result) => {
          setNumG(result);
        })
        .catch((error) => {
          console.error("There was an error:", error);
        });
    } else {
      setNumG(tableData[tableData.length - 1].numeroGeneral);
      // {console.log("linea 515 " + numG);}
      // {console.log("linea 516 " + JSON.stringify(tableData));}
    }
  }, [props.data]);

  // Paso 1: Agregar pruebas de concreto
  useEffect(() => {
    // Clean arrays for inputs
    setDuracionArray((state) => []);
    setFechaEnsayeArray((state) => []);
    // Set input arrays to some template data
    for (let i = 0; i < nCilindro; i++) {
      setDuracionArray((state) => [...state, 0]);
      setFechaEnsayeArray((state) => [...state, fechaMuestreo]);
    }
    // Do this whenever nCilindro changes
  }, [nCilindro, fechaMuestreo]);

  // Paso 2: Cargar diametros para modificar cargas
  useEffect(() => {
    // console.log(datosEdit);
    if (modalCarga) {
      getDiametros(datosEdit);
    }
  }, [modalCarga, datosEdit])

  // Declaracion de variables
  const isValidDate = (date) => {
    return !isNaN(date.getTime());
  };
  const updateDays = (days, date, index) => {
    let result = new Date(date);
    result.setDate(result.getDate() + parseInt(days, 10));

    if (index !== null || index !== undefined) {
      let fechasDeEnsaye = [...fechaEnsayeArray];
      fechasDeEnsaye[index] = isValidDate(result) ? result.toISOString() : null;
      setFechaEnsayeArray((state) => [...fechasDeEnsaye]);
    } else {
      setFechaEnsaye(isValidDate(result) ? result.toISOString() : null);
    }
  };

  const updateEsfuerzoMaxKg = (carga, diametro, resistenciaTemp) => {
    let floatCarga = parseFloat(carga, 10);
    let floatDiametro = parseFloat(diametro, 10);
    let radio = floatDiametro / 2;
    let area = Math.PI * Math.pow(radio, 2);
    let result = floatCarga / area;
    let r = result.toFixed(4);
    if (isFinite(r)) {
      const result = Math.round(parseFloat(r, 10));
      // Actualiza el esfuerzo y los datos edit
      setEsfuerzoMaxKg(result);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxKg: result,
      }));

      const resistenciaDePrueba =
        resistenciaTemp != "" && resistenciaTemp != 0
          ? resistenciaTemp
          : resistenciaDeProyecto;
      updateEsfuerzoMaxMP(r);
      updateEsfuerzoMaxObtenido(r, resistenciaDePrueba);
    } else {
      setEsfuerzoMaxKg(0);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxKg: 0,
      }));
    }
  };
  const updateEsfuerzoMaxMP = (esfuerzomaxkg) => {
    let constante = 0.0981;
    let floatEsfuerzomaxkg = parseFloat(esfuerzomaxkg, 10);
    let result = floatEsfuerzomaxkg * constante;
    let r = result.toFixed(2);
    setEsfuerzoMaxMp(r);
    setDatosEdit((prevState) => ({
      ...prevState,
      esfuerzoMaxMp: parseFloat(r, 10),
    }));
  };
  const updateEsfuerzoMaxObtenido = (esfuerzomaxkg, resistenciaDePrueba) => {
    let floatEsfuerzoMaxKg = parseFloat(esfuerzomaxkg, 10);
    // let floatResistenciaDePrueba = parseFloat(resistenciaDePrueba, 10);
    let floatResistenciaDeProyecto = parseFloat(
      obraSeleccionada.concretos.resistenciaDeProyecto,
      10
    );
    // console.log('floatResistenciaDeProyecto', floatResistenciaDeProyecto)
    let resistenciaTemp =
      (resistenciaDePrueba != "") & (resistenciaDePrueba != 0)
        ? resistenciaDePrueba
        : resistenciaDeProyecto;
    //floatResistenciaDeProyecto
    // console.log("resistenciaTemp", resistenciaTemp);
    let result = floatEsfuerzoMaxKg / resistenciaTemp;
    result = (result * 100).toFixed(0);
    // console.log("GUUD 3 ", floatEsfuerzoMaxKg, floatResistenciaDeProyecto, resistenciaDeProyecto, result);
    if (isFinite(result)) {
      const r = Math.round(result);
      setEsfuerzoMaxObtenido(result);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxObtenido: r,
      }));
    } else {
      setEsfuerzoMaxObtenido(0);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxObtenido: 0,
      }));
    }
  };

  // validacion TamanoMax
  const handleTamanoMaxAgregadoChange = (e) => {
    const value = e.target.value;
    setTamanoMaxAgregado(value);

    if (!value) {
      setTamanoMaxAgregadoError(true);
    } else {
      setTamanoMaxAgregadoError(false);
    }
  };

  const handleNextClick = () => {
    if (!tamanoMaxAgregado) {
      setTamanoMaxAgregadoError(true);
      setModalInsertarScreen(0);
    } else {
      setTamanoMaxAgregadoError(false);
      setModalInsertarScreen(1);
    }
  };
  // 1
  // BODY Modal crear prueba
  const bodyInsertar = (
    <form>
      <div className={classes.modal}>
        <h3>Agregar una prueba</h3>
        <br />
        <TextField
          className={classes.inputMaterial}
          label="Cantidad de cilindros"
          type="number"
          autoFocus={true}
          InputProps={{ inputProps: { min: 0 } }}
          name="nCilindro"
          onChange={(e) => setNCilindro(e.target.value)}
        />
        <br />
        <TextField
          className={classes.inputMaterial}
          label="Revenimiento obtenido"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          name="revObtenido"
          step="0.1"
          onChange={(e) => setRevObtenido(e.target.value)}
        />
        <br />
        <TextField
          className={classes.inputMaterial}
          label="Resistencia"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          name="resistenciaDeProyecto"
          step="0.1"
          onChange={(e) => {
            setResistenciaDeProyecto(e.target.value);
            //console.log("GUUD 1 ", resistenciaDeProyecto, e.target.value);
            updateEsfuerzoMaxObtenido(esfuerzoMaxKg, e.target.value);
          }}
        />
        <br />
        <TextField
          className={classes.inputMaterial}
          label="Revenimiento"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          name="revenimientoDeProyecto"
          step="0.1"
          onChange={(e) => setRevenimientoDeProyecto(e.target.value)}
        />
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Fecha de Muestreo"
            name="fechaMuestreo"
            disableMaskedInput={false}
            value={fechaMuestreo}
            onChange={(date) => {
              setFechaMuestreo(date);
              updateDays(duracion, date);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <br />
        <FormControl className={classes.inputMaterial}>
          <TextField
            label="Tamaño Max Agregado"
            name="tamanoMaxAgregado"
            type="number"
            step="0.1"
            InputProps={{ inputProps: { min: 0 } }}
            value={tamanoMaxAgregado}
            onChange={handleTamanoMaxAgregadoChange}
            required
            error={tamanoMaxAgregadoError}
            helperText={tamanoMaxAgregadoError ? "Este campo es requerido" : ""}
          />
        </FormControl>
        <br />
        <TextField
          className={classes.inputMaterial}
          label="Lugar de Colado"
          name="lugarColado"
          onChange={(e) => setLugarColado(e.target.value)}
        />
        <br />
        <FormControl fullWidth>
          <InputLabel id="modeladoDeConcreto">Modelado de Concreto</InputLabel>
          <Select
            labelId="modeladoDeConcreto"
            id="modelado"
            value={modeladoDeConcreto}
            label="Modelado de Concreto"
            onChange={(e) => setModeladoDeConcreto(e.target.value)}
          >
            <MenuItem value={"15x30"}>15x30</MenuItem>
            <MenuItem value={"10x20"}>10x20</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br />
        <div align="right">
          <Button
            color="secondary"
            onClick={() => {
              abrirCerrarModalInsertar();
              setModalInsertarScreen(0);
            }}
          >
            Cancelar
          </Button>

          {/* <Button
          type="submit"
          color="secondary"
          onClick={() => setModalInsertarScreen(1)}
        >
          Siguiente
        </Button> */}
          <Button color="secondary" onClick={handleNextClick}>
            Siguiente
          </Button>
        </div>
      </div>
    </form>
  );
  const bodyInsertarNext = () => {
    // var numG = 0;
    // numG = tableData[tableData.length-1].numeroGeneral;
    // {console.log("numG:", numG);}
    let cilinderNumbers = [];
    for (let i = 1; i <= nCilindro; i++) {
      cilinderNumbers.push(i);
    }
    // {console.log("1111" + JSON.stringify((tableData[0].numeroGeneral += 1)));}
    //  {console.log("aaaaaaaa" + JSON.stringify((tableData[tableData.length-1].numeroGeneral)))}
    //  {console.log("eee" + JSON.stringify(tableData))}
    // numgral = {stats?.pruebasTotales}
    // numG = numgral + 1; // General
    var numP = 0; // Particular
    if (tableData) {
      // Considerar si tableData esta vacio
      // numG = tableData[tableData.length-1].numeroGeneral;
      numP = tableData[tableData.length - 1].nCilindro;
    }
    return (
      <div className={classes.modal}>
        <h3>Agregar pruebas</h3>
        {/* {console.log(JSON.stringify(tableData[0].numeroGeneral))} */}
        <br />
        {cilinderNumbers.length ? (
          cilinderNumbers.map((num, i) => (
            <div key={num}>
              <br />
              {/* <h4>
                #{numG + num} Cilindro #{numP + num}
              </h4> */}
              <h4>
                #{stats?.pruebasTotales + num} Cilindro #{numP + num}
              </h4>
              <TextField
                className={classes.inputMaterial}
                label="Edad de Prueba (días)"
                name="duracion"
                value={duracionArray[i]}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                step="1"
                onChange={(e) => {
                  let duraciones = [...duracionArray];
                  duraciones[i] = e.target.value;
                  setDuracionArray((state) => [...duraciones]);
                  updateDays(e.target.value, fechaMuestreo, i);
                }}
              />
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha de Ensaye"
                  name="fechaEnsaye"
                  disabled={true}
                  disableMaskedInput={false}
                  value={fechaEnsayeArray[i]}
                  onChange={(date) => {
                    let fechasDeEnsaye = [...fechaEnsayeArray];
                    fechasDeEnsaye[i] = date;
                    setFechaEnsayeArray((state) => [...fechasDeEnsaye]);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <br />
            </div>
          ))
        ) : (
          <h5>Ingrese número de cilindros!</h5>
        )}
        <div align="right">
          <Button
            color="secondary"
            onClick={() => {
              // console.log("Duraciones: ", duracionArray);
              // console.log("Fechas de ensaye: ", fechaEnsayeArray);
              abrirCerrarModalInsertar();
              setModalInsertarScreen(0);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              peticionPost();
              setModalInsertarScreen(0);
            }}
          >
            Agregar
          </Button>
        </div>
      </div>
    );
  };

  // BODY Modal editar prueba
  const bodyEditar = (
    <div className={classes.modal}>
      <h3>Editar prueba</h3>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Carga"
        autoFocus={true}
        type="number"
        name="carga"
        value={datosEdit?.carga || ""}
        // onChange={handleMultiEditChange}
        onChange={(e) => {
          handleMultiEditChange(e);
          // console.log("carga: ", datosEdit?.carga, " e.value: ", e.target.value, " resistenciaDeProyecto", datosEdit?.resistenciaDeProyecto);
          updateEsfuerzoMaxKg(
            e.target.value,
            datosEdit?.diametro || "",
            datosEdit?.resistenciaDeProyecto || ""
          );
        }}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Diámetro"
        type="number"
        name="diametro"
        value={datosEdit?.diametro || ""}
        onChange={(e) => {
          handleMultiEditChange(e);
          updateEsfuerzoMaxKg(
            datosEdit?.carga || "",
            e.target.value,
            datosEdit?.resistenciaDeProyecto || ""
          );
        }}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Revenimiento obtenido"
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        value={datosEdit?.revObtenido || ""}
        name="revObtenido"
        step="0.1"
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Resistencia"
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        value={datosEdit?.resistenciaDeProyecto || ""}
        name="resistenciaDeProyecto"
        step="0.1"
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Revenimiento"
        value={datosEdit?.revenimientoDeProyecto || ""}
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        name="revenimientoDeProyecto"
        step="0.1"
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha de Muestreo"
          name="fechaMuestreo"
          disableMaskedInput={false}
          value={datosEdit?.fechaMuestreo || null}
          onChange={(date) => {
            handleMultiEditChangeDate(date);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Edad de Prueba (días)"
        name="duracion"
        value={datosEdit?.duracion || ""}
        type="number"
        onChange={handleMultiEditChange}
      />
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha de Ensaye"
          name="fechaEnsaye"
          disableMaskedInput={false}
          value={datosEdit?.fechaEnsaye || null}
          onChange={(date) => {
            handleMultiEditChangeDate(date);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Tamaño Max Agregado"
        name="tamanoMaxAgregado"
        type="number"
        value={datosEdit?.tamanoMaxAgregado || ""}
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Lugar de Colado"
        value={datosEdit?.lugarColado || ""}
        name="lugarColado"
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Esfuerzo Máximo Obtenido (kg/cm2)"
        disabled={true}
        value={datosEdit?.esfuerzoMaxKg || ""}
        name="esfuerzoMaxKg"
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Esfuerzo Máximo Obtenido (mpa)"
        disabled={true}
        value={datosEdit?.esfuerzoMaxMp || ""}
        name="esfuerzoMaxMp"
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Esfuerzo Máximo Obtenido"
        disabled={true}
        value={datosEdit?.esfuerzoMaxObtenido || ""}
        name="esfuerzoMaxObtenido"
        onChange={handleMultiEditChange}
      />
      <br />
      <FormControl fullWidth>
        <InputLabel id="modeladoDeConcreto">Modelado de Concreto</InputLabel>
        <Select
          labelId="modeladoDeConcreto"
          id="modelado"
          value={datosEdit?.modeladoDeConcreto || ""}
          label="Modelado de Concreto"
          name="modeladoDeConcreto"
          onChange={handleMultiEditChange}
        >
          <MenuItem value={"15x30"}>15x30</MenuItem>
          <MenuItem value={"10x20"}>10x20</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalEditar()}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={() => peticionPut()}>
          Actualizar
        </Button>
      </div>
    </div>
  );

  // BODY Modal eliminar prueba
  const bodyEliminar = (
    <div className={classes.modalEliminar}>
      <p>
        Estás seguro que deseas eliminar al proyecto{" "}
        <b>{proyectoSeleccionado && proyectoSeleccionado.nombreObra}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  );

  // BODY Modal agregar Carga
  const [valores, setValores] = useState(Array(datosEdit.length).fill(""));
  const [valoresDiametro, setValoresDiametro] = useState([]);
  // const [valoresDiametro, setValoresDiametro] = useState(
  //   Array(datosEdit.length).fill("")
  // );
  // const [valoresDiametro, setValoresDiametro] = useState(
  //   Array(datosEdit.length).fill(datosEdit.map(item => item.diametro || ""))
  // );
  // console.log(datosEdit);
  // console.log(valoresDiametro);
  const [valoresEsfuerzoMaxKg, setValoresEsfuerzoMaxKg] = useState(
    Array(datosEdit.length).fill("")
  );
  const [valoresEsfuerzoMaxMp, setValoresEsfuerzoMaxMp] = useState(
    Array(datosEdit.length).fill("")
  );
  const [valoresEsfuerzoMaxObtenido, setValoresEsfuerzoMaxObtenido] = useState(
    Array(datosEdit.length).fill("")
  );
    // console.log(datosEdit);
  //NUEVAS FUNCIONES PARA ACTUALIZAR //

  const getResistenciaDePrueba = useCallback(
    async (id) => {
      const prueba = await axios.get(baseURL + `/${id}`);
      // console.log(prueba);
      // console.log(prueba.data.pruebas2[0].resistenciaDeProyecto);
      const resistenciaDePrueba = prueba.data.pruebas2[0].resistenciaDeProyecto; // refactor => resistenciaDePrueba
      return resistenciaDePrueba;
    }, [axios]
  );

  const calcularEsfuerzoMaxKgn = (carga, diametro, index) => {
    let floatCarga = parseFloat(carga, 10);
    // let floatDiametro = parseFloat(diametro, 10);
    // let radio = floatDiametro / 2;
    // console.log(diametro);
    // console.log(floatDiametro);
    // let area = Math.PI * Math.pow(radio, 2);
    let area = (Math.PI * Math.pow(diametro, 2)) / 4;
    // let area = Math.PI * 2 * radio;
    // let area = Math.PI * diametro;
    // console.log(floatCarga);
    // console.log(area);
    const esfuerzoMaxKg = floatCarga / area;
    // console.log(esfuerzoMaxKg);
    //let r = result.toFixed(4);
    if (isFinite(esfuerzoMaxKg)) {
      // console.log("GUUD 2 ");
      const result = Math.round(parseFloat(esfuerzoMaxKg, 10));
      // Actualiza el esfuerzo y los datos edit
      setEsfuerzoMaxKg(result);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxKg: result,
      }));

      updateEsfuerzoMaxMPn(esfuerzoMaxKg, index);
      updateEsfuerzoMaxObtenidon(esfuerzoMaxKg, index);
    } else {
      setEsfuerzoMaxKg(0);
      setDatosEdit((prevState) => ({
        ...prevState,
        esfuerzoMaxKg: 0,
      }));
    }

    return esfuerzoMaxKg;
  };

  const updateEsfuerzoMaxMPn = (esfuerzoMaxKg, index) => {
    let constante = 0.0981;
    let floatEsfuerzoMaxKg = parseFloat(esfuerzoMaxKg, 10);
    let result = floatEsfuerzoMaxKg * constante;
    let r = result.toFixed(2);
    setEsfuerzoMaxMp(r);

    const newEsfuerzoMaxMp = [...valoresEsfuerzoMaxMp];
    newEsfuerzoMaxMp[index] = r;
    setValoresEsfuerzoMaxMp(newEsfuerzoMaxMp);

    setDatosEdit((prevState) => ({
      ...prevState,
      esfuerzoMaxMp: parseFloat(r, 10),
    }));
  };

  const updateEsfuerzoMaxObtenidon = (esfuerzoMaxKg, index) => {
    let floatEsfuerzoMaxKg = parseFloat(esfuerzoMaxKg, 10);
    let floatResistenciaDeProyecto = parseFloat(
      obraSeleccionada.concretos.resistenciaDeProyecto,
      10
    );

    // (async () => {
    //   try {
    //     const resistenciaDePrueba = await getResistenciaDePrueba(proyectoSeleccionado[index]);

    //     let resistenciaTemp = (resistenciaDePrueba == 0 || resistenciaDePrueba == undefined || resistenciaDePrueba == '') ? floatResistenciaDeProyecto : resistenciaDePrueba;

    //     let result = floatEsfuerzoMaxKg / resistenciaTemp;
    //     result = (result * 100).toFixed(0);  
  
    //     const r = Math.round(result);
    //     setEsfuerzoMaxObtenido(r);
  
    //     const newEsfuerzoMaxObtenido = [...valoresEsfuerzoMaxObtenido];
    //     newEsfuerzoMaxObtenido[index] = r;
    //     setValoresEsfuerzoMaxObtenido(newEsfuerzoMaxObtenido);
  
    //     setDatosEdit((prevState) => ({
    //       ...prevState,
    //       esfuerzoMaxObtenido: r,
    //     }));
    //   } catch (error) {
    //     console.error("Error fetching resistenciaDePrueba:", error);
    //     // Handle the error appropriately
    //     setEsfuerzoMaxObtenido(0);
    
    //     const newEsfuerzoMaxObtenido = [...valoresEsfuerzoMaxObtenido];
    //     newEsfuerzoMaxObtenido[index] = 0;
    //     setValoresEsfuerzoMaxObtenido(newEsfuerzoMaxObtenido);
  
    //     setDatosEdit((prevState) => ({
    //       ...prevState,
    //       esfuerzoMaxObtenido: 0,
    //     }));
    //   }
    // })();
    getResistenciaDePrueba(proyectoSeleccionado[index])
    .then(resistenciaDePrueba => {
      let resistenciaTemp = (resistenciaDePrueba === 0 || resistenciaDePrueba === undefined || resistenciaDePrueba === '') ? floatResistenciaDeProyecto : resistenciaDePrueba;

      let result = floatEsfuerzoMaxKg / resistenciaTemp;
      result = (result * 100).toFixed(0);  
      const r = Math.round(result);      
      setEsfuerzoMaxObtenido(r);

      const newEsfuerzoMaxObtenido = [...valoresEsfuerzoMaxObtenido];
      newEsfuerzoMaxObtenido[index] = r;
      setValoresEsfuerzoMaxObtenido(newEsfuerzoMaxObtenido);

      // setDatosEdit((prevState) => ({ // aqui esta el error
      //   ...prevState,
      //   esfuerzoMaxObtenido: r,
      // }));
    })
    .catch(error => {
      console.error("Error fetching resistenciaDePrueba:", error);
      // Handle the error appropriately
      setEsfuerzoMaxObtenido(0);
  
      const newEsfuerzoMaxObtenido = [...valoresEsfuerzoMaxObtenido];
      newEsfuerzoMaxObtenido[index] = 0;
      setValoresEsfuerzoMaxObtenido(newEsfuerzoMaxObtenido);

      // setDatosEdit((prevState) => ({
      //   ...prevState,
      //   esfuerzoMaxObtenido: 0,
      // }));
    });
  };

  const handleChangeCarga = (e, index) => {
    const newValues = [...valores];
    newValues[index] = e.target.value;
    setValores(newValues);

    if (valoresDiametro[index]) {
      const esfuerzoMaxKg = calcularEsfuerzoMaxKgn(
        parseFloat(e.target.value),
        parseFloat(valoresDiametro[index]),
        index
      );

      const newEsfuerzoMaxKg = [...valoresEsfuerzoMaxKg];
      newEsfuerzoMaxKg[index] = esfuerzoMaxKg.toFixed(2);
      setValoresEsfuerzoMaxKg(newEsfuerzoMaxKg);

      const proyectosActualizados = [...datosEdit];
      proyectosActualizados[index].esfuerzoMaxKg = esfuerzoMaxKg.toFixed(2); // Redondeamos a 2 decimales
      setDatosEdit(proyectosActualizados);
    }
  };

  const handleChangeDiametro = (e, index) => {
    const diametro = e.target.value;
    console.log(diametro);
    if (diametro === 10 || diametro === 15) {
      const newValues = [...valoresDiametro];
      newValues[index] = e.target.value;
      setValoresDiametro(newValues);

      if (valores[index]) {
        const esfuerzoMaxKg = calcularEsfuerzoMaxKgn(
          parseFloat(valores[index]),
          parseFloat(diametro),
          index
        );

        const newEsfuerzoMaxKg = [...valoresEsfuerzoMaxKg];
        newEsfuerzoMaxKg[index] = esfuerzoMaxKg.toFixed(2);
        setValoresEsfuerzoMaxKg(newEsfuerzoMaxKg);

        const proyectosActualizados = [...datosEdit];
        proyectosActualizados[index].esfuerzoMaxKg = esfuerzoMaxKg.toFixed(2); // Redondeamos a 2 decimales
        setDatosEdit(proyectosActualizados);
      }
    }
  };

  //modal NUEVO YA NO se cIERRA al querer escribir
  const agregarCarga = (
    <div className={classes.modalCarga}>
      <div className={classes.scrollContainer}>
        {Array.isArray(datosEdit) &&
          datosEdit.map((proyecto, index) => (
            <div key={index}>
              <h3>
                Agregar Carga y Diámetro para la prueba #{proyecto.numeroGeneral}
              </h3>
              <br />
              <TextField
                className={classes.inputMaterial}
                label="Carga"
                type="number"
                name={`carga-${index}`}
                value={valores[index]}
                onChange={(e) => handleChangeCarga(e, index)}
              />

              {/* <TextField
                className={classes.inputMaterial}
                label="Carga"
                type="number"
                name={`carga-${index}`} 
                value={valores[index]} 
                onChange={(e) => {
                const newValues = [...valores]; 
                newValues[index] = e.target.value; 
                setValores(newValues);
                              
              }}
              /> */}
              <br />
              <br />
              <TextField
                className={classes.inputMaterial}
                label="Diámetro"
                type="number"
                name={`diametro-${index}`}
                value={valoresDiametro[index]}
                error={valoresDiametro[index] != 10 || valoresDiametro[index] != 15}
                helperText={valoresDiametro[index] != 10 && valoresDiametro[index] != 15 ? "El diámetro debe ser 10 o 15 segun el Modelado de Concreto del primer paso de registro de pruebas de concreto." : ""}
                onChange={(e) => handleChangeDiametro(e, index)}
                InputLabelProps={{
                  shrink: valoresDiametro[index], // Ensures the label shrinks when the field is not empty or focused
                }}
              />

              {/* <TextField
                className={classes.inputMaterial}
                label="Diámetro"
                type="number"
                name={`diametro-${index}`}
                value={valoresDiametro[index]}
                onChange={(e) => {
                  const newValues = [...valoresDiametro];
                  newValues[index] = e.target.value;
                  setValoresDiametro(newValues);
                }}
              /> */}
              <br />
              <br />
              <TextField
                className={classes.inputMaterial}
                label="Esfuerzo Máximo Obtenido (kg/cm2)"
                name="esfuerzoMaxKg"
                disabled={true}
                type="number"
                step="0.1"
                InputProps={{ inputProps: { min: 0 } }}
                value={valoresEsfuerzoMaxKg[index] || ""}
              />
              <br />
              <TextField
                className={classes.inputMaterial}
                label="Esfuerzo Máximo Obtenido (mpa)"
                name="esfuerzoMaxMp"
                type="number"
                disabled={true}
                value={valoresEsfuerzoMaxMp[index] || ""}
              />
              <br />
              <TextField
                className={classes.inputMaterial}
                label="Esfuerzo Máximo Obtenido"
                disabled={true}
                name="esfuerzoMaxObtenido"
                value={valoresEsfuerzoMaxObtenido[index] || ""}
              />
              <br />
              {index !== datosEdit.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          ))}
      </div>
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalCarga()}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={() => peticionPut()}>
          Agregar
        </Button>
      </div>
    </div>
  );

  //modal anterior se cerraba al querer escribir
  // const agregarCarga = (
  //   <div className={classes.modalCarga}>
  //     <h3>Agregar Carga y Diámetro</h3>
  //     <br />
  //     <TextField
  //       className={classes.inputMaterial}
  //       label="Carga"
  //       type="number"
  //       name="carga"
  //       value={datosEdit?.carga || ""}
  //       onChange={(e) => {
  //         handleMultiEditChange(e);
  //         // console.log("g", e.target.value, datosEdit?.carga || "", datosEdit?.resistenciaDeProyecto);
  //         updateEsfuerzoMaxKg(
  //           e.target.value,
  //           datosEdit?.diametro || "",
  //           datosEdit?.resistenciaDeProyecto || ""
  //         );
  //       }}
  //     />
  //     <br />
  //     <br />
  //     <TextField
  //       className={classes.inputMaterial}
  //       label="Diámetro"
  //       type="number"
  //       name="diametro"
  //       value={datosEdit?.diametro || ""}
  //       onChange={(e) => {
  //         handleMultiEditChange(e);
  //         // console.log("g1", proyectoSeleccionado, datosEdit, e.target.value, datosEdit?.diametro || "", datosEdit?.resistenciaDeProyecto);
  //         updateEsfuerzoMaxKg(
  //           datosEdit?.carga || "",
  //           e.target.value,
  //           datosEdit?.resistenciaDeProyecto || ""
  //         );
  //       }}
  //     />

  //     <br />
  //     <br />
  //     <TextField
  //       className={classes.inputMaterial}
  //       label="Esfuerzo Máximo Obtenido (kg/cm2)"
  //       name="esfuerzoMaxKg"
  //       disabled={true}
  //       type="number"
  //       step="0.1"
  //       InputProps={{ inputProps: { min: 0 } }}
  //       value={datosEdit?.esfuerzoMaxKg || ""}
  //     />
  //     <br />
  //     <TextField
  //       className={classes.inputMaterial}
  //       label="Esfuerzo Máximo Obtenido (mpa)"
  //       name="esfuerzoMaxMp"
  //       type="number"
  //       disabled={true}
  //       value={datosEdit?.esfuerzoMaxMp || ""}
  //     />
  //     <br />
  //     <TextField
  //       className={classes.inputMaterial}
  //       label="Esfuerzo Máximo Obtenido"
  //       disabled={true}
  //       name="esfuerzoMaxObtenido"
  //       value={datosEdit?.esfuerzoMaxObtenido || ""}
  //     />
  //     <div align="right">
  //       <Button color="secondary" onClick={() => abrirCerrarModalCarga()}>
  //         Cancelar
  //       </Button>
  //       <Button
  //         color="secondary"
  //         onClick={() => {
  //           peticionPut();
  //         }}
  //       >
  //         Agregar
  //       </Button>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className={classes.root}>
        {/* Descarga de PDF */}
        {props.verPDF && (
          <>
            <PDFDownloadLink
              document={
                <DocuPDF
                  pruebas={proyectoSeleccionado}
                  data={obraSeleccionada}
                />
              }
              fileName={`${obraSeleccionada.nombreObra}_${obraSeleccionada.ubicacion}.pdf`}
            >
              <Button
                id={"downloadpdf"}
                color="secondary"
                variant="contained"
                style={{ display: "none" }}
              >
                Descargar PDF
              </Button>
            </PDFDownloadLink>
          </>
        )}
        {/* Tabla con pruebas de concreto */}
        <MaterialTable
          columns={columnas}
          data={tableData}
          onSelectionChange={(selected, rowData) => {
            setProyectoSeleccionado(selected);
            rowData && setLastResistencia(rowData.resistenciaDeProyecto);
            rowData && setLastRevenimiento(rowData.revenimientoDeProyecto);
          }}
          actions={[
            {
              icon: AddCircleOutlineIcon,
              // isFreeAction: true,
              disabled: disabledButton,
              tooltip: "Agregar Carga",
              // onClick: () => abrirCerrarModalCarga(),
              onClick: (event, rowData) => {
                seleccionarProyecto(rowData, "Carga");
                // if (rowData.length > 1) {
                //   Swal.fire({
                //     position: "top-end",
                //     icon: "error",
                //     title:
                //       "Favor de agregar la carga y diametro una prueba a la vez.",
                //     showConfirmButton: false,
                //     timer: 1500,
                //     timerProgressBar: true,
                //   });
                // } else {
                //   seleccionarProyecto(rowData, "Carga");
                // }
              },
            },
            {
              icon: PrintIcon,
              tooltip: "Imprimir Obra",
              disabled: disabledButton,
              onClick: async (event, rowData) => {
                // FIXME: Delete non-serializable value tableData that contains a function call
                // delete obraSeleccionada?.tableData;

                let inputOptionsFirma = {};
                firmaOptions?.forEach((option) => {
                  inputOptionsFirma = {
                    ...inputOptionsFirma,
                    [option.title]: option.title,
                  };
                });
                const { value: firma, isConfirmed } = await Swal.fire({
                  title: "¿Deseas imprimir las pruebas?",
                  text: "Seleccione signatario",
                  icon: "question",
                  reverseButtons: true,
                  showCancelButton: true,
                  cancelButtonText: "Cancelar",
                  cancelButtonColor: "#d3d3d3",
                  confirmButtonText: "Imprimir",
                  confirmButtonColor: "#3085d6",
                  input: "select",
                  inputPlaceholder: "Signatario",
                  inputOptions: inputOptionsFirma,
                  inputValue: "ING. GASTON FLORES",
                  inputValidator: (value) => {
                    return new Promise((resolve) => {
                      if (!value) {
                        resolve("Tienes que seleccionar una firma");
                      } else {
                        resolve();
                      }
                    });
                  },
                });
                if (isConfirmed && firma) {
                  // console.log("setData: ", obraSeleccionada);
                  // console.log("setPruebas: ", rowData);
                  // FIXME: This doesn't work
                  dispatch(setFirmaConcreto(firma));
                  dispatch(setData(obraSeleccionada));
                  dispatch(setPruebas(rowData));
                  dispatch(setCase("concreto"));
                  navigate("/vistaprevia", { replace: true });
                }
              },
            },

            {
              icon: DownloadIcon,
              disabled: disabledButton,
              tooltip: "Descargar pruebas en PDF",

              onClick: (event, rowData) => {
                setProyectoSeleccionado(rowData);
                props.setVerPDF(true);
                setTimeout(() => {
                  document.getElementById("downloadpdf").click();
                }, 600);
              },
            },
            {
              icon: EditIcon,
              tooltip: "Editar Prueba",
              onClick: (event, rowData) => {
                if (rowData.length > 1) {
                  Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Favor de editar una prueba a la vez.",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                  });
                } else {
                  seleccionarProyecto(rowData, "Editar");
                }
              },
            },
            {
              icon: DeleteIcon,
              tooltip: "Eliminar prueba",
              onClick: (event, rowData) => {
                seleccionarProyecto(rowData, "Eliminar");
              },
            },
            {
              icon: InfoIcon,
              isFreeAction: true,
              tooltip: hideColumns
                ? "Mostrar todas las columnas de la tabla"
                : "Ocultar las columnas nuevamente",
              onClick: () => {
                setHideColumns(!hideColumns);
              },
            },
            {
              icon: AddIcon,
              isFreeAction: true,
              tooltip: "Agregar Prueba",
              onClick: () => abrirCerrarModalInsertar(),
            },
          ]}
          options={{
            exportButton: true,
            exportAllData: true,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            selection: true,
            showTextRowsSelected: true,
            showSelectAllCheckbox: true,
            showDetailPanelIcon: true,
            columnsButton: true,
            tableLayout: "auto",
            paging: true,
            search: true,
            sorting: true,
            showTitle: false,
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar...",
              showColumnsAriaLabel: "Columnas",
              showColumnsTitle: "Columnas",
            },

            pagination: {
              nextTooltip: "Siguiente",
              previousTooltip: "Anterior",
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Renglones por página:",
              labelRowsSelect: "renglones",
            },
            body: {
              addTooltip: "Agregar",
              editTooltip: "Editar",
              deleteTooltip: "Borrar",
              editRow: {
                deleteText: "Borrar este renglón?",
              },
              emptyDataSourceMessage: (
                <>
                  <p> No hay pruebas registradas en esta obra</p>
                  <Button
                    color="primary"
                    onClick={() => abrirCerrarModalInsertar()}
                  >
                    Agregar
                  </Button>
                </>
              ),
            },
          }}
          onRowClick={(event, rowData) => {
            const rowDataCopy = { ...rowData };
            rowDataCopy.tableData.checked = false;
            const dataCopy = [...tableData];
            dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
            setTableData(dataCopy);
          }}
        />
        {/* {console.log("tableData") + JSON.stringify(tableData)} */}
        {/* {console.log("numeroGeneral") + JSON.stringify(tableData.numeroGeneral)} */}
        {/* Modales */}
        <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
          {modalInsertarScreen === 0 ? bodyInsertar : bodyInsertarNext()}
        </Modal>
        <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>
          {bodyEditar}
        </Modal>
        <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>
          {bodyEliminar}
        </Modal>

        <Modal open={modalCarga} onClose={abrirCerrarModalCarga}>
          {agregarCarga}
        </Modal>
      </div>
    </>
  );
};

export default InnerDataTableConcreto;
