import React, { useState, useEffect /*useState*/ } from "react";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import DataTable from "../Terraceria/DataTable";
import { makeStyles } from "@material-ui/core/styles";
import DataHistorial from "./DataHistorial";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "scroll",
    justifyContent: "center",
    // height: "82vh",
    // backgroundColor: "#000",
    // margin: "50px 30px 0px 30px",
    paddingBottom: "3rem",
    // "& > *:nth-child(1)": {
    //   flex: "0 1 25%",
    // },
    // "& > *:nth-child(2)": {
    // flex: "0 1 75%",
    // },
  },
  // Objetos con cartas de datos cuantitativos historicos
  boxes: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  // Contenido de objetos con datos historicos
  innerBoxes: {
    display: "flex",
  },
}));

// Declaracion de tab historica para obras de terraceria
const HistorialTerraceria = () => {
  const [stats, setStats] = useState({});
  // Declaracion de estilos
  const classes = useStyles();
  // Instancia de axios
  const axios = useAxiosPrivate();

  // Petición get al endpoint de stats de terraceria
  const getStatsTerraceria = async () => {
    try {
      const response = await axios.get("terraceria/stats");
      setStats(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Cuando se monte este componente, realiza una petición get a los stats de terraceria
  useEffect(() => {
    getStatsTerraceria();
  }, []);

  // BODY Principal
  return (
    <div className={classes.root}>
      <div className={classes.boxes}>
        <DataHistorial
          number={stats?.proyectos}
          title="Proyectos totales"
          className={classes.innerBoxes}
        />
        <DataHistorial
          number={stats?.clientes}
          title="Clientes"
          className={classes.innerBoxes}
        />
        <DataHistorial
          number={stats?.pruebasTotales}
          title="Pruebas totales"
          className={classes.innerBoxes}
        />
        <DataHistorial
          number={stats?.pruebasHoy}
          title="Pruebas de hoy"
          className={classes.innerBoxes}
        />
      </div>
      <DataTable />
    </div>
  );
};

export default HistorialTerraceria;
