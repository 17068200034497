import "./App.css";
import {
  Routes,
  Navigate,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Concreto from "./components/Concreto";
import Terraceria from "./components/Terraceria";
import Historial from "./components/Historial";
import Configuraciones from "./components/Configuraciones";
import { ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Missing from "./components/Missing";
// import Pdf from "./components/PDF/App";
import PdfPreview from "./components/PDF/PdfPreview";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import { makeStyles } from "@material-ui/core/styles";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import EmailPasswordSent from "./components/EmailPasswordSent";
import PasswordResetSuccess from "./components/PasswordResetSuccess";

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "space-between",
    backgroundColor: "#F8F8F8",
    overflowY: "scroll",
  },
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.fullScreen}>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="*" element={<Missing />} />
            <Route exact path="/unauthorized" element={<Unauthorized />} />
            <Route exact path="/" element={<Navigate to="/auth" />} />
            <Route exact path="/auth" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/password-email-sent"
              element={<EmailPasswordSent />}
            />
            <Route exact path="/success" element={<PasswordResetSuccess />} />
            <Route element={<RequireAuth />}>
              <Route exact path="/concreto" element={<Concreto />} />
              <Route exact path="/terraceria" element={<Terraceria />} />
              <Route exact path="/historial" element={<Historial />} />
              <Route exact path="/vistaprevia" element={<PdfPreview />} />
              <Route
                exact
                path="/configuraciones"
                element={<Configuraciones />}
              />
              <Route exact path="/home" element={<Home />} />
            </Route>
          </Routes>
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
