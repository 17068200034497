import DownloadIcon from "@mui/icons-material/Download";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState, useCallback } from "react";
import MaterialTable from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DocuPDF2 from "../PDF/DocuPDF2";
import { useNavigate, useLocation } from "react-router-dom";
import { setData, setPruebas, setCase } from "../../features/auth/pdfSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { selectConfiguracionesTerraceria } from "../../features/configuraciones/configuracionesSlice";
import Swal from "sweetalert2";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    backgroundColor: "#F8F8F8",
    padding: "10px",
  },
  // Objeto popup para insertar y editar
  modal: {
    position: "absolute",
    width: 430,
    height: 540,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objeto popup para eliminar
  modalEliminar: {
    position: "absolute",
    width: 430,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objetos de iconos
  iconos: {
    cursor: "pointer",
  },
  // Objetos de entradas de datos (campos de formularios)
  inputMaterial: {
    width: "100%",
  },
}));

// Ruteo de backend
const baseURL = "/pterraceria";

// Declaracion de tabla interior
const InnerDataTableTerraceria = (props) => {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de controladores
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/vistaprevia";

  // Configuraciones de terraceria en redux
  const terraceriaConfig = useSelector(selectConfiguracionesTerraceria);

  // Declaracion de estados
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalNextInsertar, setModalNextInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [tableData, setTableData] = useState([{}]);
  const [hideColumns, setHideColumns] = useState(true);
  const [nPrueba, setNPrueba] = useState();
  const [nPruebaCont, setNPruebaCont] = useState(1);
  const [est, setEst] = useState("");
  const [lugarDeColado, setLugarDeColado] = useState();
  const [fechaRealizada, setFechaRealizada] = useState(new Date());
  const [fechaInforme, setFechaInforme] = useState(new Date());
  const [espesorDeCapa, setEspesorDeCapa] = useState();
  const [volumenPrueba, setVolumenPrueba] = useState();
  const [mvhl, setMvhl] = useState();
  const [masaHumedaMaterial, setMasaHumedaMaterial] = useState();
  const [contenidoAgua, setContenidoAgua] = useState();
  const [contAguaOptima, setContAguaOptima] = useState();
  const [masaVolSecaLugar, setMasaVolSecaLugar] = useState();
  let [compactacion, setCompactacion] = useState();
  const [mvsm, setMvsm] = useState();
  const [trompa, setTrompa] = useState("");
  const [bascula, setBascula] = useState("");
  const [flexometro, setFlexometro] = useState("");
  const [compactacionEspecificada, setCompactacionEspecificada] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const [idTerraceria, setIdTerraceria] = useState();
  let [pruebasTerraceria, setPruebasTerraceria] = useState([]);
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState({
    id: "",
    nPrueba: "",
    est: "",
    lugarDeColado: "",
    fechaRealizada: "",
    fechaInforme: "",
    espesorDeCapa: "",
    volumenPrueba: "",
    masaHumedaMaterial: "",
    mvhl: "",
    contenidoAgua: "",
    masaVolSecaLugar: "",
    compactacion: "",
    mvsm: "",
    contAguaOptima: "",
  });
  const [datosEdit, setDatosEdit] = useState({
    fechaRealizada: null,
    fechaInforme: null,
  });
  const [obraSeleccionada, setObraSeleccionada] = useState({
    id: "",
    nombreObra: "",
    lugarDeColado: "",
    clientes: {
      correoContacto: "",
      empresa: "",
      id: "",
      nomContacto: "",
      telContacto: "",
    },
  });

  // Declaracion de columnas de tabla
  const columnas = [
    {
      title: "Número General",
      field: "numeroGeneral",
      width: "auto",
    },
    {
      title: "Espesor de Capa",
      field: "espesorDeCapa",
      width: "auto",
    },
    {
      title: "Compactación",
      field: "compactacion",
      width: "auto",
    },
    {
      title: "Lugar de Colado",
      field: "lugarDeColado",
      width: "auto",
    },
    {
      title: "Conteo de agua óptima",
      field: "contAguaOptima",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Masa húmeda de material",
      field: "masaHumedaMaterial",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Volumen de Prueba",
      field: "volumenPrueba",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Contenido de agua",
      field: "contenidoAgua",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Fecha realizada",
      field: "fechaRealizada",
      width: "auto",
      type: "date",
      dateSetting: {
        format: "dd/mm/yyyy",
      },
      hidden: hideColumns,
    },
    {
      title: "Fecha informe",
      field: "fechaInforme",
      width: "auto",
      type: "date",
      dateSetting: {
        format: "dd/mm/yyyy",
      },
      hidden: hideColumns,
    },
    {
      title: "MVHL",
      field: "mvhl",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Contenido de agua",
      field: "contenidoAgua",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Masa de Volumen seca lugar",
      field: "masaVolSecaLugar",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Mvsm",
      field: "mvsm",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Trompa",
      field: "trompa",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Báscula",
      field: "bascula",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Flexómetro",
      field: "flexometro",
      width: "auto",
      hidden: hideColumns,
    },
    {
      title: "Compactación Especificada",
      field: "compactacionEspecificada",
      width: "auto",
      hidden: hideColumns,
    },
  ];

  // Metodos para manejo de datos y acciones
  const handleMultiEditChange = (e) => {
    const { name, value } = e.target;
    setDatosEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleMultiEditChangeDate = (date, name) => {
    // console.log(date);
    setDatosEdit((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };
  const peticionGet = useCallback(
    async (id) => {
      if (props?.getAll) {
        const prueba = await axios.get(baseURL + `/${id}`);
        setTableData(prueba.data.pruebas);
      } else {
        const dateFilter = props?.dateFilter || "";
        const prueba = await axios.get(
          baseURL + `/day/${id}/?date=${dateFilter}`
        );
        setTableData(prueba.data.pruebas);
      }
    },
    [axios]
  );
  const peticionPost = async () => {
    let t = obraSeleccionada.terracerias;
    compactacion = compactacion || t.compactacionEspecificada;
    await axios
      .post(baseURL, {
        pruebasTerraceria
      })
      .then((response) => {
        abrirCerrarModalInsertar();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Prueba añadida exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        peticionGet(idTerraceria);
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const peticionPut = async () => {
    if (datosEdit) {
      await axios
        .put(baseURL, { datosEdit, proyectoSeleccionado })
        .then((response) => {
          peticionGet(idTerraceria);
          Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Datos actualizados exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        })
        .catch((error) => {
          Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
          console.log(error);
        });
      setDatosEdit();
    }
    abrirCerrarModalEditar();
  };
  const peticionDelete = async () => {
    await axios
      .delete(baseURL + `/${proyectoSeleccionado}`)
      .then((response) => {
        peticionGet(idTerraceria);
        abrirCerrarModalEliminar();
        Swal.fire({
           position: "top-end",
           icon: "success",
           title: "Eliminado exitosamente",
           showConfirmButton: false,
           timer: 1000,
           timerProgressBar: true,
         });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const seleccionarProyecto = (proyectos, caso) => {
    let ids = [];
    proyectos.forEach((element) => {
      ids.push(element.id);
    });
    setProyectoSeleccionado(ids);
    if (proyectos.length === 1) {
      // Se elimina primero el atributo tableData
      const { tableData, ...proyecto } = proyectos[0];
      setDatosEdit(proyecto);
    }
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };
  const abrirCerrarModalInsertar = () => {
    if(modalInsertar){
      reiniciarVariablesGenerales();
    }
    setModalInsertar(!modalInsertar);
  };
  const nextModalInsertar = () => {
    var testNumber = nPruebaCont;
    addPruebaToArray();
    setNPruebaCont(++testNumber);
    document.getElementById('modalPrueba').scrollTo(0,0);
  };
  const testPost = () => {
    addPruebaToArray();
    peticionPost();
    reiniciarVariablesGenerales();
  };

  const addPruebaToArray = () => {
    let pruebasActuales = pruebasTerraceria;
    let nuevaPrueba = [
      nPruebaCont,
      est,
      espesorDeCapa,
      masaHumedaMaterial,
      volumenPrueba,
      mvhl,
      fechaRealizada,
      fechaInforme,
      lugarDeColado,
      contenidoAgua,
      masaVolSecaLugar,
      compactacion,
      mvsm,
      contAguaOptima,
      idTerraceria,
      trompa,
      bascula,
      flexometro,
      compactacionEspecificada,
    ];
    pruebasActuales.push(nuevaPrueba);
    setPruebasTerraceria(pruebasActuales);
    reiniciarVariablesPrueba();
    return;
  }

  const reiniciarVariablesPrueba = () => {
    // Limpiar modal 2do paso
    setEst("");
    setEspesorDeCapa();
    setMasaHumedaMaterial();
    setVolumenPrueba();
    setMvhl();
    const today = new Date();
    setFechaRealizada(today);
    setFechaInforme(today);
    setLugarDeColado();
    setContenidoAgua();
    setMasaVolSecaLugar()
    setCompactacion();
  }

  const reiniciarVariablesGenerales = () => {
    // Limpiar modal 1er y 2do paso
    setNPruebaCont(1);
    setNPrueba(1);
    setMvsm();
    setContAguaOptima();
    setTrompa("");
    setBascula("");
    setFlexometro("");
    reiniciarVariablesPrueba();
    setPruebasTerraceria([]);
    setModalNextInsertar(false);
  }

  const abrirCerrarModalEditar = () => {
    modalEditar &&
      setProyectoSeleccionado((prevState) => ({
        ...prevState.tableData,
        checked: false,
      }));
    setModalEditar(!modalEditar);
  };
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  useEffect(() => {
    const handleidTerraceria = async () => {
      setObraSeleccionada(props.data.rowData);
      const id = props.data.rowData.terracerias.id;
      peticionGet(id);
      setIdTerraceria(id);
    };
    handleidTerraceria();
  }, [peticionGet, props.data.rowData.terracerias.id, props.data.rowData]);

  // Formateando opciones de trompa
  let trompaOptions = [];
  terraceriaConfig?.trompaOptions.forEach((option) => {
    trompaOptions.push(option.title);
  });
  const menuItemsTrompa = trompaOptions.map(item => (
    <MenuItem key={item} value={item}>{item}</MenuItem>
  ));

  // Formateando opciones de bascula
  let basculaOptions = [];
  terraceriaConfig?.basculaOptions.forEach((option2) => {
    basculaOptions.push(option2.title);
  });
  const menuItemsBascula = basculaOptions.map(item2 => (
    <MenuItem key={item2} value={item2}>{item2}</MenuItem>
  ));

  // Formateando opciones de flexometro
  let flexometroOptions = [];
  terraceriaConfig?.flexometroOptions.forEach((option3) => {
    flexometroOptions.push(option3.title);
  });
  const menuItemsFlexometro = flexometroOptions.map(item3 => (
    <MenuItem key={item3} value={item3}>{item3}</MenuItem>
  ));

  // BODY Modal crear prueba
  const bodyInsertar = (
    <div className={classes.modal}>
      <h3>Agregar pruebas</h3>
      <br />
      <TextField
        className={classes.inputMaterial}
        autoFocus={true}
        label="Cantidad de pruebas"
        name="nPrueba"
        type="number"
        onChange={(e) => setNPrueba(e.target.value)}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Compactación Especificada"
        name="compactacionEspecificada"
        type="number"
        onChange={(e) => setCompactacionEspecificada(e.target.value)}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        type="number"
        label="Mvsm"
        name="mvsm"
        onChange={(e) => setMvsm(e.target.value)}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Cont. de agua óptima"
        type="number"
        name="contAguaOptima"
        onChange={(e) => setContAguaOptima(e.target.value)}
      />
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Trompa">Trompa</InputLabel>
        <Select
          labelId="trompa"
          id="trompa"
          value={trompa}
          label="TROMPA"
          onChange={(e) => setTrompa(e.target.value)}
        >
          {menuItemsTrompa}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Bascula">Báscula</InputLabel>
        <Select
          labelId="bascula"
          id="bascula"
          value={bascula}
          label="BÁSCULA"
          onChange={(e) => setBascula(e.target.value)}
        >
          {menuItemsBascula}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Flexometro">Flexómetro</InputLabel>
        <Select
          labelId="flexometro"
          id="flexometro"
          value={flexometro}
          label="FLEXÓMETRO"
          onChange={(e) => setFlexometro(e.target.value)}
        >
          {menuItemsFlexometro}
        </Select>
      </FormControl>
      <br />
      <br />
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalInsertar()}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={() => setModalNextInsertar(true)}>
          Siguiente
        </Button>
      </div>
    </div>
  );

  const bodyInsertarNext = (
      <div className={classes.modal} id={"modalPrueba"}>
        <h3>Agregar prueba</h3>
        <br />
        <div key={nPruebaCont}>
          <br />
          <h4>Terraceria {nPruebaCont}</h4>
          <FormControl fullWidth>
            <InputLabel id="Est">EST.</InputLabel>
            <Select
              labelId="est"
              id="est"
              value={est}
              label="EST"
              onChange={(e) => setEst(e.target.value)}
            >
              <MenuItem value={"CTO."}>Centro</MenuItem>
              <MenuItem value={"DER."}>Derecha</MenuItem>
              <MenuItem value={"IZQ."}>Izquierda</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Espesor de Capa"
            name="espesorDeCapa"
            type="number"
            onChange={(e) => setEspesorDeCapa(e.target.value)}
          />
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Lugar de Colado"
            name="lugarDeColado"
            onChange={(e) => setLugarDeColado(e.target.value)}
          />
          <br />
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Fecha realizada"
              name="fechaRealizada"
              disableMaskedInput={false}
              value={fechaRealizada}
              onChange={(date) => {
                setFechaRealizada(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <br />
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Fecha informe"
              name="fechaInforme"
              disableMaskedInput={false}
              value={fechaInforme}
              onChange={(date) => {
                setFechaInforme(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Compactación"
            type="number"
            name="compactacion"
            onChange={(e) => setCompactacion(e.target.value)}
          />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Contenido de agua"
            type="number"
            name="contenidoAgua"
            onChange={(e) => setContenidoAgua(e.target.value)}
          />
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Masa húmeda de material"
            type="number"
            name="masaHumedaMaterial"
            onChange={(e) => setMasaHumedaMaterial(e.target.value)}
          />
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Volumen de Prueba"
            type="number"
            name="volumenPrueba"
            onChange={(e) => setVolumenPrueba(e.target.value)}
          />
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="MVHL"
            type="number"
            name="mvhl"
            onChange={(e) => setMvhl(e.target.value)}
          />
          <br />
          <br />
          <TextField
            className={classes.inputMaterial}
            label="Masa de Volumen seca lugar"
            type="number"
            name="masaVolSecaLugar"
            onChange={(e) => setMasaVolSecaLugar(e.target.value)}
          />
          <br />
          <br />
          <div align="right">
            <Button color="secondary" onClick={() => abrirCerrarModalInsertar()}>
              {nPruebaCont < nPrueba ? 'Cancelar' : 'Cancelar todo'}
            </Button>
            <Button color="secondary" onClick={() => {nPruebaCont < nPrueba ? nextModalInsertar() : testPost()}}>
              {nPruebaCont < nPrueba ? 'Siguiente' : 'Agregar'}
            </Button>
          </div>
        </div>
      </div>
  );

  // BODY Modal editar prueba
  const bodyEditar = (
    <div className={classes.modal}>
      <h3>Editar esta prueba</h3>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Número de prueba"
        autoFocus={true}
        name="nPrueba"
        value={datosEdit?.nPrueba || ""}
        onChange={handleMultiEditChange}
      />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Est">EST.</InputLabel>
        <Select
          labelId="est"
          id="est"
          value={datosEdit?.est}
          label="EST"
          onChange={handleMultiEditChange}
        >
          <MenuItem value={"CTO."}>Centro</MenuItem>
          <MenuItem value={"DER."}>Derecha</MenuItem>
          <MenuItem value={"IZQ."}>Izquierda</MenuItem>
        </Select>
      </FormControl>
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Espesor de Capa"
        name="espesorDeCapa"
        value={datosEdit?.espesorDeCapa || ""}
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Lugar de Colado"
        name="lugarDeColado"
        value={datosEdit?.lugarDeColado || ""}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha realizada"
          name="fechaRealizada"
          disableMaskedInput={false}
          value={datosEdit?.fechaRealizada}
          onChange={(date) => {
            handleMultiEditChangeDate(date);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha informe"
          name="fechaInforme"
          disableMaskedInput={false}
          value={datosEdit?.fechaInforme}
          onChange={(date) => {
            handleMultiEditChangeDate(date);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Compactación"
        type="number"
        name="compactacion"
        value={datosEdit?.compactacion}
        onChange={handleMultiEditChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Contenido de agua"
        type="number"
        name="contenidoAgua"
        value={datosEdit?.contenidoAgua}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Conteo de agua óptima"
        name="contAguaOptima"
        value={datosEdit?.contAguaOptima}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Masa húmeda de material"
        name="masaHumedaMaterial"
        value={datosEdit?.masaHumedaMaterial}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Volumen de Prueba"
        name="volumenPrueba"
        value={datosEdit?.volumenPrueba}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="MVHL"
        name="mvhl"
        value={datosEdit?.mvhl}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Contenido de agua"
        name="contenidoAgua"
        value={datosEdit?.contenidoAgua}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Masa de Volumen seca lugar"
        name="masaVolSecaLugar"
        value={datosEdit?.masaVolSecaLugar}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Mvsm"
        name="mvsm"
        value={datosEdit?.mvsm}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Compactación Especificada"
        name="compactacionEspecificada"
        type="number"
        value={datosEdit?.compactacionEspecificada}
        onChange={handleMultiEditChange}
      />
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Trompa">Trompa</InputLabel>
        <Select
          labelId="trompa"
          id="trompa"
          value={datosEdit?.trompa}
          label="TROMPA"
          onChange={handleMultiEditChange}
        >
          {menuItemsTrompa}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Bascula">Báscula</InputLabel>
        <Select
          labelId="bascula"
          id="bascula"
          value={datosEdit?.bascula}
          label="BÁSCULA"
          onChange={handleMultiEditChange}
        >
          {menuItemsBascula}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="Flexometro">Flexómetro</InputLabel>
        <Select
          labelId="flexometro"
          id="flexometro"
          value={datosEdit?.flexometro}
          label="FLEXÓMETRO"
          onChange={handleMultiEditChange}
        >
          {menuItemsFlexometro}
        </Select>
      </FormControl>
      <br />
      <div align="right">
        <Button color="secondary" onClick={() => abrirCerrarModalEditar()}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={() => peticionPut()}>
          Editar
        </Button>
      </div>
    </div>
  );

  // BODY Modal eliminar prueba
  const bodyEliminar = (
    <div className={classes.modalEliminar}>
      <p>
        Estás seguro que deseas eliminar al proyecto{" "}
        <b>{proyectoSeleccionado && proyectoSeleccionado.nombreObra}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  );

  // BODY Principal
  return (
    <>
      <div className={classes.root}>
        {/* Descarga de PDF */}
        {props.verPDF && (
          <>
            <PDFDownloadLink
              document={
                <DocuPDF2
                  pruebas={proyectoSeleccionado}
                  data={obraSeleccionada}
                />
              }
              fileName={`${obraSeleccionada.nombreObra} - ${obraSeleccionada.ubicacion} .pdf`}
            >
              <Button
                id={"downloadpdf"}
                color="secondary"
                variant="contained"
                style={{ display: "none" }}
              >
                Descargar PDF
              </Button>
            </PDFDownloadLink>
          </>
        )}
        {/* Tabla con pruebas de terraceria */}
        <MaterialTable
          columns={columnas}
          data={tableData}
          onSelectionChange={(selected, rowData) => {
            if (selected.length > 1) {
              setDisabledButton(false);
              setProyectoSeleccionado(selected);

              // Lugar de colado

              //   if (
              //     lastRevenimiento === rowData.revenimientoDeProyecto &&
              //     lastResistencia === rowData.resistenciaDeProyecto
              //   ) {
              //     setLastResistencia(rowData.resistenciaDeProyecto);
              //     setLastRevenimiento(rowData.revenimientoDeProyecto);
              //     setDisabledButton(false);
              //   } else {
              //     selected[selected.indexOf(rowData)]?.tableData?.checked &&
              //       window.alert(
              //         "Avertencia. La prueba que intenta seleccionar contiene resistencia y/o revenimientos que difieren de las demás. No es posible imprimir el PDF"
              //       );
              //     // selected[selected.indexOf(rowData)].tableData.checked = false;
              //     setDisabledButton(true);
              //   }
              // } else {
              //   setLastRevenimiento(rowData.revenimientoDeProyecto);
              //   setLastResistencia(rowData.resistenciaDeProyecto);
            }
          }}
          actions={[
            {
              icon: PrintIcon,
              tooltip: "Imprimir Obra",
              disabled: disabledButton,
              onClick: (event, rowData) => {
                dispatch(setData(obraSeleccionada));
                dispatch(setPruebas(rowData));
                dispatch(setCase("terraceria"));
                navigate(from, { replace: true });
                setProyectoSeleccionado(rowData);
              },
            },
            {
              icon: DownloadIcon,
              disabled: disabledButton,
              tooltip: "Descargar pruebas en PDF",

              onClick: (event, rowData) => {
                setProyectoSeleccionado(rowData);
                props.setVerPDF(true);
                setTimeout(() => {
                  document.getElementById("downloadpdf").click();
                }, 600);
              },
            },
            {
              icon: EditIcon,
              tooltip: "Editar Proyecto",
              onClick: (event, rowData) => {
                seleccionarProyecto(rowData, "Editar");
              },
            },
            {
              icon: DeleteIcon,
              tooltip: "Eliminar proyecto",
              onClick: (event, rowData) => {
                seleccionarProyecto(rowData, "Eliminar");
              },
            },
            {
              icon: InfoIcon,
              isFreeAction: true,
              tooltip: hideColumns
                ? "Mostrar todas las columnas de la tabla"
                : "Ocultar las columnas nuevamente",
              onClick: () => {
                setHideColumns(!hideColumns);
              },
            },
            {
              icon: AddIcon,
              isFreeAction: true,
              tooltip: "Agregar Prueba",
              onClick: () => abrirCerrarModalInsertar(),
            },
          ]}
          options={{
            exportButton: true,
            exportAllData: true,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            selection: true,
            showTextRowsSelected: true,
            showSelectAllCheckbox: false,
            columnsButton: true,
            showDetailPanelIcon: true,
            tableLayout: "auto",
            paging: true,
            search: true,
            sorting: true,
            showTitle: false,
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar...",
              showColumnsAriaLabel: "Columnas",
              showColumnsTitle: "Columnas",
            },
            pagination: {
              nextTooltip: "Siguiente",
              previousTooltip: "Anterior",
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Renglones por página:",
              labelRowsSelect: "renglones",
            },
            body: {
              addTooltip: "Agregar",
              editTooltip: "Editar",
              deleteTooltip: "Borrar",
              editRow: {
                deleteText: "Borrar este renglón?",
              },
              emptyDataSourceMessage: (
                <>
                  <p> No hay pruebas registradas en esta obra</p>
                  <Button
                    color="primary"
                    onClick={() => abrirCerrarModalInsertar()}
                  >
                    Agregar
                  </Button>
                </>
              ),
            },
          }}
          onRowClick={(event, rowData) => {
            const rowDataCopy = { ...rowData };
            rowDataCopy.tableData.checked = false;
            const dataCopy = [...tableData];
            dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
            setTableData(dataCopy);
          }}
        />

        {/* Modales */}
        <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
          {modalNextInsertar === false ? bodyInsertar : bodyInsertarNext}
        </Modal>
        <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>
          {bodyEditar}
        </Modal>
        <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>
          {bodyEliminar}
        </Modal>
      </div>
    </>
  );
};
export default InnerDataTableTerraceria;
