import logoGCC from "../../imgs/logogcc-removebg-preview.png";
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  DataTableCell,
  TableBody,
} from "@david.kucsai/react-pdf-table";
import { divideTestsByEquipment } from "../../services/divideTestsByEquipment";
import { dividePruebasByLocation } from "../../services/dividePruebasByLocation";
import { splitPruebasGroupsToPages } from "../../services/splitPruebasGroupsToPages";
import DataTable from "./../Terraceria/DataTable";
import { array } from "yup";
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Estilos
const styles = StyleSheet.create({
  // Objeto para formato de paginacion
  page: {
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  // Objeto con titulo GCC
  title: {
    marginBottom: 1,
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  // Objeto para formato de textos
  text: {
    marginLeft: 35,
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontWeight: "bold",
  },
  // Objeto para formato de seccion, wrapper
  section: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 15,
    padding: 10,
    flexGrow: 1,
  },
  // Objeto con titulos de columnas en tabla de pdf
  headerRow: {
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
  },
  locationRow: {
    height: 30,
    borderBottomWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
  },
  // Objeto para alineacion de contenido en tabla de pdf
  cell: {
    margin: 0,
    borderWidth: 0,
    borderRightWidth: 1,
    fontSize: 8,
    height: 30,
    paddingHorizontal: 5,
    paddingVertical: 5,
    textAlign: "center",
    justifyContent: "center",
    // NOTE: alignItems property gives -infinity error
    // alignItems: "center",
    fontFamily: "Times-Roman",
  },
  // Objeto con sub-titulo GCC
  subtitle: {
    fontSize: 10,
    marginLeft: 70,
    marginBottom: 10,
    fontFamily: "Oswald",
    textAlign: "center",
    letterSpacing: "",
    alignContent: "right",
    width: "52%",
  },
  // Objeto logo
  image: {
    marginVertical: 10,
    paddingHorizontal: 150,
    width: "100px",
    height: "100px",
    float: "flex",
  },
  footerRev: {
    fontSize: 6,
    textAlign: "right",
    marginTop: 16,
  },
  footerAddr: {
    textTransform: "uppercase",
    fontStyle: "bold",
    fontSize: 7,
    textAlign: "center",
  },
  footerWebsite: {
    textTransform: "lowercase",
    fontStyle: "bold",
    fontSize: 8,
    textAlign: "center",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  headerFirstHalf: {
    // backgroundColor: "aquamarine",
    width: "55%",
    paddingVertical: 3,
  },
  headerSecondHalf: {
    // backgroundColor: "violet",
    flexGrow: 1,
    paddingVertical: 3,
  },
  headerTitleLeft: {
    // backgroundColor: "red",
    width: "30%",
    fontSize: 10,
  },
  headerTitleRight: {
    // backgroundColor: "red",
    width: "40%",
    fontSize: 10,
  },
  headerValue: {
    // backgroundColor: "blue",
    flexGrow: 1,
    fontSize: 10,
  },
  nmx: {
    fontSize: 9,
    textAlign: "justify",
    textIndent: 1,
    width: 150,
    display: "block",
    strokeLineCap: "butt",
    dominantBaseline: "text-after-edge",
    marginBottom: 10,
  },
  notes: {
    fontSize: 7,
    textAlign: "left",
    textIndent: 1,
    marginTop: 5,
    display: "block",
    strokeLineCap: "butt",
    dominantBaseline: "text-after-edge",
    marginBottom: 10,
  },
  firma: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginHorizontal: 10,
    marginTop: 90,
    borderTopWidth: 0.5,
  },
  headerTable: {
    height: 375,
  },
});

// Metodos para manejo de datos y acciones
const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Declaracion de IMPRESION para PDFs de terraceria
const DocuPDF2 = ({ pruebas, data, date, config }) => {
  // {console.log(data)}
  const pruebasPorEquipoUtilizado = divideTestsByEquipment(pruebas); //dividir por equipo
  // console.log("Test by used equipment");
  // console.log(pruebasPorEquipoUtilizado);
  const pruebasDivided = dividePruebasByLocation(pruebasPorEquipoUtilizado);
  // console.log("pruebasDivided"); // + JSON.stringify(pruebasDivided));
  // console.log(pruebasDivided);
  const pruebasByPage = pruebasDivided; //splitPruebasGroupsToPages(pruebasDivided); //Este funciona bien en main
  // console.log("pruebasByPage"); // + JSON.stringify(pruebasByPage));
  // console.log(pruebasByPage);
  let pagesFinal = [];
  let page = [];
  // Set column widths
  const columnWidths = {
    nPrueba: 0.05,
    est: 0.1,
    espesorDeCapa: 0.1,
    masaHumedaMaterial: 0.1,
    volumenPrueba: 0.1,
    mvhl: 0.1,
    contenidoAgua: 0.1,
    masaVolSecaLugar: 0.1,
    contAguaOptima: 0.05,
    mvsm: 0.1,
    compactacion: 0.1,
  };
  // Acceder al objeto que contiene la propiedad "trompa","bascula","flexometro"
  const objTerraceria = data.terracerias.pterracerias[0];
  //trompa
  const valorTrompa = objTerraceria.trompa;
  //bascula
  const valorBascula = objTerraceria.bascula;
  //flexometro
  const valorFlexometro = objTerraceria.flexometro;

  pruebasByPage.forEach((pageGroup, pageIndex) => {
    // console.log(pageIndex);
    // console.log(pageGroup);
    // console.log("pg: " + JSON.stringify(pageGroup));
    // console.log("pI: " + pageIndex);
    // console.log("pp0: " + pruebasByPage[0][1].length);
    // console.log(pruebasByPage[0]);
    // console.log("pp: " + pruebasByPage.length);
    // console.log("pp00: " + pruebasByPage[0][0].length);
    // Original array
    // const items = [12, 24, 36];
    // const copy = [];
    // pageGroup.forEach(function (item, i) {
    //   copy.push(item + i);
    //   <Text>item</Text>;
    // });
    // // console.log(copy);
    // {
    //   console.log("====================================");
    // }
    // {
    //   console.log(JSON.stringify(item));
    // }
    // {
    //   console.log("====================================");
    // }
    // let colado = pageGroup.length ? pageGroup[0].lugarDeColado : null;
    // console.log(colado);

    pageGroup.forEach((item, i) => {
      // console.log("------INDEX----------" + i + "--------INDEX--------");
      //console.log(i);
      // console.log("------INDEX----------" + i + "--------INDEX--------");
      // console.log(
      //   "--------ITEM--------" + JSON.stringify(item) + "--------ITEM--------"
      // );
      // console.log(item);
      // console.log("--------ITEM--------" + item + "-------ITEM---------");
      // console.log(
      //   "--------lugar colado--------" + date + "--------lugar colado--------"
      // );
      // function pageGroup(item, i) {
      //   <>
      //     <Text>item</Text>
      //     <Text>:</Text>
      //     <Text>i</Text>
      //   </>;
      // }
      // let lugarColado = [pageGroup.lugarDeColado];
      // for (let i = 0; i < lugarColado.length; i++) {
      //   console.log(lugarColado[i]);
      // }

      page = (
        <View style={styles.section}>
          {/* <View style={{ flexDirection: "row" }}>
            <Image
              style={
                (styles.image,
                {
                  flexDirection: "column",
                  width: " 80px",
                  height: "80px",
                  marginHorizontal: 10,
                })
              }
              src={logoGCC}
            />
            <View style={{ flexDirection: "column" }}>
              <Text style={styles.title}>
                GEOTECNIA Y CALIDAD CONSTRUCTIVA, S.A. DE C.V.
              </Text>
              <Text style={styles.subtitle}>
                SERVICIOS DE CONTROL DE CALIDAD Y ASESORIA TÉCNICA EN LA
                CONSTRUCCIÓN LABORATORIO DE SUELOS, CONCRETOS Y ASFALTOS
              </Text>
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <Text style={styles.text}>
                  Informe de pruebas de compactación
                </Text>
              </View>
            </View>
          </View> */}
          <Text style={{ ...styles.text, textAlign: "center", marginTop: 76 }}>
              Informe de pruebas de compactación
            </Text>
          <View style={styles.flexRow}>
            <View style={styles.headerFirstHalf}>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleLeft}>
                  <Text>Obra:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{data?.nombreObra}</Text>
                  {/* {console.log(data)} */}
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleLeft}>
                  <Text>Dirección:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{data?.terracerias?.direccion}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleLeft}>
                  <Text>Cliente:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{data?.clientes?.nomContacto}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleLeft}>
                  <Text>Atención:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{data?.terracerias?.atencion}</Text>
                </View>
              </View>
            </View>
            <View style={styles.headerSecondHalf}>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleRight}>
                  <Text>Orden de Trabajo:</Text>
                </View>
                <View style={styles.headerValue}>
                  {/* TODO: Where is this data? */}
                  <Text>{""}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleRight}>
                  <Text>Fecha de Recibido:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{formatDate(data?.terracerias?.fechaRealizada)}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleRight}>
                  <Text>Fecha de informe:</Text>
                </View>
                <View style={styles.headerValue}>
                  <Text>{formatDate(data?.terracerias?.fechaInforme)}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.headerTitleRight}>
                  <Text>Método empleado:</Text>
                </View>
                <View style={styles.headerValue}>
                  {/* TODO: Where is this data? */}
                  <Text>{""}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Equipo utilizado, báscula & flexómetro */}
          <View style={{ ...styles.flexRow, marginBottom: 10 }}>
            {/* Equipo utilizado */}
            <View style={{ ...styles.flexRow, flexGrow: 1 }}>
              <View style={{ ...styles.headerTitleLeft, width: "41%" }}>
                <Text>Equipo Utilizado: Trompa: GCC/044-{valorTrompa}</Text>
              </View>
              <View style={styles.headerValue}>
                <Text>{""}</Text>
              </View>
            </View>
            {/* Báscula */}
            <View style={{ ...styles.flexRow, flexGrow: 1 }}>
              <View style={styles.headerTitleLeft}>
                <Text>Báscula: GCC/010-{valorBascula}</Text>
              </View>
              <View style={styles.headerValue}>
                <Text>{""}</Text>
              </View>
            </View>
            {/* Flexómetro */}
            <View style={{ ...styles.flexRow, flexGrow: 1 }}>
              <View style={{ ...styles.headerTitleLeft, width: "35%" }}>
                <Text>Flexómetro: GCC/075-{valorFlexometro}</Text>
              </View>
              <View style={styles.headerValue}>
                <Text>{""}</Text>
              </View>
            </View>
          </View>
          {/* Tabla con datos */}
          <View style={styles.headerTable}>
            <Table style={styles.headerRow} data={pageGroup}>
            <TableHeader>
              <TableCell
                weighting={columnWidths.nPrueba}
                style={styles.headerRow}
              >
                Prueba Nº
              </TableCell>
              <TableCell weighting={columnWidths.est} style={styles.headerRow}>
                Est.
              </TableCell>
              <TableCell
                weighting={columnWidths.espesorDeCapa}
                style={styles.headerRow}
              >
                Espesor de capa (cm)
              </TableCell>
              <TableCell
                weighting={columnWidths.masaHumedaMaterial}
                style={styles.headerRow}
              >
                Masa húmeda (g)
              </TableCell>
              <TableCell
                weighting={columnWidths.volumenPrueba}
                style={styles.headerRow}
              >
                Volumen (cm³)
              </TableCell>
              <TableCell weighting={columnWidths.mvhl} style={styles.headerRow}>
                M.V.H.L. kg/m³
              </TableCell>
              <TableCell
                weighting={columnWidths.contenidoAgua}
                style={styles.headerRow}
              >
                Cont. de agua (%)
              </TableCell>
              <TableCell
                weighting={columnWidths.masaVolSecaLugar}
                style={styles.headerRow}
              >
                M.V.S.L. kg/m³
              </TableCell>
              <TableCell
                weighting={columnWidths.contAguaOptima}
                style={styles.headerRow}
              >
                A.O %
              </TableCell>
              <TableCell weighting={columnWidths.mvsm} style={styles.headerRow}>
                M.V.S.M. kg/m³
              </TableCell>
              <TableCell
                weighting={columnWidths.compactacion}
                style={styles.headerRow}
              >
                Compactación %
              </TableCell>
            </TableHeader>
            <TableBody
              includeTopBorder={false}
              includeBottomBorder={true}
              includeLeftBorder={true}
              includeRightBorder={true}
            >
              {/* ---ESTE ES EL QUE SE QUIERE ARREGLA PARA QUE SE MUESTRE ENCIMA DE LA INFORMACION DE CDA PRUEBA--- */}
              {/* {console.log(pageGroup)} */}
              {/* <Table data={pageGroup} isNested={true}>
                <TableHeader>
                  <TableCell
                    style={styles.locationRow}
                    weighting={1}
                    // getContent={(pageGroup) => pageGroup.lugarDeColado}
                    >
                    {pageGroup[i][0].lugarDeColado}
                  </TableCell>
                  {console.log(i)}
                  {console.log(pageGroup[i][0].lugarDeColado)}
                </TableHeader>
                <TableBody
                  textAlign={"center"}
                  includeTopBorder={false}
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}

                  <DataTableCell
                    weighting={columnWidths.nPrueba}
                    style={styles.cell}
                    getContent={(r) => r.nPrueba}
                  />
                  <DataTableCell
                    weighting={columnWidths.est}
                    style={styles.cell}
                    getContent={(r) => r.est}
                  />
                  <DataTableCell
                    weighting={columnWidths.espesorDeCapa}
                    style={styles.cell}
                    getContent={(r) => r.espesorDeCapa}
                  />
                  <DataTableCell
                    weighting={columnWidths.masaHumedaMaterial}
                    style={styles.cell}
                    getContent={(r) => r.masaHumedaMaterial}
                  />
                  <DataTableCell
                    weighting={columnWidths.volumenPrueba}
                    style={styles.cell}
                    getContent={(r) => r.volumenPrueba}
                  />
                  <DataTableCell
                    weighting={columnWidths.mvhl}
                    style={styles.cell}
                    getContent={(r) => r.mvhl}
                  />
                  <DataTableCell
                    weighting={columnWidths.contenidoAgua}
                    style={styles.cell}
                    getContent={(r) => r.contenidoAgua}
                  />
                  <DataTableCell
                    weighting={columnWidths.masaVolSecaLugar}
                    style={styles.cell}
                    getContent={(r) => r.masaVolSecaLugar}
                  />
                  <DataTableCell
                    weighting={columnWidths.contAguaOptima}
                    style={styles.cell}
                    getContent={(r) => r.contAguaOptima}
                  />
                  <DataTableCell
                    weighting={columnWidths.mvsm}
                    style={styles.cell}
                    getContent={(r) => r.mvsm}
                  />
                  <DataTableCell
                    weighting={columnWidths.compactacion}
                    style={{ ...styles.cell, borderRightWidth: 0 }}
                    getContent={(r) => r.compactacion}
                  />
                </TableBody>
              </Table> */}

              {/* ---ESTE ES EL QUE SE QUIERE ARREGLA PARA QUE SE MUESTRE ENCIMA DE LA INFORMACION DE CDA PRUEBA--- */}
              <DataTableCell
                weighting={1}
                getContent={(pageGroup) => (
                  <Table data={item} isNested={true}>
                    <TableHeader
                      includeTopBorder={false}
                      includeBottomBorder={false}
                      includeLeftBorder={false}
                      includeRightBorder={false}
                    >
                      <TableCell style={styles.locationRow} weighting={1}>
                        {pageGroup[0].lugarDeColado}
                      </TableCell>
                      {/* {console.log(pageGroup[0].lugarDeColado)} */}
                    </TableHeader>
                    <TableBody
                      textAlign={"center"}
                      includeTopBorder={false}
                      includeBottomBorder={false}
                      includeLeftBorder={false}
                      includeRightBorder={false}
                    >
                      <DataTableCell
                        weighting={columnWidths.nPrueba}
                        style={styles.cell}
                        getContent={(r) => r.nPrueba}
                      />
                      <DataTableCell
                        weighting={columnWidths.est}
                        style={styles.cell}
                        getContent={(r) => r.est}
                      />
                      <DataTableCell
                        weighting={columnWidths.espesorDeCapa}
                        style={styles.cell}
                        getContent={(r) => r.espesorDeCapa}
                      />
                      <DataTableCell
                        weighting={columnWidths.masaHumedaMaterial}
                        style={styles.cell}
                        getContent={(r) => r.masaHumedaMaterial}
                      />
                      <DataTableCell
                        weighting={columnWidths.volumenPrueba}
                        style={styles.cell}
                        getContent={(r) => r.volumenPrueba}
                      />
                      <DataTableCell
                        weighting={columnWidths.mvhl}
                        style={styles.cell}
                        getContent={(r) => r.mvhl}
                      />
                      <DataTableCell
                        weighting={columnWidths.contenidoAgua}
                        style={styles.cell}
                        getContent={(r) => r.contenidoAgua}
                      />
                      <DataTableCell
                        weighting={columnWidths.masaVolSecaLugar}
                        style={styles.cell}
                        getContent={(r) => r.masaVolSecaLugar}
                      />
                      <DataTableCell
                        weighting={columnWidths.contAguaOptima}
                        style={styles.cell}
                        getContent={(r) => r.contAguaOptima}
                      />
                      <DataTableCell
                        weighting={columnWidths.mvsm}
                        style={styles.cell}
                        getContent={(r) => r.mvsm}
                      />
                      <DataTableCell
                        weighting={columnWidths.compactacion}
                        style={{ ...styles.cell, borderRightWidth: 0 }}
                        getContent={(r) => r.compactacion}
                      />
                    </TableBody>
                  </Table>
                )}
              />
            </TableBody>
            </Table>
          </View>

          <View>
            <Text style={styles.notes}>
              Nota: Los resultados presentes en este informe están relacionados
              únicamente a las pruebas efectuadas. Este reporte no puede ser
              reproducido parcial o totalmente sin previa autorización por
              escrito del laboratorio.
            </Text>
          </View>
          <View
            style={{
              ...styles.flexRow,
              height: 130,
            }}
          >
            {/* Normas */}
            <View>
              <Text style={styles.nmx}>
                En la elaboración de este informe se utilizo la siguiente norma:
                NMX-C- 416-ONNCE-2003 JACiSOS (15.1- 15.4, 15.4.1. 15.4.1.1.
                15.4.1.3, 15.4.2, 15.4.2.1/15.4.2.2, 15.4.2.2.4- 15.5, 15.5.1,
                15.5.2, 15,5/5, 15.5.6, 15.5.14. 15.5.45. 156.3. 15.64. 15.7.5,
                15.7.5.1 al 15.7.5.7, 15.85. 15.8.5.1 al 15.8.5.8, 15.9.1.
                15.9.1.1. 15.9.5, 15.9.5.1. 159.5.2 15.9.8. 15.9.8.1, 15.9.8.2,
                15.9.9, 15.9.9.1 y 15.11).
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                OBSERVACIONES: {config?.observacion}
              </Text>
              {/* Firmas */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  width: "95%",
                }}
              >
                {/* Tecnico */}
                <View style={styles.firma}>
                  <Text style={{ fontSize: 8 }}>{config?.tecnico}</Text>
                  <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                    Técnico Laborista
                  </Text>
                </View>
                {/* Signatario */}
                <View style={styles.firma}>
                  <Text style={{ fontSize: 8 }}>{config?.firma}</Text>
                  <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                    Firma del Responsable
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.footerRev}>
              Codigo: FOR 080; Revision 01; Pagina {pageIndex + 1} de{" "}
              {pruebasByPage[0][0].length}
            </Text>
            {/* <Text style={styles.footerAddr}>
              lázaro cardenas nº.436 col. emiliano zapata, monterrey, n.l. c.p.
              64390 tels, 81-8347-8528, 81-83478570 y 81-8348-3485
            </Text>
            <Text style={styles.footerWebsite}>www.geotecnia.mx</Text> */}
          </View>
        </View>
      );
    });

    pagesFinal.push(page);
  });

  // BODY Principal
  return (
    <Document title="Proyectos de Obra">
      <>
        {pagesFinal.map((page, i) => {
          return (
            <Page size="A4" style={styles.page} key={i} wrap>
              {page}
            </Page>
          );
        })}
      </>
    </Document>
  );
};

export default DocuPDF2;
