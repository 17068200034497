import logoGCC from "../../imgs/logogcc-removebg-preview.png";
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  DataTableCell,
  TableBody,
} from "@david.kucsai/react-pdf-table";
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Metodo para formato de fechas
const formatDate = (date) => {
  if (date === "") return "";

  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Estilos
const styles = StyleSheet.create({
  // Objeto para formato de paginacion
  page: {
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Times-Roman",
  },
  header: {
    // backgroundColor: "aquamarine",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 5,
    marginHorizontal: 35,
    borderBottom: 1,
    fontSize: 12,
  },
  subheader: {
    flexDirection: "row",
    paddingVertical: 20,
    marginHorizontal: 35,
    fontSize: 14,
    title: {
      fontFamily: "Times-Bold",
      marginLeft: 20,
    },
  },
  headerRow: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderWidth: 0,
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
  },
  cell: {
    margin: 0,
    borderRightWidth: 0,
    fontSize: 10,
    height: 30,
    paddingHorizontal: 5,
    paddingVertical: 5,
    textAlign: "center",
    justifyContent: "center",
    // NOTE: alignItems property gives -infinity error
    // alignItems: "center",
    fontFamily: "Times-Roman",
  },
  cellInput: {
    borderBottomWidth: 1,
    marginHorizontal: 5,
  },
});

const DocuPDFCargas = ({ pruebas, date }) => {
  const columnWidths = {
    numeroGeneral: 0.09,
    nCilindro: 0.09,
    obra: 0.26,
    fechaMuestreo: 0.16,
    duracion: 0.06,
    fechaEnsaye: 0.13,
    carga: 0.12,
    diametro: 0.10,
  };
  // BODY Principal
  if (pruebas.length !== 0) {
    return (
      <Document title="Proyectos de Obra">
        <>
          <Page size="A4" style={styles.page} wrap>
            <View>
              <View style={styles.header}>
                <Text>SERVICIOS DE CONTROL DE CALIDAD, S.A.</Text>
                {/* TODO: Change this dynamically */}
                <Text>Página: 1</Text>
              </View>
              <View style={styles.subheader}>
                <Text style={styles.subheader.title}>
                  Programa de pruebas de concreto para el día:{" "}
                </Text>
                <Text>{formatDate(pruebas[0].fechaEnsaye)}</Text>
              </View>
              <View style={styles.body}>
                <Table data={pruebas}>
                  <TableHeader
                    style={styles.headerRow}
                    includeTopBorder={false}
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                  >
                    <TableCell
                      weighting={columnWidths.numeroGeneral}
                      style={styles.headerRow}
                    >
                      Num. General
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.nCilindro}
                      style={styles.headerRow}
                    >
                      Num. Cilindro
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.obra}
                      style={styles.headerRow}
                    >
                      Obra
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.fechaMuestreo}
                      style={styles.headerRow}
                    >
                      Fecha de Muestreo
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.duracion}
                      style={styles.headerRow}
                    >
                      Edad
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.fechaEnsaye}
                      style={styles.headerRow}
                    >
                      Fecha de Ensaye
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.carga}
                      style={{ ...styles.headerRow, marginHorizontal: 5 }}
                    >
                      Carga
                    </TableCell>
                    <TableCell
                      weighting={columnWidths.diametro}
                      style={{ ...styles.headerRow, marginHorizontal: 5 }}
                    >
                      Diametro
                    </TableCell>
                  </TableHeader>
                  <TableBody
                    includeTopBorder={false}
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                  >
                    <DataTableCell
                      weighting={columnWidths.numeroGeneral}
                      style={styles.cell}
                      getContent={(r) => r.numeroGeneral}
                    />
                    <DataTableCell
                      weighting={columnWidths.nCilindro}
                      style={styles.cell}
                      getContent={(r) => r.nCilindro}
                    />
                    <DataTableCell
                      weighting={columnWidths.obra}
                      style={styles.cell}
                      getContent={(r) => r.nombreObra}
                    />
                    <DataTableCell
                      weighting={columnWidths.fechaMuestreo}
                      style={styles.cell}
                      getContent={(r) => formatDate(r.fechaMuestreo)}
                    />
                    <DataTableCell
                      weighting={columnWidths.duracion}
                      style={styles.cell}
                      getContent={(r) => r.duracion}
                    />
                    <DataTableCell
                      weighting={columnWidths.fechaEnsaye}
                      style={styles.cell}
                      getContent={(r) => formatDate(r.fechaEnsaye)}
                    />
                    <DataTableCell
                      weighting={columnWidths.carga}
                      style={[styles.cell, styles.cellInput]}
                      getContent={(r) => null}
                    />
                    <DataTableCell
                      weighting={columnWidths.diametro}
                      style={[styles.cell, styles.cellInput]}
                      getContent={(r) => null}
                    />
                  </TableBody>
                </Table>
              </View>
            </View>
          </Page>
        </>
      </Document>
    );
  } else {
    return (
      <Document title="Proyectos de Obra">
        <>
          <Page size="A4" style={styles.page} wrap>
            <View>
              <View style={styles.header}>
                <Text>SERVICIOS DE CONTROL DE CALIDAD, S.A.</Text>
                {/* TODO: Change this dynamically */}
                <Text>Página: 1</Text>
              </View>
              <View style={styles.subheader}>
                <Text style={styles.subheader.title}>
                  No se encontraron pruebas para el día {formatDate(date)}
                </Text>
                {/* <Text>{formatDate(pruebas[0].fechaEnsaye)}</Text> */}
              </View>
            </View>
          </Page>
        </>
      </Document>
    );
  }
};

export default DocuPDFCargas;
