import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HistorialTerraceria from "./HistorialTerraceria";
import HistorialConcreto from "./HistorialConcreto";
// import { Row } from "react-bootstrap";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto principal, wrapper
  root: {
    margin: "50px 30px 75px 30px",
    display: "flex",
    flexDirection: "column",
  },
  // Objeto para alternar tabs
  top: {
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > h2": {
      fontSize: "34px",
    },
  },
  // Objeto de modal
  modal: {
    position: "absolute",
    width: 430,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  // Objetos de iconos
  iconos: {
    cursor: "pointer",
  },
  // Objetos de entradas de datos (campos de formularios)
  inputMaterial: {
    width: "100%",
  },
}));

// Declaracion de pantalla HISTORIAL
const Historial = () => {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de estados
  const [historial, setHistorial] = useState(<HistorialConcreto />);
  const [alignment, setAlignment] = React.useState("web");

  // Metodos para cambio de tab
  function setConcreto() {
    setHistorial(<HistorialConcreto />);
  }
  function setTerraceria() {
    setHistorial(<HistorialTerraceria />);
  }

  // Metodos para manejo de datos y acciones
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // BODY Principal
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="web" onClick={setConcreto}>
            Concreto
          </ToggleButton>
          <ToggleButton value="android" onClick={setTerraceria}>
            Terracería
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.historial}>
        <span>{historial}</span>
      </div>
    </div>
  );
};

export default Historial;
