import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   selectConfiguracionesClientes,
// } from "../../features/configuraciones/configuracionesSlice";
import useAxiosPrivate from "../../hooks/auth/useAxiosPrivate";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AddIcon from "@mui/icons-material/Add";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DeleteIcon from "@mui/icons-material/Delete";
// import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "@material-table/core";
import { Modal } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// Ruteo de backend
const baseURL = "/obra";

// Declaracion de tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto popup para insertar y editar
  modal: {
    position: "absolute",
    width: 450,
    height: 490,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
  // Objeto popup para eliminar
  modalEliminar: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  // Objetos de iconos
  iconos: {
    cursor: "pointer",
  },
  // Objetos de entradas de datos (campos de formularios)
  inputMaterial: {
    width: "100%",
  },
  // Objeto interior de tablas (celdas)
  tableCell: {
    textTransform: "capitalize",
  },
}));

// Declaracion de configuraciones de terraceria
const ClientesConfig = () => {
  // Declaracion de estilos
  const classes = useStyles();
  // const filter = createFilterOptions();

  // Declaracion de columnas de tabla
  const columnas = [
    {
      title: "Empresa del cliente",
      field: "empresa",
      width: "30%",
    },
    {
      title: "Nombre del cliente",
      field: "nomContacto",
      width: "25%",
    },
    {
      title: "Correo del cliente",
      field: "correoContacto",
      width: "30%",
    },
    {
      title: "Telefono del cliente",
      field: "telContacto",
      width: "15%",
    },
  ]

  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const userRef = useRef();
  const errRef = useRef();
  const axios = useAxiosPrivate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  // const clientesConfig = useSelector(selectConfiguracionesClientes);

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditarCliente, setModalEditarCliente] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    clientes: {
      id: "",
      empresa: "",
      nomContacto: "",
      telContacto: "",
      correoContacto: "",
    },
  });

  React.useEffect(() => {
    peticionGet();
    // const fetchConfiguracion = async () => {
    //   try {
    //     const response = await axiosPrivate.get("/configuracion/");
    //     const data = response?.data[0];
    //     console.log(data);
    //     dispatch(
    //       setClientesConfig({
    //         titulo: data?.terraceriaTitulo,
    //         informe: data?.terraceriaInforme,
    //         nota: data?.terraceriaNota,
    //         normas: data?.terraceriaNormas,
    //         tecnico: data?.terraceriaTecnico,
    //         tecnicoOptions: data?.terraceriaTecnicoOptions || [],
    //         firma: data?.terraceriaFirma,
    //       })
    //     );
    //     dispatch(setFirmaOptionsState(data?.firmaOptions || []));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    // fetchConfiguracion();
  }, []);

  const seleccionarCliente = (cliente, caso) => {
    setSelectedRow(cliente[0]);
    switch (caso) {
      case "Eliminar":
        abrirCerrarModalEliminar();
        break;
      case "EditarCliente":
        abrirCerrarModalEditarCliente();
        break;

      default:
        break;
    }
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  const abrirCerrarModalEditarCliente = () => {
    setModalEditarCliente(!modalEditarCliente);
  };
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClientesChange = (e) => {
    const { name, value } = e.target;
    setSelectedRow((prevState) => ({
      ...prevState,
      clientes: {
        ...prevState.clientes,
        [name]: value,
      },
    }));
  };

  // Metodos para funciones de backend
  const createClientes = async (newClient) => {
    console.log(selectedRow?.clientes?.correoContacto);
    try {
      const options = await axiosPrivate.post("/cliente", {
        empresa: selectedRow?.clientes?.empresa,
        nomContacto: selectedRow?.clientes?.nomContacto,
        telContacto: selectedRow?.clientes?.telContacto,
        correoContacto: selectedRow?.clientes?.correoContacto
      });

      const mensaje = options.data.message;
      const cliente = options.data.cliente;
      console.log(cliente);
      // if (!mensaje) {
      //   getClientes();
      //   return cliente;
      // }
      // return mensaje;
      if (options?.data) {
        peticionGet();
        abrirCerrarModalInsertar();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cliente agregado correctamente",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        return cliente;
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error",
        text: "Intente de nuevo",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
      console.log(err);
    }
  };

  const getClientes = useCallback(async () => {
    const options = await axios.get("/cliente");
    console.log(options);
    const clientes = options.data.clientes;
    console.log(clientes);
    return clientes;
  }, [axios]);

  const peticionGet = useCallback(async () => {
    await getClientes().then((response) => {
      const clientes = response;
      if (clientes) {
        setTableData(clientes);
      }
    });
  }, [axios]);
  const peticionPut = async () => {
    await axios
      .put(
        baseURL +
          "/c" +
          `/${selectedRow.id}/${selectedRow.concretos.id}/${selectedRow.clientes.id}`,
        selectedRow
      )
      .then(() => {
        peticionGet();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Datos actualizados exitosamente",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };
  const peticionDelete = async () => {
    await axios
      .delete(
        // baseURL +
        //   "/" +
        //   `${proyectoSeleccionado.id}/${proyectoSeleccionado.concretos.id}`
        "/cliente/" + selectedRow.id
      )
      .then(() => {
        peticionGet();
        abrirCerrarModalEliminar();
        Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Eliminado exitosamente",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error",
          text: "Intente de nuevo",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        console.log(error);
      });
  };

  const bodyInsertar = (
    <div className={classes.modal}>
      <h3>Agregar Nuevo Cliente</h3>
      <TextField
        className={classes.inputMaterial}
        label="Empresa"
        name="empresa"
        value={selectedRow?.clientes?.empresa}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Nombre del Cliente"
        name="nomContacto"
        value={selectedRow?.clientes?.nomContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Teléfono de Contacto"
        name="telContacto"
        value={selectedRow?.clientes?.telContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Correo de Contacto"
        name="correoContacto"
        value={selectedRow?.clientes?.correoContacto}
        onChange={handleClientesChange}
      />
      <br />
      <br />
      <br />
      <div align="right">
        <Button
          color="secondary"
          onClick={() => {
            abrirCerrarModalInsertar();
            createClientes();
          }}
        >
          Listo
        </Button>
      </div>
    </div>
  );

  // BODY Modal editar cliente
  const bodyEditarCliente = (
    <div className={classes.modal}>
      <h3>Editar datos de Cliente</h3>
      <TextField
        className={classes.inputMaterial}
        label="Empresa"
        name="empresa"
        value={selectedRow?.clientes?.empresa}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Nombre del Cliente"
        name="nomContacto"
        value={selectedRow?.clientes?.nomContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Teléfono de Contacto"
        name="telContacto"
        value={selectedRow?.clientes?.telContacto}
        onChange={handleClientesChange}
      />
      <br />
      <TextField
        className={classes.inputMaterial}
        label="Correo de Contacto"
        name="correoContacto"
        value={selectedRow?.clientes?.correoContacto}
        onChange={handleClientesChange}
      />
      <br />
      <br />
      <br />
      <div align="right">
        <Button
          color="secondary"
          onClick={() => {
            abrirCerrarModalEditarCliente();
            peticionPut();
          }}
        >
          Listo
        </Button>
      </div>
    </div>
  );

  // BODY Modal eliminar cliente
  const bodyEliminar = (
    <div className={classes.modalEliminar}>
      <p>
        ¿Estás seguro que deseas eliminar al cliente{" "}
        <b>{selectedRow && selectedRow.nomContacto}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  );

  // BODY Principal
  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
      <Container component="main" maxWidth="l">
        <CssBaseline />
        <MaterialTable
          title="Lista de Clientes"
          onSelectionChange={(selected) => {
            // if (selected.length > 1) {
            //   setDisableFunction(true);
            // } else {
            //   setDisableFunction(false);
            // }
          }}
          columns={columnas}
          data={tableData}
          actions={[
            {
              icon: AddIcon,
              isFreeAction: true,
              // disabled: disableFunction,
              tooltip: "Agregar cliente",
              onClick: () => abrirCerrarModalInsertar(),
            },
            {
              icon: ManageAccountsIcon,
              // disabled: disableFunction,
              tooltip: "Editar Datos del Cliente",
              onClick: (event, rowData) =>
                seleccionarCliente(rowData, "EditarCliente"),
            },
            {
              icon: DeleteIcon,
              // disabled: disableFunction,
              tooltip: "Eliminar proyecto",
              onClick: (event, rowData) => {
                seleccionarCliente(rowData, "Eliminar");
              },
            },
          ]}
          options={{
            exportButton: true,
            exportAllData: true,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            selection: true,
            showTextRowsSelected: true,
            columnResizable: true,
            showDetailPanelIcon: true,
            showSelectAllCheckbox: false,
            columnsButton: true,
            tableLayout: "auto",
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar...",
              showColumnsAriaLabel: "Columnas",
              showColumnsTitle: "Columnas",
              addRemoveColumns: "Agregar o quitar columnas de la tabla",
            },
            pagination: {
              nextTooltip: "Siguiente",
              previousTooltip: "Anterior",
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Renglones por página:",
              labelRowsSelect: "renglones",
            },
            body: {
              addTooltip: "Agregar",
              editTooltip: "Editar",
              deleteTooltip: "Borrar",
              emptyDataSourceMessage: (
                <>
                  <p> No hay registros</p>
                  <Button
                    color="primary"
                    onClick={() => abrirCerrarModalInsertar()}
                  >
                    Agregar
                  </Button>
                </>
              ),
              editRow: {
                deleteText: "Borrar este renglón?",
              },
            },
          }}
          detailPanel={(rowData) => {
            // Tabla interior con pruebas de obras de terraceria
            // return <InnerDataTableTerraceria data={rowData} getAll={true} />;
          }}
          onRowClick={(event, rowData, togglePanel) => {
            peticionGet();
            togglePanel();
          }}
        />
      </Container>
      {/* </ThemeProvider> */}

      {/* Modales */}
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
        {bodyInsertar}
      </Modal>
      <Modal open={modalEditarCliente} onClose={abrirCerrarModalEditarCliente}>
        {bodyEditarCliente}
      </Modal>
      <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>
    </div>
  );
};

export default ClientesConfig;
