import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../services/auth";
import { setAccessToken } from "../../features/auth/authSlice";
import LandscapeIcon from "@mui/icons-material/Landscape";
import LockIcon from "@mui/icons-material/Lock";
import JoinRightIcon from "@mui/icons-material/JoinRight";

// Estilos
const useStyles = makeStyles(() => ({
  // Objeto logo
  logo: {
    transition: ".5s",
    width: "175px",
    marginTop: "10px",
    "&:hover": {
      transform: "scale(0.95)",
      cursor: "pointer",
    },
  },
  // Objeto principal, wrapper
  bar: {
    maxWidth: "1200px",
  },
}));

// Declaracion de componente NAVBAR
export default function SearchAppBar() {
  // Metodos para manejo de datos y acciones
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await logout();
      console.log(response.data);
      navigate("/auth");
      dispatch(setAccessToken(""));
    } catch (err) {
      console.error(err);
    }
  };

  // Declaracion de controladores
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const classes = useStyles();

  // BODY Principal
  return accessToken ? (
    <Box
      sx={{
        // flexGrow: 1,
        zIndex: 9,
        height: 63,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="././imgs/logoGCC.png"
              alt="LOGO"
              className={classes.logo}
              onClick={() => {
                navigate("/home");
              }}
            ></img>
          </Typography>
          <MenuItem
            onClick={() => {
              navigate("/concreto");
            }}
          >
            <JoinRightIcon />
            Concreto
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/terraceria");
            }}
          >
            <LandscapeIcon />
            Terracería
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/historial");
            }}
          >
            <HistoryIcon />
            Historial
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/configuraciones");
            }}
          >
            <SettingsIcon />
            Configuraciones
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LockIcon />
            Cerrar sesión
          </MenuItem>
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        zIndex: 9,
        //width: "1200px",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="././imgs/logoGCC.png"
              alt="LOGO"
              className={classes.logo}
              onClick={() => {
                navigate("/auth");
              }}
            ></img>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
