import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { useNavigate } from "react-router-dom";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto de tarjetas para estadisticos
  card: {
    backgroundColor: "#fff",
    maxWidth: "250px",
    border: "1px solid #D3D3D3",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "20px",
    padding: "20px",
    boxShadow: "2px 2px 5px #aaaaaa",
    transition: ".3s",
    "& > h1": {
      fontWeight: "400",
    },
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: "3px 3px 5px #aaaaaa",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
    },
  },
  // Contenido de tarjetas para estadisticos
  cardContent: {
    backgroundColor: "#fff",
    width: "200px",
    // marginTop: "10px",
    textAlign: "center",
  },
}));

// Declaracion de datos cuantitativos historicos
const DataHistorial = ({ number, title, text, route }) => {
  // Declaracion de estilos
  const classes = useStyles();

  // BODY Principal
  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <h1>{number}</h1>
        <p>{title}</p>
        {/* <p>{text}</p> */}
      </div>
    </div>
  );
};

export default DataHistorial;
