import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import { setAccessToken } from "../../features/auth/authSlice";

// Controlador para refrescar token de autenticacion para peticiones con backend
function useRefreshToken() {
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      // TODO: change to withCredentials: true to pass RT through httpOnly secure cookie
      const response = await axios.post(
        "user/token",
        {},
        { withCredentials: true }
      );

      // console.log("New Access Token: ", response.data.accessToken);
      // console.log("refresh token refreshed");
      dispatch(setAccessToken(response.data.accessToken));
      return response.data.accessToken;
    } catch (error) {
      return null;
    }
  };
  return refresh;
}

export default useRefreshToken;
