import * as React from "react";
import Avatar from "@mui/material/Avatar";
// import { useDispatch } from "react-redux";
// import { setAccessToken } from "../../features/auth/authSlice";
// import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
import { createTheme, /*ThemeProvider*/ } from "@mui/material/styles";
import { /*useRef,*/ useState, /*useEffect*/ } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { display } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UserConfig from "./UserConfig";
import ConcretoConfig from "./ConcretoConfig";
import TerraceriaConfig from "./TerraceriaConfig";
import ClientesConfig from "./ClientesConfig";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// Declaracion de tema
const theme = createTheme();

// Estilos
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "3rem",
    justifyContent: "center",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  top: {
    marginTop: "30px",
  },
  historial: {
    marginTop: "1rem",
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: "1rem",
    // },
  },
}));

// Declaracion de objeto principal de pantalla configuraciones, wrapper
export default function Config() {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/home";

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");
  const [firma, setFirma] = React.useState("");
  const [tab, setTab] = useState(<UserConfig />);
  const [alignment, setAlignment] = React.useState("web");

  // Metodos para cambio de tab
  function setUsuario() {
    setTab(<UserConfig />);
  }
  function setConcreto() {
    setTab(<ConcretoConfig />);
  }
  function setTerraceria() {
    setTab(<TerraceriaConfig />);
  }
  function setClientes() {
    setTab(<ClientesConfig />);
  }

  // Metodos para manejo de datos y acciones
  const handleChange = (event, newAlignment) => {
    setFirma(event.target.value);
    setAlignment(newAlignment);
  };

  // BODY Principal
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: 300,
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            height: 300,
            padding: 10,
          },
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <SettingsIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Configuraciones
        </Typography>
        <Typography variant="body2" align="center">
          Aquí podras cambiar elementos del usuario y de las reportes de
          impresión.
        </Typography>
        <div className={classes.top}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="usuario" onClick={setUsuario}>
              Usuario
            </ToggleButton>
            <ToggleButton value="concreto" onClick={setConcreto}>
              Concreto
            </ToggleButton>
            <ToggleButton value="terraceria" onClick={setTerraceria}>
              Terracería
            </ToggleButton>
            <ToggleButton value="clientes" onClick={setClientes}>
              Clientes
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Box>
      <div className={classes.historial}>
        <span>{tab}</span>
      </div>
    </div>
  );
}
