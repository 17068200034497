import React from "react";
import DocuPDF from "../PDF/DocuPDF";
import DocuPDF2 from "../PDF/DocuPDF2";
import DocuPDFCargas from "./DocuPDFCargas";
import { PDFViewer } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import {
  selectConfiguracionesConcreto,
  selectConfiguracionesTerraceria,
} from "../../features/configuraciones/configuracionesSlice";
import { useLocation } from "react-router-dom";

// Declaracion de VISTA PREVIA para PDFs de concreto y terraceria
const PdfPreview = () => {
  const { state } = useLocation();
  const dateFilter = state?.dateFilter; // Read values passed on state
  // Declaracion de variables
  const caso = useSelector((state) => state.pdf.case);
  const data = useSelector((state) => state.pdf.data);
  let pruebas = useSelector((state) => state.pdf.pruebas);
  // Pruebas que no tienen carga 0
  const pruebasConCarga = pruebas.filter((prueba) => prueba.carga > 0);
  // Filtrar pruebas que no sean del día

  const pruebasDelDia = pruebas.filter((prueba) => {
    if (caso === "concreto" || caso === "cargasConcreto") {
      let fechaEnsayeDate = new Date(prueba.fechaEnsaye);

      let startOfDay = dateFilter ? new Date(dateFilter) : new Date();
      startOfDay.setHours(0, 0, 0, 0);

      let endOfDay = dateFilter ? new Date(dateFilter) : new Date();
      endOfDay.setHours(23, 59, 59);

      return startOfDay <= fechaEnsayeDate && fechaEnsayeDate <= endOfDay;
    } else return false;
  });

  // Select pdf text variables from redux
  const concretoConfig = useSelector(selectConfiguracionesConcreto);
  const terraceriaConfig = useSelector(selectConfiguracionesTerraceria);

  // BODY principal
  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "200vh",
      }}
    >
      {/* Wrapper PDF */}
      <PDFViewer
        showToolbar={true}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        {/*Concreto (1) o Terraceria (2)*/}
        {caso === "concreto" ? (
          <DocuPDF
            pruebas={pruebasConCarga}
            data={data}
            config={concretoConfig}
          />
        ) : caso === "terraceria" ? (
          <DocuPDF2 pruebas={pruebas} data={data} config={terraceriaConfig} />
        ) : (
          <DocuPDFCargas pruebas={pruebasDelDia} date={dateFilter} />
        )}
      </PDFViewer>
    </div>
  );
};

export default PdfPreview;
