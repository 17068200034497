import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

// Estilos
const useStyles = makeStyles((theme) => ({
  // Objeto de tarjetas para navegar a paginas principales
  card: {
    cursor: "pointer",
    backgroundColor: "#fff",
    maxWidth: "350px",
    border: "1px solid #D3D3D3",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "20px",
    padding: "30px",
    boxShadow: "5px 5px 10px #a0a0a0",
    transition: ".3s",
    "&:hover": {
      transform: "scale(0.98)",
      boxShadow: "5px 5px 10px #aaaaaa",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      margin: "0px",
      padding: "20px",
      marginTop: "10px",
      marginBottom: "10px",
      maxWidth: "300px",
      boxShadow: "2px 2px 5px #a0a0a0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  // Contenido de tarjetas sobre pantallas
  cardContent: {
    backgroundColor: "#fff",
    width: "350px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "14px",
      width: "300px",
    },
  },
}));

// Declaracion de tarjeta con ruta a pantalla
const Card = ({ image, title, text, route }) => {
  // Declaracion de estilos
  const classes = useStyles();

  // Declaracion de controladores
  const navigate = useNavigate();

  // BODY Principal
  return (
    <div className={classes.card} onClick={() => navigate(route)}>
      <div>
        <img src={image} alt="{title}" height="50px" width="50px" />
      </div>
      <div className={classes.cardContent}>
        <h3>{title}</h3>
        {/* <div className={classes.line}></div> */}
        {/* <p>{text}</p> */}
      </div>
    </div>
  );
};

export default Card;
