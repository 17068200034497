import {
  Alert,
  Button,
  Container,
  CssBaseline,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Declaracion de componente de NO AUTORIZADO
const Unauthorized = () => {
  // Declaracion de controladores
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  // BODY Principal
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h1"
          variant="h5"
          sx={{ mb: 1, textAlign: "center" }}
        >
          No autorizado
        </Typography>
        <Alert variant="outlined" severity="error">
          <strong>No tiene acceso a la página solicitada</strong>
        </Alert>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={goBack}>
          Regresar
        </Button>
      </Container>
    </>
  );
};

export default Unauthorized;
