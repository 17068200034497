import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../features/auth/authSlice";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRef, useState, /*useEffect*/ } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../services/auth";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// Declaracion de pantalla RESET PWD (se usa?)
export default function SignIn() { // WRONG NAME
  // Declaracion de controladores
  // const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  // const userRef = useRef();
  const errRef = useRef();
  const dispatch = useDispatch();

  // Declaracion de estados
  // const [errMsg, setErrMsg] = useState("");
  //   const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //   useEffect(() => {
  //     userRef.current.focus();
  //   }, []);

  // Metodos para manejo de datos y acciones
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await login(newPassword, confirmPassword);
      const accessToken = response?.data?.accessToken;
      dispatch(setAccessToken(accessToken));

      navigate(from, { replace: true });
    } catch (err) {
      // MySwal.fire({
      //   title: <p>Hello World</p>,
      //   didOpen: () => {
      //     MySwal.showLoading();
      //   },
      // }).then(() => {
      //   return MySwal.fire(<p>{err.response.data}</p>);
      // });
      console.log(err);
      errRef.current.focus();
    }
  };

  // BODY Principal
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 585,
            justifyContent: "center",
            // backgroundColor: "white",
            padding: "30px",
            borderRadius: "20px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nueva contraseña
          </Typography>
          <Typography variant="body2" align="center">
            Cambia tu contraseña. Asegúrate que sea segura y no la compartas con
            nadie.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nueva contraseña"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirmar contraseña"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              autoComplete="off"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reestablecer contraseña
            </Button>
          </Box>
        </Box>
        <br />
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
}
